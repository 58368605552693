import { CheckCircle, DoDisturbOn, Edit, FilterList, HourglassTop, Refresh, UploadFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, AlertTitle, Button, Card, CardContent, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Grid, IconButton, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { cleanData, db, storage } from '../../../Firebase';
import { UserAuth } from '../../context/UserContext';
import DialogVisa from './DialogVisa';
import { getBlob, ref } from 'firebase/storage';
import { IconLabelItem } from '../../common/IconLabelItem';




export default function MesVisa() {

    const { user, usr, logout } = UserAuth();

    const [color, setAge] = React.useState('#ff4838');


    const columns = [
        // {
        //     field: 'confirmed',
        //     align: "center",
        //     headerName: 'Confirmé',
        //     width: 90,
        //     renderCell: (cell) => {
        //         return (
        //             <div>
        //                 {
        //                     cell.row.confirmed ?
        //                         <Recommend color='success'></Recommend>
        //                         :
        //                         <AccessAlarm color='warning'></AccessAlarm>

        //                 }
        //             </div>

        //         );
        //     }
        // },
        {
            field: 'etat',
            align: "center",
            headerName: 'Etat',
            width: 180,
            renderCell: (cell) => {
                return (
                    <div>
                        {
                            cell.row.etat == "done" ?
                                <div>
                                    <CheckCircle color={"success"}> </CheckCircle>
                                    <strong> Confirmé</strong>
                                </div>
                                : cell.row.etat == "waiting" ?
                                    <div>
                                        <HourglassTop color={"warning"}></HourglassTop>
                                        <strong>  En traitement</strong>
                                    </div >
                                    :
                                    <div>
                                        <DoDisturbOn color="error"></DoDisturbOn>
                                        <strong> Annulé</strong>
                                    </div>
                        }

                    </div >

                );
            }
        },

        {
            field: 'visa', headerName: 'Visa', width: 200, renderCell: (cell) => {
                return (
                    <div>{cell.row.pays} - {cell.row.type}</div>
                )
            }
        },
        {
            field: 'date', headerName: 'Date', width: 200
        },
        { field: 'nom', headerName: 'Nom prénom', width: 150 },


        {
            field: 'total', headerName: 'Total (DA)', width: 200

        },
        { field: 'passport', headerName: 'Num Passport', width: 180 },





    ];

    const [rows, setRows] = React.useState([

    ]);



    const getData = async () => {
        setRows([])
        const detailRef = collection(db, "visa");
        const q = await query(detailRef, where("nom_agence", "==", usr.nom_agence))
        let querySnapshot = await getDocs(q)
            .then((querySnapshot) => {
                const newData = cleanData(querySnapshot)
                setRows(newData)
            }).catch((err) => {
                console.log(err);
            })
    }

    const [filter, setfilter] = React.useState(false);

    const handleFilter = async (values) => {
        setRows([])

        var detailRef = null;
        var q = null
        var querySnapshot = null;
        let tmp = []

        if (values.agence) {
            detailRef = collection(db, "visa");
            q = await query(detailRef, where("nom_agence", "==", values.agence))
            querySnapshot = await getDocs(q);
        }

        if (values.name) {
            detailRef = collection(db, "visa");
            q = await query(detailRef, where("nom", "==", values.name))
            querySnapshot = await getDocs(q);
        }



        await querySnapshot.forEach(async (doc) => {
            tmp.push({ ...doc.data(), id: doc.id })
            // doc.data() is never undefined for query doc snapshots
        });

        setRows(tmp)


    }

    const editData = async () => {

        setdone(true)
        let itm = item
        try {
            const docRef = await updateDoc(doc(db, "visa", itm.id), {
                confirmed: true
            });
            getData()
            setdone(false)
            handleCloseConfirmation()
            handleClose()

        } catch (e) {
            setdone(false)
            handleCloseConfirmation()

        }


    }

    useEffect(() => {
        getData()
    }, [usr])



    // dialog
    const [item, setItem] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);




    const [collapse, setCollapse] = React.useState(false)



    const [listvisa, setListvisa] = React.useState([]);

    //  confirmation
    const [done, setdone] = useState(false);
    const [confirmationValues, setconfirmationValues] = useState();

    const [confirmation, setconfirmation] = React.useState(false);
    const handleOpenConfirmation = (values) => {
        setconfirmationValues(values);
        setconfirmation(true);
    };
    const handleCloseConfirmation = () => setconfirmation(false);

    const onDownload = (url) => {
        let file = 'images/demande_visa/' + item.id + '/pdf';

        getBlob(ref(storage, file))
            .then((blob) => {
                const href = URL.createObjectURL(blob);
                const a = Object.assign(document.createElement('a'), {
                    href,
                    style: 'display:none',
                    download: 'visa_' + item.id + '.pdf', // This is where you set the name of the file you're about to download
                });
                a.click();

                URL.revokeObjectURL(href);
                a.remove();
            })
            .catch((error) => {
                console.error(error);
            });

    };



    return (
        <div style={{ backgroundColor: grey[900] }}>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={confirmation}
                onClose={handleCloseConfirmation}>
                <DialogTitle>
                    <strong>Confirmation</strong>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        êtes-vous sûr de vouloir confirmer cette modification ?{' '}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        autoFocus
                        color="inherit"
                        onClick={handleCloseConfirmation}>
                        Annuler
                    </Button>
                    <LoadingButton loading={done} variant="contained" onClick={editData}>
                        Confirmer
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <div style={{ backgroundColor: grey[900], paddingTop: 90, }}>
            </div>
            <div style={{ backgroundColor: "white", paddingTop: 20, height: filter ? 870 : 680 }}>
                <Container style={{ height: 580, width: '100%', }}>


                    <Dialog
                        scroll='body'
                        fullWidth
                        maxWidth="md"
                        open={open}
                        onClose={handleClose}
                    >
                        <Card>

                            <Container sx={{ mt: 3 }}>
                                <Grid container>
                                    <Grid item xs={12} md={6}>
                                        <Typography variant='h4'>
                                            <strong>
                                                Informations
                                            </strong>
                                        </Typography>
                                    </Grid>


                                </Grid>
                                <CardContent>

                                    <DialogVisa data={item}></DialogVisa>
                                    {
                                        item.etat == "done" &&
                                        <div>
                                            <Divider
                                                sx={{ mt: 3, mb: 2, color: color, borderBottomWidth: 3 }}
                                                variant="middle"
                                            ></Divider>
                                            <Typography variant="h6" >
                                                <strong>
                                                    E-Visa
                                                </strong>

                                            </Typography>
                                            {
                                                item.pdf != "" &&
                                                <div>
                                                    <a
                                                        style={{ paddingLeft: 30 }}
                                                        href={item?.pdf}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        download
                                                        onClick={onDownload}
                                                    >
                                                        <UploadFile></UploadFile>
                                                        Téléchager E-Visa
                                                    </a>
                                                </div>
                                            }
                                        </div>
                                    }


                                    <Divider
                                        sx={{ mt: 1, mb: 1, color: color, borderBottomWidth: 3 }}
                                        variant="middle"
                                    ></Divider>
                                    <div className='row'>
                                        <Alert severity={item.etat == "done" ? "success" : item.etat == "waiting" ? "warning" : "error"} >
                                            {IconLabelItem(<></>, "Etat de la demande",
                                                item.etat == "done" ?
                                                    <>
                                                        <strong>Confirmé</strong>
                                                        {/* <CheckCircle color={"success"}> </CheckCircle> */}
                                                    </>
                                                    : item.etat == "waiting" ?
                                                        < >
                                                            {/* <HourglassTop color={"warning"}></HourglassTop> */}
                                                            <strong>En traitement</strong>
                                                        </ >
                                                        :
                                                        <>
                                                            {/* <DoDisturbOn color="error"></DoDisturbOn> */}
                                                            <strong> Annulé</strong>
                                                        </>
                                            )}
                                        </Alert>

                                    </div>
                                </CardContent>

                            </Container>
                        </Card>
                    </Dialog>
                    <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant='h3' mb={2}>
                            <strong>
                                <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-globe-europe-africa" viewBox="0 0 16 16">
                                    <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.809.809 0 0 1 1.034-.275.809.809 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34c.078.028.16.044.243.054.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.333.333 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501Z" />
                                </svg> Visa
                            </strong>
                        </Typography>
                        <Button onClick={() => setfilter(!filter)} style={{ backgroundColor: color }} variant='contained' endIcon={<FilterList ></FilterList>}>filter</Button>

                    </Stack>

                    <Collapse in={filter} sx={{ mb: 2 }}>
                        <Card>

                            <CardContent>
                                <Typography variant='h6' mb={2}>
                                    <strong>
                                        <i style={{ color: color }} className="bi bi-search"></i> Filter
                                    </strong>
                                </Typography>

                                <Formik
                                    initialValues={{

                                        name: "",


                                    }}
                                    validationSchema={
                                        Yup.object().shape({
                                            // agence: Yup.string().max(255).required('champ obligatoire'),
                                            // name: Yup.string().max(255).required('champ obligatoire'),
                                        }

                                        )
                                    }
                                    onSubmit={(values) => {
                                        handleFilter(values)
                                    }}
                                >
                                    {({

                                        setFieldValue,
                                        values,
                                        errors,
                                        touched,
                                        handleChange,
                                        handleBlur,
                                        handleSubmit,
                                        isSubmitting, isValid
                                    }) => (
                                        <Form>
                                            <div className="row">

                                                <div className="col">
                                                    <TextField


                                                        error={Boolean(touched.name && errors.name)}
                                                        fullWidth
                                                        helperText={touched.name && errors.name}
                                                        label="Nom client"
                                                        margin="normal"
                                                        name="name"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.name}
                                                        variant="outlined"
                                                    />

                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className="col-sm-6">

                                                    <Button type='submit' style={{ backgroundColor: color }} variant='contained'>Appliquer</Button>
                                                    <IconButton onClick={() => {

                                                        setFieldValue("name", "")
                                                        getData()
                                                    }}>
                                                        <Refresh></Refresh>
                                                    </IconButton>
                                                </div>
                                            </div>
                                        </Form>)}
                                </Formik>
                            </CardContent>

                        </Card>
                    </Collapse>


                    <DataGrid
                        disableColumnMenu
                        disableColumnFilter
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5]}
                        onCellClick={(cell) => {
                            setItem(cell.row)
                            handleOpen()
                        }}

                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    <Alert severity="error">
                                        Aucune visa trouvé !
                                    </Alert>
                                </Stack>
                            ),
                            NoResultsOverlay: () => (
                                <Alert severity="error">
                                    Aucune visa trouvé !
                                </Alert>
                            )
                        }}

                    />
                </Container>

            </div>

        </div>
    );
}