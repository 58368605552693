/** @format */

import React, { useState } from 'react';

import { Card, Container, Stack, Typography } from '@mui/material';

import { Link, useHistory } from 'react-router-dom';
import AdminDestinationCart from './AdminDestinationCart';

function AdminDestionation() {


    const [list_destination, setList_destination] = useState([
        {
            'title': 'Omra - عمرة',
            'image': '/images/mecca.jpg',
            'type': 'omra'
        },
        {
            'title': 'National',
            'image': '/images/algeria.jpg',
            'type': 'national'
        },
        {
            'title': 'International',
            'image': '/images/world.jpg',
            'type': 'international'
        },

    ])


    let history = useHistory()


    return (
        <>

            <div className="destination-wrapper " >
                <Container style={{ width: '100%' }}>
                    <Stack
                        direction='row'
                        justifyContent="space-between"
                        alignItems="center"
                    >
                        <Typography variant="h3" mb={3}>
                            <strong>

                                <i class="bi bi-arrow-up-right-circle-fill"></i> Destinations
                            </strong>
                        </Typography>


                    </Stack>


                    <div className="row g-3">
                        {
                            list_destination.map(destination =>
                                <div className="col-lg-4 col-md-4 col-sm-6">
                                    <Link
                                        onClick={() => {
                                            history.push({ pathname: "/admin/packages", state: { type: destination.type } }
                                            )
                                        }}
                                    >
                                        <Card>

                                            <AdminDestinationCart

                                                image={
                                                    process.env.PUBLIC_URL +
                                                    destination.image
                                                }
                                                palce={destination.title}
                                                palceCount="45"
                                            />
                                        </Card>
                                    </Link>



                                </div>
                            )
                        }


                    </div>
                </Container>
            </div>
        </>
    );
}

export default AdminDestionation;
