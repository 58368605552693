/** @format */

import React, { useEffect, useState } from 'react';
import HomeFour from '../pages/homeFour/HomeFour';
import Footer from '../common/Footer';
import Loading from '../common/Loading';
import HeaderFour from '../pages/homeFour/HeaderFour';
import Header from '../common/Header';

function HomeFourLayout() {
	const [load, setLoad] = useState(false);
	useEffect(() => {
		setLoad(true);
		setTimeout(() => {
			setLoad(false);
		}, 1000);
	}, []);
	return (
		<>
			{load ? (
				<Loading />
			) : (
				<>
					<Header />
					<HomeFour />
					<Footer className="footer-area" />
				</>
			)}
		</>
	);
}

export default HomeFourLayout;
