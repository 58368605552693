import { orange } from "@mui/material/colors";
import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.css";

function AboutWrapperArea() {
  const [isOpen, setOpen] = useState(false);

  return (
    <>
      <div className="about-main-wrappper pt-110">
        <div className="container">
          <div className="about-tab-wrapper">
            <div className="row justify-content-center">
              <div className="col-lg-6">
                <div className="about-tab-image-grid text-center">
                  <div className="about-video d-inline-block">
                    <img src={process.env.PUBLIC_URL + "/images/about/about-g2.png"} alt="aboutIMG" />
                    <div className="video-overlay">
                      <div className="play-icon video-popup">
                        <i
                          onClick={() => setOpen(true)}
                          className="bi bi-play-fill"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div className="row float-images g-4">
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="about-image">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-g1.png"} alt="aboutIMG" />
                      </div>
                    </div>
                    <div className="col-lg-6 col-md-6 col-sm-6">
                      <div className="about-image">
                        <img src={process.env.PUBLIC_URL + "/images/about/about-g3.png"} alt="aboutIMG" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 mt-5 mt-lg-0">
                <div className="about-tab-wrap">
                  <h2 className="about-wrap-title">
                    À propos de <span>notre entreprise</span>  et de ce que nous offrons.
                  </h2>
                  <div className="about-tab-switcher">
                    <ul
                      className="nav nav-pills mb-3 justify-content-md-between justify-content-center"
                      id="about-tab-pills"
                      role="tablist"
                    >
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link active"
                          id="pills-about1"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills1"
                          role="tab"
                          aria-controls="about-pills1"
                          aria-selected="true"
                        >
                          <h3>10</h3>
                          <h6>Years Experience</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about2"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills2"
                          role="tab"
                          aria-controls="about-pills2"
                          aria-selected="false"
                        >
                          <h3>100+</h3>
                          <h6>Nos Guides</h6>
                        </div>
                      </li>
                      <li className="nav-item" role="presentation">
                        <div
                          className="nav-link"
                          id="pills-about3"
                          data-bs-toggle="pill"
                          data-bs-target="#about-pills3"
                          role="tab"
                          aria-controls="about-pills3"
                          aria-selected="false"
                        >
                          <h3>500+</h3>
                          <h6>Travelar Connect</h6>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div
                    className="tab-content about-tab-content"
                    id="pills-tabContent"
                  >
                    <div
                      className="tab-pane fade show active"
                      id="about-pills1"
                      role="tabpanel"
                      aria-labelledby="pills-about1"
                    >
                      <p>
                        Il m'est agréable de vous présenter, notre agence <strong style={{ color: "#ff4838" }}>«VERISON BOOKING»</strong>. Dont la vocation est
                        de transformer chaque client en hôte privilégié et chaque étape de son séjour en souvenir
                        inoubliable.<br />


                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills2"
                      role="tabpanel"
                      aria-labelledby="pills-about2"
                    >
                      <p>
                        <strong style={{ color: "#ff4838" }}>«VERISON BOOKING»</strong> organise vos vacances et propose une gamme attrayante et variée de
                        produits. Outre les transferts, les excursions et les circuits au départ de différents aéroports, elle
                        organise toute opération à caractère spécial ou à thème pour des groupes spéciaux, Inventives,
                        croisières, séjours thermale, séminaires & congrès, itinéraires individuels avec guide et chauffeur,
                        Soirées typiques, stages d'initiation à la plongé et à la pêche sous-marine. Prestations et
                        destinations sont á la carte, et toutes les aventures sont permises.
                        Notre plus grande satisfaction sera que nos clients trouvent dans sur notre plateforme le produit
                        touristique recherché qui répond à leurs attentes et la prestation de qualité qui leur permet
                        d'apprécier leurs vacances dans le monde.<br />
                      </p>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="about-pills3"
                      role="tabpanel"
                      aria-labelledby="pills-about3"
                    >
                      <p>
                        <strong style={{ color: "#ff4838" }}>«VERISON BOOKING»</strong> accueille et offre de nombreuses découvertes. Comme chacun aime
                        voyager á son rythme et á sa manière il est important d'offrir á tous, le choix, le confort et la
                        sécurité.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row pt-110">
            <div className="col-lg-6">
              <div className="achievement-counter-wrap">
                <h2 className="about-wrap-title">
                  Pourquoi réserver avec <span>Verison?</span>
                </h2>
                <div className="achievement-counter-cards">
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center ">
                    {/* <div className="counter-box mb-sm-0 mb-3">
                      <h2>500+</h2>
                      <h6>Awesome Tour</h6>
                    </div> */}
                    <p>
                      <ul>
                        <li>
                          <i className="bi bi-check2" /> Réservation et vente de séjours de tourisme

                        </li>
                        <li>
                          <i className="bi bi-check2" /> Réservation et vent de billets d’avions

                        </li>
                        <li>
                          <i className="bi bi-check2" /> Réservation d’hôtels

                        </li>
                        <li>
                          <i className="bi bi-check2" /> Réservation et vent de billets d’avions
                        </li>

                        <li>
                          <i className="bi bi-check2" /> Représentation d'agences de voyages nationaux et étrangers en Algérie

                        </li>
                        <li>
                          <i className="bi bi-check2" /> Visa et assistance

                        </li>
                      </ul>


                    </p>
                  </div>
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                    {/* <div className="counter-box">
                      <h2>300+</h2>
                      <h6>Destinations</h6>
                    </div> */}
                    <p>
                      <ul>
                        <li>
                          <i className="bi bi-check2" />Organisation de voyages professionnels : Un véritable service à la carte peut être fourni
                          par «VERISON BOOKING» à l'intention des hommes et femmes d’affaires : (Réservation
                          hôtelière, accueil et assistance, mise à disposition de voitures grand confort avec
                          chauffeur).
                        </li>
                        <li>
                          <i className="bi bi-check2" /> Organisation d'Inventives, de séminaires et de congrès
                        </li>
                        <li>
                          <i className="bi bi-check2" />Organisation de séjours sportifs
                        </li>
                      </ul>

                    </p>
                  </div>
                  <div className="achievement-counter-card flex-sm-row flex-column text-sm-start text-center">
                    {/* <div className="counter-box">
                      <h2>150+</h2>
                      <h6> Mountains</h6>
                    </div> */}
                    <p>
                      <ul>
                        <li>
                          <i className="bi bi-check2" /> Organisation et vente d’Omra

                        </li>
                        <li>
                          <i className="bi bi-check2" /> Organisation et vente de séjours de soins et thermale

                        </li>
                        <li>
                          <i className="bi bi-check2" /> Organisation de transferts et d'excursions

                        </li>
                        <li>
                          <i className="bi bi-check2" /> Organisation et vente de circuits touristiques

                        </li>
                        <li>
                          <i className="bi bi-check2" /> Organisation de croisières

                        </li>
                      </ul>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="about-image-group mt-5 mt-lg-0">
                <img src={process.env.PUBLIC_URL + "/images/about/about-image-group2.png"} alt="aboutIMG" className="img-fluid" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <React.Fragment>
        <ModalVideo
          channel="youtube"
          autoplay
          isOpen={isOpen}
          videoId="3SsK-cxlj_w"
          onClose={() => setOpen(false)}
        />
      </React.Fragment>
    </>
  );
}

export default AboutWrapperArea;
