/** @format */

import {
	ArrowBack,
	ArrowForward,
	Check,
	Delete,
	FileCopy,
	FlightLand,
	FlightTakeoff,
	Group,
	HourglassBottom,
	UploadFile,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
	Alert,
	Box,
	Button,
	Checkbox,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	FormControl,
	FormControlLabel,
	FormGroup,
	FormLabel,
	Grid,
	Input,
	MenuItem,
	Radio,
	RadioGroup,
	Snackbar,
	Step,
	StepLabel,
	Stepper,
	TextField,
	Typography,
} from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { collection, doc, setDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { db, storage } from '../../../Firebase';
import { UserAuth } from '../../context/UserContext';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';

const useStyles = makeStyles({
	root: {
		'& .MuiOutlinedInput-root': {
			'& fieldset ': {
				borderColor: '#ff4838',
				borderRadius: 200,
			},
			'& input ': {
				borderColor: '#ff4838',
				borderRadius: 200,
			},
		},
		padding: 5,
	},
});

const columns = [
	{ field: 'id', headerName: 'ID', width: 70 },
	{ field: 'nom', headerName: 'Nom Prenom', width: 180 },
	{ field: 'age', headerName: 'Date de naissance', width: 150 },
	{ field: 'phone', headerName: 'Téléphone', width: 150 },
	{
		field: 'transport',
		headerName: 'Transport',
		width: 150,
		renderCell: (cell) => {
			return <div>{cell.row.transport ? 'Oui' : 'Non'}</div>;
		},
	},
	{ field: 'passport', headerName: 'Passport', width: 180 },
	{ field: 'visa', headerName: 'Visa', width: 130 },
	{
		field: 'expiration_passport',
		headerName: 'expiration passport',
		width: 150,
	},
];

const columns_national = [
	{ field: 'id', headerName: 'ID', width: 70 },
	{ field: 'nom', headerName: 'Nom Prenom', width: 180 },
	{ field: 'age', headerName: 'Date de naissance', width: 150 },
	{ field: 'phone', headerName: 'Téléphone', width: 150 },
	{
		field: 'transport',
		headerName: 'Transport',
		width: 150,
		renderCell: (cell) => {
			return <div>{cell.row.transport ? 'Oui' : 'Non'}</div>;
		},
	},
];

export default function BookingTable(props) {
	const classes = useStyles();

	const [prix, setprix] = useState({
		priceAdulte: props.programme.priceAdulte,
		priceEnf0: props.programme.priceEnf0,
		priceEnf2: props.programme.priceEnf2,
		priceEnf6: props.programme.priceEnf6,
	});

	const [places, setplaces] = useState(props.programme.places);
	const [montant, setmontant] = useState();
	const [loading, setloading] = useState(false);

	const [id, setid] = useState(props.programme.id);

	// const [usr, setusr] = useState({})

	const [rows, setRows] = useState([]);
	const [nbpersonne, setnbpersonne] = useState(0);

	const addClient = async (values, docRef, x) => {
		try {
			let promises = [];

			for (let file of values.files) {
				const imageRef = ref(
					storage,
					'images/reservation/' + docRef + '/' + x
				);
				promises.push(
					uploadBytesResumable(imageRef, file).then(
						(uploadResult) => {
							return getDownloadURL(uploadResult.ref);
						}
					)
				);
				x = x + 1;
			}

			const photos = await Promise.all(promises);

			let reservation = {
				nom: values.nom,
				passport: values.passport,
				transport: values.transport,
				age: values.age,
				visa: values.visa,
				expiration_passport: values.expiration_passport,
				files: photos,
			};

			return reservation;
		} catch (values) {
			console.error('Error adding document: ', values);
			return false;
		}

		// decrementer les places "p" dans le documment avec id
	};

	const ajouterListClients = async () => {
		setloading(true);

		const reference = await doc(collection(db, 'reservation'));
		let docRef = reference.id;

		let promises = [];
		let fileid = 0;
		for (let row in rows) {
			promises.push(addClient(rows[row], docRef, fileid));
			fileid = fileid + props.programme.files.length;
		}
		let waiting = await Promise.all(promises);
		let total = calculTotal(rows[0]);

		let reservation = {
			depart: rows[0].depart.depature,
			retour: rows[0].depart.return,
			nom_agence: usr.nom_agence,
			id_agence: usr.id,
			type: props.programme.type,
			destination: props.programme.title,
			nom: rows[0].nom,
			phone: rows[0].phone,
			chambre: rows[0].chambre,
			programme: {
				id: props.programme.id,
				files: props.programme.files,
				enfants: props.programme.enfants,
			},
			total: total,
			etat: 'waiting',
			date: format(new Date(), 'dd-MM-yyyy'),
			nbadults: rows[0].nbadults,
			enfants: rows[0].enfants,
			members: waiting,
			pdf: '',
		};

		await setDoc(doc(db, 'reservation', docRef), reservation);

		docRef = await updateDoc(doc(db, 'inscription', usr.id), {
			versement: parseInt(montant) + parseInt(total),
		}).then(() => {
			// getUser()
			actions.resetForm();
			setActiveStep(0);
			setRows([]);
		});

		let tmpDepart = props.programme.departs;

		tmpDepart.map((item, index) => {
			if (item.depature == rows[0].depart.depature) {
				tmpDepart[index] = {
					depature: rows[0].depart.depature,
					return: rows[0].depart.return,
					places: rows[0].depart.places,
					reservations: rows[0].depart.reservations + rows[0].places,
				};
			}
		});

		let p = {
			departs: tmpDepart,
		};
		docRef = await updateDoc(
			doc(db, 'destination_detail', props.programme.id),
			p
		);

		props.getData();
		getUsr();
		setloading(false);
		setnbpersonne(0);
		setnbTransport(0);
		setRows([]);
		handleSnake();
		handleCloseConfirmation();
		navigate.back();
	};

	let navigate = useHistory();

	const addToTable = (e) => {
		let tmp = [...rows];

		setnbpersonne((v) => v + 1);
		let reservation = {
			places: calculePersonnes(e.nbadults, e.enfants),
			nbadults: e.nbadults,
			depart: e.depart,
			destination: props.title,
			transport: e.transport,
			nom_agence: usr.nom_agence,
			nom: e.nom.toLowerCase(),
			passport: e.passport,
			age: format(e.age, 'dd-MM-yyyy'),
			phone: e.phone,
			visa: e.visa,
			chambre: e.chambre,
			nbpersonne: nbpersonne,
			enfants: e.enfants,
			expiration_passport: format(
				props.programme.type == 'national'
					? new Date()
					: e.expiration_passport,
				'dd-MM-yyyy'
			),
			files: e.files,
		};
		tmp.push({ ...reservation, id: nbpersonne });
		setRows(tmp);
	};

	// confirmation
	const [actions, setactions] = useState(null);
	const [confirmationValues, setconfirmationValues] = useState();
	const [confirmation, setconfirmation] = React.useState(false);
	const handleOpenConfirmation = () => {
		setconfirmation(true);
	};
	const handleCloseConfirmation = () => setconfirmation(false);

	const { user, usr, getUsr } = UserAuth();

	useEffect(() => {
		setmontant(usr ? usr.versement : 0);
		// getUser()
	}, [usr]);

	useEffect(() => {
		getUsr();

		console.log(props.programme?.files);
		console.log(props.programme?.files?.length);
	}, []);

	//////////////// stepper

	const steps = [
		'Nombre de personne adultes et enfant',
		'Réservations',
		'Confirmation',
	];

	const [activeStep, setActiveStep] = React.useState(0);
	const [skipped, setSkipped] = React.useState(new Set());

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	///  snack bar
	const [snake, setSnake] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const handleSnake = async () => {
		setSnake(true);
	};
	const handleCloseSnake = () => setSnake(false);
	const myRef = React.useRef(null);

	////  test nb personne / chambre

	const typeToNumber = (type) => {
		switch (type) {
			case 'Single':
				return 0;
			case 'Double':
				return 1;

			case 'Triple':
				return 2;

			case 'Quadruple':
				return 3;
			default:
				return 0;
		}
	};

	const testChambreNbPersonne = (values) => {
		return (
			calculePersonnes(values.nbadults, values.enfants) >
			values.depart.places - values.depart.reservations
		);
	};

	const [nbTransport, setnbTransport] = useState(0);
	const [selectedChamber, setselectedChamber] = useState(0);

	const calculTotal = (values) => {
		let price = {
			priceAdulte: props.programme.priceAdulte,
			priceEnfants: props.programme.enfants,
		};

		let total = price.priceAdulte[selectedChamber] * values.nbadults;

		let x = 0;
		for (let index in values.enfants) {
			x =
				x +
				parseInt(values.enfants[index]) *
					price.priceEnfants[index].prix[selectedChamber];
		}

		total = total + x + props.programme.prixTransport * nbTransport;
		return total;
	};

	const selectedInformations = (values) => {
		return (
			<Grid container>
				<Grid item xs={12} md={6}>
					<Typography variant="h6">
						<strong>
							<FileCopy></FileCopy>
							Depart / retour :
						</strong>{' '}
						<FlightTakeoff></FlightTakeoff> -{' '}
						{values.depart.depature} / <FlightLand></FlightLand> -{' '}
						{values.depart.return}
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography variant="h6">
						<strong>
							<FileCopy></FileCopy>
							Chambre :
						</strong>{' '}
						{values.chambre}
					</Typography>
				</Grid>
				<Grid item xs={12} md={6}>
					<Typography variant="h6">
						<strong>
							<FileCopy></FileCopy>
							Nomber adultes :
						</strong>{' '}
						{values.nbadults}
					</Typography>
				</Grid>

				{values.enfants &&
					values.enfants.map((item, idx) => (
						<Grid item xs={12} md={6}>
							<Typography variant="h6">
								<strong>
									<FileCopy></FileCopy>
									Nomber enfants{' '}
									{props.programme.enfants[idx].age} :
								</strong>{' '}
								{item}
							</Typography>
						</Grid>
					))}

				<Grid item xs={12} md={6}>
					<Typography variant="h6">
						<strong>
							<FileCopy></FileCopy>
							Nomber total de personnes :
						</strong>{' '}
						{calculePersonnes(values.nbadults, values.enfants)}
					</Typography>
				</Grid>
			</Grid>
		);
	};

	const calculePersonnes = (adulte, enfants) => {
		let total = adulte;
		for (let index in enfants) {
			total = total + parseInt(enfants[index]);
		}
		return total;
	};

	const testCurrentPersonne = (values) => {
		let i = rows.length;

		if (i < values.nbadults) {
			return 'Adulte n° ' + (i + 1);
		}

		i = i - values.nbadults;
		let x = 0;
		while (x < props.programme.enfants.length) {
			if (i < values.enfants[x]) {
				return (
					'Enfant ' +
					props.programme.enfants[x].age +
					' n° ' +
					(i + 1)
				);
			} else {
				x = x + 1;
				i = i - 1;
			}
		}

		return '';
		//  return
	};

	return (
		<div>
			<Snackbar
				open={snake}
				autoHideDuration={5000}
				onClose={handleCloseSnake}>
				<Alert
					onClose={handleCloseSnake}
					severity="success"
					sx={{ width: '100%' }}>
					La réservation a été ajouté !
				</Alert>
			</Snackbar>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={confirmation}
				onClose={handleCloseConfirmation}>
				<DialogTitle>
					<strong>Confirmation</strong>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						êtes-vous sûr de vouloir confirmer ses réservations ?{' '}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						autoFocus
						color="inherit"
						onClick={handleCloseConfirmation}>
						Annuler
					</Button>
					<LoadingButton
						loading={loading}
						variant="contained"
						onClick={ajouterListClients}>
						Confirmer
					</LoadingButton>
				</DialogActions>
			</Dialog>
			{props.programme.enfants && (
				<div className="row">
					<Formik
						initialValues={{
							nom: '',
							phone: '',
							age: null,
							passport: '',
							expiration_passport: '',
							visa: '',
							chambre: 'Single',
							depart: '',
							files: [],
							nbpersonne: 0,
							nbadults: 0,
							enfants: props.programme.enfants.map((item) => 0),
							transport: false,
						}}
						validationSchema={Yup.object().shape({
							nom: Yup.string()
								.max(255)
								.required('Champ obligatoire'),
							phone: Yup.string().test(
								'phone',
								'Le numéro est invalide (0123456789)',
								(value) =>
									!Number.isNaN(Number(value)) &&
									value.length == 10 &&
									value[0] == '0'
							),
							age: Yup.date().required('Champ obligatoire'),
							passport: Yup.string()
								.when('age', (showEmail, schema) => {
									if (props.programme.type != 'national')
										return schema.required(
											'Champ obligatoire'
										);
									return schema;
								})
								.test(
									'passport',
									'Le numéro est invalide',
									(value) =>
										!Number.isNaN(Number(value)) ||
										props.programme.type == 'national'
								),
							expiration_passport: Yup.date().when(
								'age',
								(showEmail, schema) => {
									if (props.programme.type != 'national') {
										return schema.required(
											'Champ obligatoire'
										);
									}
									return schema;
								}
							),
							visa: Yup.string()
								.max(255)
								.when('age', (showEmail, schema) => {
									if (props.programme.type != 'national') {
										return schema.required(
											'Champ obligatoire'
										);
									}
									return schema;
								}),
							chambre: Yup.string()
								.max(255)
								.required('Champ obligatoire'),
							nbadults: Yup.number()
								.min(0, 'Min 0 personne')
								.required('Champ obligatoire'),
							files: Yup.mixed()
								.test(
									'files',
									'Fichier obligatoire',
									(value) => {
										return (
											(value && value.length != 0) ||
											props.programme.type ==
												'national' ||
											props.programme.files.length == 0
										);
									}
								)
								.test(
									'files',
									'Taille de fichier (taille de fichier < 20mb)',
									(value) => {
										function checkSize(file) {
											return file.size > 20000000;
										}
										return (
											value &&
											value.filter(checkSize).length == 0
										);
									}
								)
								.test(
									'files',
									'Format accepté: .pdf',
									(value) => {
										function checkType(file) {
											return (
												file.type != 'application/pdf'
											);
										}
										return (
											value &&
											value.filter(checkType).length == 0
										);
									}
								),
						})}
						onSubmit={(values, actions, setFieldValue) => {
							// handleOpenConfirmation(values);
							console.log(values);
							setactions(actions);
							addToTable(values);

							values.nom = '';
							values.age = null;
							values.passport = '';
							values.expiration_passport = '';
							values.visa = '';
							values.files = [];
							values.transport = false;
						}}>
						{({
							setFieldValue,
							isValid,
							values,
							errors,
							touched,
							handleChange,
							handleBlur,
							handleSubmit,
							isSubmitting,
						}) => (
							<Form>
								<div className="col-lg-12">
									<div className="package-sidebar">
										<aside className="package-widget-style-2 widget-form">
											<div className="widget-title text-center d-flex justify-content-between">
												<h4>Réservation</h4>
												<h3 className="widget-lavel">
													<span>Crédit </span>
													{montant + ' DA'}
												</h3>
												<h3 className="widget-lavel">
													<span>Total : </span>
													{calculTotal(values) +
														' DA'}
												</h3>

												<h3 className="widget-lavel">
													<div>
														{values.depart.places -
														values.depart
															.reservations
															? values.depart
																	.places -
															  values.depart
																	.reservations
															: ''}
														<span> Places </span>
													</div>
												</h3>
											</div>
											<div className="widget-body">
												<Stepper
													activeStep={activeStep}>
													{steps.map(
														(label, index) => {
															const stepProps =
																{};
															const labelProps =
																{};

															return (
																<Step
																	key={label}
																	{...stepProps}>
																	<StepLabel
																		{...labelProps}>
																		{label}
																	</StepLabel>
																</Step>
															);
														}
													)}
												</Stepper>

												{
													//  la page des chambers
													activeStep === 0 && (
														<div>
															<div className="booking-form-wrapper">
																<div className="row">
																	<div className="col-sm-6">
																		<div className="custom-input-group">
																			<p>
																				Date
																				de
																				depart
																				/
																				retour
																				:
																			</p>
																		</div>
																		<TextField
																			select
																			error={Boolean(
																				touched.dapart &&
																					errors.dapart
																			)}
																			helperText={
																				touched.dapart &&
																				errors.dapart
																			}
																			className={
																				classes.root
																			}
																			fullWidth
																			value={
																				values.dapart
																			}
																			onChange={(
																				event,
																				value
																			) => {
																				setFieldValue(
																					'depart',
																					event
																						.target
																						.value
																				);
																				console.log(
																					event
																						.target
																						.value
																				);
																			}}
																			placeholder="Date de
																	depart /
																	retour "
																			name="dapart">
																			{props.programme?.departs?.map(
																				(
																					item,
																					ind
																				) =>
																					new Date(
																						item.depature
																							.split(
																								'-'
																							)
																							.reverse()
																							.join(
																								'-'
																							)
																					).getTime() >
																						new Date().getTime() && (
																						<MenuItem
																							key={
																								ind
																							}
																							value={
																								item
																							}>
																							<FlightTakeoff></FlightTakeoff>{' '}
																							-{' '}
																							{
																								item.depature
																							}{' '}
																							/{' '}
																							<FlightLand></FlightLand>{' '}
																							-{' '}
																							{
																								item.return
																							}

																							/{' '}
																							<Group></Group>{' '}
																							-
																							{
																								' Places : '
																							}
																							{item.places -
																								item.reservations}
																						</MenuItem>
																					)
																			)}
																		</TextField>
																	</div>
																</div>
																<div className="row">
																	<div className="col-sm-6">
																		<div className="custom-input-group">
																			<p>
																				Chambre:
																			</p>
																		</div>
																		<TextField
																			error={Boolean(
																				touched.chambre &&
																					errors.chambre
																			)}
																			helperText={
																				touched.chambre &&
																				errors.chambre
																			}
																			fullWidth
																			className={
																				classes.root
																			}
																			select
																			name="chambre"
																			onChange={(
																				event,
																				value
																			) => {
																				setselectedChamber(
																					typeToNumber(
																						event
																							.target
																							.value
																					)
																				);
																				setFieldValue(
																					'chambre',
																					event
																						.target
																						.value
																				);
																			}}
																			value={
																				values.chambre
																			}
																			variant="outlined">
																			<MenuItem
																				key={
																					0
																				}
																				value={
																					'Single'
																				}>
																				Single
																			</MenuItem>
																			<MenuItem
																				key={
																					1
																				}
																				value={
																					'Double'
																				}>
																				Double
																			</MenuItem>
																			<MenuItem
																				key={
																					2
																				}
																				value={
																					'Triple'
																				}>
																				Triple
																			</MenuItem>
																			<MenuItem
																				key={
																					3
																				}
																				value={
																					'Quadruple'
																				}>
																				Quadruple
																			</MenuItem>
																		</TextField>
																	</div>
																</div>
																<div className="row">
																	<div className="col-sm-6">
																		<div className="custom-input-group">
																			<p>
																				Nombre
																				d'adultes:
																				<strong>
																					{' (' +
																						props
																							.programme
																							.priceAdulte[
																							selectedChamber
																						] +
																						' DA par adulte)'}
																				</strong>
																			</p>
																		</div>
																		<TextField
																			error={Boolean(
																				touched.nbadults &&
																					errors.nbadults
																			)}
																			helperText={
																				touched.nbadults &&
																				errors.nbadults
																			}
																			className={
																				classes.root
																			}
																			fullWidth
																			value={
																				values.nbadults
																			}
																			onChange={
																				handleChange
																			}
																			type="number"
																			placeholder="Nombre
																	d'adultes"
																			name="nbadults"
																			inputProps={{
																				min: 0,
																			}}
																		/>
																	</div>
																</div>
																{props.programme
																	.enfants &&
																	props.programme.enfants.map(
																		(
																			item,
																			idx
																		) => (
																			<div className="row">
																				<div className="col-sm-6">
																					<div className="custom-input-group">
																						<p>
																							{'Enfants entre ' +
																								item.age +
																								''}

																							:
																							<strong>
																								{' (' +
																									props
																										.programme
																										.enfants[
																										idx
																									]
																										.prix[
																										selectedChamber
																									] +
																									' DA par enfant)'}
																							</strong>
																						</p>
																					</div>
																					<TextField
																						error={Boolean(
																							touched.nbenf0 &&
																								errors.nbenf0
																						)}
																						helperText={
																							touched.nbenf0 &&
																							errors.nbenf0
																						}
																						className={
																							classes.root
																						}
																						fullWidth
																						value={
																							values.enfants &&
																							values
																								.enfants[
																								idx
																							]
																						}
																						onChange={(
																							event
																						) => {
																							let tmp =
																								[
																									...values.enfants,
																								];
																							tmp[
																								idx
																							] =
																								event.target.value;
																							setFieldValue(
																								'enfants',
																								tmp
																							);
																						}}
																						type="number"
																						placeholder={
																							'Enfants entre (' +
																							item.age +
																							'ans)'
																						}
																						name="enfants"
																						inputProps={{
																							min: 0,
																						}}
																					/>
																				</div>
																			</div>
																		)
																	)}
															</div>

															{testChambreNbPersonne(
																values
															) &&
															calculePersonnes(
																values.nbadults,
																values.enfants
															) != 0 ? (
																<Alert severity="error">
																	{
																		'Verifiez le number de personnes !(nbpersonnes > places disponibles )'
																	}
																</Alert>
															) : (
																<Box
																	sx={{
																		display:
																			'flex',
																		flexDirection:
																			'row',
																		pt: 2,
																	}}>
																	<Box
																		sx={{
																			flex: '1 1 auto',
																		}}
																	/>

																	<Button
																		disabled={
																			values.nbadults <=
																				0 ||
																			values.chambre ==
																				'' ||
																			values.depart ==
																				'' ||
																			testChambreNbPersonne(
																				values
																			)
																		}
																		variant="contained"
																		endIcon={
																			<ArrowForward></ArrowForward>
																		}
																		onClick={
																			handleNext
																		}>
																		Suivant
																	</Button>
																</Box>
															)}
														</div>
													)
												}
												{
													//  la page de réservations
													activeStep === 1 && (
														<React.Fragment>
															<Box
																sx={{
																	display:
																		'flex',
																	flexDirection:
																		'row',
																	pt: 2,
																	mt: 2,
																}}>
																<Button
																	variant="outlined"
																	startIcon={
																		<ArrowBack></ArrowBack>
																	}
																	color="inherit"
																	disabled={
																		activeStep ===
																		0
																	}
																	onClick={
																		handleBack
																	}
																	sx={{
																		mr: 1,
																	}}>
																	Retour
																</Button>
																<Box
																	sx={{
																		flex: '1 1 auto',
																	}}
																/>
																<Button
																	disabled={
																		nbpersonne <
																		calculePersonnes(
																			values.nbadults,
																			values.enfants
																		)
																	}
																	variant="contained"
																	endIcon={
																		<ArrowForward></ArrowForward>
																	}
																	onClick={
																		handleNext
																	}>
																	Réservez
																</Button>
															</Box>
															<Divider
																sx={{
																	mt: 2,
																	mb: 2,
																}}></Divider>
															{
																//  message pour continuer
															}
															<div>
																{nbpersonne >=
																	calculePersonnes(
																		values.nbadults,
																		values.enfants
																	) && (
																	<div>
																		<Typography variant="h5">
																			<strong>
																				Total
																				:{' '}
																			</strong>
																			{calculTotal(
																				values
																			)}{' '}
																			DA
																		</Typography>
																		<Typography variant="h5">
																			Cliquer
																			sur
																			suivant
																			pour
																			countinuer
																		</Typography>
																	</div>
																)}
															</div>
															{
																//  les informations
															}
															<div
																style={{
																	marginTop:
																		'3vh',
																}}>
																{selectedInformations(
																	values
																)}
															</div>

															{
																// typography
															}

															{nbpersonne <
																calculePersonnes(
																	values.nbadults,
																	values.enfants
																) && (
																<div>
																	<Typography
																		sx={{
																			mt: 2,
																		}}
																		variant="h4">
																		{testCurrentPersonne(
																			values
																		)}
																	</Typography>
																	<div className="booking-form-wrapper">
																		<div className="row">
																			<div className="col-sm-6">
																				<div className="custom-input-group">
																					<p>
																						Nom
																						et
																						prénom:
																					</p>
																				</div>
																				<TextField
																					error={Boolean(
																						touched.nom &&
																							errors.nom
																					)}
																					helperText={
																						touched.nom &&
																						errors.nom
																					}
																					className={
																						classes.root
																					}
																					fullWidth
																					value={
																						values.nom
																					}
																					onChange={
																						handleChange
																					}
																					type="text"
																					placeholder="Nom et prenom"
																					name="nom"
																				/>
																			</div>

																			{nbpersonne <
																				values.nbadults &&
																				nbpersonne ===
																					0 && (
																					<div className="col-sm-6">
																						<div className="custom-input-group">
																							<p>
																								Numéro
																								de
																								téléphone:
																							</p>
																						</div>
																						<TextField
																							id="ref"
																							ref={
																								myRef
																							}
																							error={Boolean(
																								touched.phone &&
																									errors.phone
																							)}
																							helperText={
																								touched.phone &&
																								errors.phone
																							}
																							fullWidth
																							className={
																								classes.root
																							}
																							value={
																								values.phone
																							}
																							onChange={
																								handleChange
																							}
																							type="tel"
																							placeholder="Numero de telephone"
																							name="phone"
																						/>
																					</div>
																				)}

																			<div className="col-sm-6">
																				<div className="custom-input-group">
																					<p>
																						Date
																						de
																						naissance:
																					</p>
																				</div>
																				<LocalizationProvider
																					fullWidth
																					dateAdapter={
																						AdapterDateFns
																					}
																					adapterLocale={
																						fr
																					}>
																					<DesktopDatePicker
																						className={
																							classes.root
																						}
																						value={
																							values.age
																						}
																						name="age"
																						onChange={(
																							event,
																							value
																						) => {
																							setFieldValue(
																								'age',
																								event
																							);
																						}}
																						ampm={
																							false
																						}
																						inputFormat="dd/MM/yyyy"
																						maxDate={
																							new Date()
																						}
																						onBlur={
																							handleBlur
																						}
																						variant="outlined"
																						renderInput={(
																							params
																						) => (
																							<TextField
																								placeholder="Date de naissance"
																								fullWidth
																								error={Boolean(
																									touched.age &&
																										errors.age
																								)}
																								helperText={
																									touched.age &&
																									errors.age
																								}
																								{...params}
																							/>
																						)}
																					/>
																				</LocalizationProvider>
																			</div>
																		</div>

																		{props
																			.programme
																			.type ==
																		'national' ? (
																			<div></div>
																		) : (
																			<div>
																				<div className="row">
																					<div className="col-sm-6">
																						<div className="custom-input-group">
																							<p>
																								Numéro
																								de
																								passport:
																							</p>
																						</div>
																						<TextField
																							error={Boolean(
																								touched.passport &&
																									errors.passport
																							)}
																							helperText={
																								touched.passport &&
																								errors.passport
																							}
																							fullWidth
																							className={
																								classes.root
																							}
																							value={
																								values.passport
																							}
																							onChange={
																								handleChange
																							}
																							type="text"
																							placeholder="Numero de passport"
																							name="passport"
																						/>
																					</div>
																					<div className="col-sm-6">
																						<div className="custom-input-group">
																							<p>
																								Expiration
																								passeport:
																							</p>
																						</div>
																						<LocalizationProvider
																							fullWidth
																							dateAdapter={
																								AdapterDateFns
																							}
																							adapterLocale={
																								fr
																							}>
																							<DesktopDatePicker
																								className={
																									classes.root
																								}
																								value={
																									values.expiration_passport
																								}
																								type="date"
																								name="expiration_passport"
																								onChange={(
																									event,
																									value
																								) => {
																									setFieldValue(
																										'expiration_passport',
																										event
																									);
																								}}
																								ampm={
																									false
																								}
																								inputFormat="dd/MM/yyyy"
																								minDate={
																									new Date()
																								}
																								onBlur={
																									handleBlur
																								}
																								variant="outlined"
																								renderInput={(
																									params
																								) => (
																									<TextField
																										placeholder="Expiration passport"
																										error={Boolean(
																											touched.expiration_passport &&
																												errors.expiration_passport
																										)}
																										fullWidth
																										helperText={
																											touched.expiration_passport &&
																											errors.expiration_passport
																										}
																										{...params}
																									/>
																								)}
																							/>
																						</LocalizationProvider>
																					</div>
																					<div className="col-sm-6">
																						<div className="custom-input-group">
																							<p>
																								Visa:
																							</p>
																						</div>
																						<TextField
																							error={Boolean(
																								touched.visa &&
																									errors.visa
																							)}
																							helperText={
																								touched.visa &&
																								errors.visa
																							}
																							fullWidth
																							className={
																								classes.root
																							}
																							select
																							name="visa"
																							onChange={(
																								event,
																								value
																							) => {
																								setFieldValue(
																									'visa',
																									event
																										.target
																										.value
																								);
																							}}
																							value={
																								values.visa
																							}
																							variant="outlined">
																							<MenuItem
																								key={
																									1
																								}
																								value={
																									'Oui'
																								}>
																								Oui
																							</MenuItem>
																							<MenuItem
																								key={
																									2
																								}
																								value={
																									'Non'
																								}>
																								Non
																							</MenuItem>
																							<MenuItem
																								key={
																									3
																								}
																								value={
																									'Pas nécessaire'
																								}>
																								Pas
																								nécessaire
																							</MenuItem>
																						</TextField>
																					</div>
																				</div>
																				{props
																					.programme
																					?.files
																					.length >
																					0 && (
																					<div className="row">
																						<div className="col-sm-6">
																							<div className="row">
																								<div className="col-sm-6">
																									<div className="custom-input-group ">
																										<Typography>
																											<strong>
																												Fichiers
																											</strong>
																										</Typography>
																										<Stack
																											mt={
																												2
																											}
																											direction="row"
																											alignItems="center"
																											spacing={
																												2
																											}>
																											<label htmlFor="button-file">
																												<Button
																													variant="contained"
																													component="span"
																													size="large"
																													startIcon={
																														<UploadFile />
																													}>
																													<Input
																														id="button-file"
																														style={{
																															display:
																																'none',
																														}}
																														onChange={(
																															value
																														) => {
																															if (
																																values
																																	.files
																																	.length <
																																props
																																	.programme
																																	.files
																																	.length
																															) {
																																let tmp =
																																	[
																																		...values.files,
																																	];
																																tmp.push(
																																	value
																																		.currentTarget
																																		.files[0]
																																);

																																setFieldValue(
																																	'files',
																																	tmp
																																);
																															}
																														}}
																														name="files"
																														type="file"
																														inputProps={{
																															accept: '.pdf',
																														}}
																													/>
																													Importer
																												</Button>
																											</label>
																											<Typography>
																												{values
																													.files
																													.length !=
																													0 && (
																													<div>
																														<Button
																															variant="contained"
																															color="error"
																															endIcon={
																																<Delete />
																															}
																															onClick={() =>
																																setFieldValue(
																																	'files',
																																	[]
																																)
																															}>
																															Supprimer
																														</Button>
																													</div>
																												)}
																											</Typography>
																											{touched.files &&
																												errors.files && (
																													<Typography
																														color="error"
																														variant="caption">
																														{
																															errors.files
																														}
																													</Typography>
																												)}
																										</Stack>
																									</div>
																								</div>
																							</div>
																							<div className="custom-input-group">
																								<div className="col">
																									{props.programme?.files.map(
																										(
																											file,
																											index
																										) => (
																											<div>
																												<strong>
																													{
																														file
																													}{' '}
																													...{' '}
																													{values
																														.files
																														.length >=
																													index +
																														1 ? (
																														<Check color="success"></Check>
																													) : (
																														<HourglassBottom color="warning"></HourglassBottom>
																													)}{' '}
																													{values
																														.files
																														.length >=
																														index +
																															1 &&
																														values
																															.files[
																															index
																														]
																															.name}
																												</strong>
																											</div>
																										)
																									)}
																								</div>
																							</div>
																						</div>
																					</div>
																				)}
																			</div>
																		)}

																		<Box
																			mt={
																				2
																			}
																		/>
																		<div className="row">
																			<div className="col-sm-6">
																				{/* <FormGroup>
																					<FormControlLabel
																						control={
																							<Checkbox
																								name="transport"
																								checked={
																									values.transport
																								}
																								onChange={() => {
																									if (
																										!values.transport ==
																										true
																									) {
																										setnbTransport(
																											(
																												prv
																											) =>
																												prv +
																												1
																										);
																									} else {
																										setnbTransport(
																											(
																												prv
																											) =>
																												prv -
																												1
																										);
																									}
																									setFieldValue(
																										'transport',
																										!values.transport
																									);
																								}}
																								inputProps={{
																									'aria-label':
																										'controlled',
																								}}
																							/>
																						}
																						label={
																							'Ajouter le transport (' +
																							props
																								.programme
																								.prixTransport +
																							' DA)'
																						}
																					/>
																				</FormGroup> */}

																				<FormControl>
																					<FormLabel id="demo-controlled-radio-buttons-group">
																						<strong>
																							Transport
																							:
																						</strong>
																					</FormLabel>
																					<RadioGroup
																						aria-labelledby="demo-controlled-radio-buttons-group"
																						name="controlled-radio-buttons-group"
																						value={
																							values.transport
																						}
																						onChange={() => {
																							if (
																								!values.transport ==
																								true
																							) {
																								setnbTransport(
																									(
																										prv
																									) =>
																										prv +
																										1
																								);
																							} else {
																								setnbTransport(
																									(
																										prv
																									) =>
																										prv -
																										1
																								);
																							}
																							setFieldValue(
																								'transport',
																								!values.transport
																							);
																						}}>
																						<FormControlLabel
																							value={
																								true
																							}
																							onClick={() => {}}
																							control={
																								<Radio />
																							}
																							label={
																								'Avec transport (' +
																								props
																									.programme
																									.prixTransport +
																								' DA)'
																							}
																						/>
																						<FormControlLabel
																							value={
																								false
																							}
																							control={
																								<Radio />
																							}
																							label="Sans transport"
																						/>
																					</RadioGroup>
																				</FormControl>
																			</div>
																		</div>

																		<div className="custom-input-group">
																			{loading ? (
																				<Box
																					sx={{
																						display:
																							'flex',
																					}}>
																					<CircularProgress />
																				</Box>
																			) : (
																				<div className="submite-btn">
																					<Button type="submit">
																						Suivant
																					</Button>
																				</div>
																			)}
																		</div>
																	</div>
																</div>
															)}
														</React.Fragment>
													)
												}

												{
													//  la page de confirmation
													activeStep ===
														steps.length - 1 && (
														<React.Fragment>
															<Box
																sx={{
																	display:
																		'flex',
																	flexDirection:
																		'row',
																	pt: 2,
																	mt: 2,
																}}>
																<Button
																	color="inherit"
																	variant="outlined"
																	onClick={
																		handleReset
																	}
																	sx={{
																		mr: 1,
																	}}>
																	Annuler
																</Button>
																<Box
																	sx={{
																		flex: '1 1 auto',
																	}}
																/>
																<Button
																	variant="contained"
																	endIcon={
																		<Check></Check>
																	}
																	onClick={
																		handleOpenConfirmation
																	}>
																	Confirmer
																</Button>
															</Box>
														</React.Fragment>
													)
												}
											</div>
										</aside>
									</div>
								</div>
							</Form>
						)}
					</Formik>
					<div className="col-lg-12 mt-3">
						<div className="tour-package-details">
							<div style={{ height: 480, width: '100%' }}>
								<DataGrid
									rows={rows}
									columns={
										props.programme.type == 'national'
											? columns_national
											: columns
									}
									pageSize={6}
									rowsPerPageOptions={[6]}
									components={{
										NoRowsOverlay: () => (
											<Stack
												height="100%"
												alignItems="center"
												justifyContent="center">
												<Alert severity="error">
													Aucune réservations trouvé !
												</Alert>
											</Stack>
										),
										NoResultsOverlay: () => (
											<Alert severity="error">
												Aucune réservations trouvé !
											</Alert>
										),
									}}
								/>
							</div>
						</div>
					</div>
				</div>
			)}
		</div>
	);
}
