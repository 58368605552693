/** @format */

import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import DestinationCart from '../destinetion/DestinationCart';

function TopDestination() {
	let history = useHistory();

	const json = [
		{
			image: '/images/mecca.jpg',
			place: 'Omra',
			places: '-',
			type: 'omra',
		},
		{
			image: '/images/algeria.jpg',
			place: 'National',
			places: '-',
			type: 'national',
		},
		{
			image: '/images/world.jpg',
			place: 'International',
			places: '-',
			type: 'international',
		},
	];
	const omra_style = 'col-lg-12 col-md-12 col-sm-10 fadeffect';
	const other_style = 'col-lg-4 col-md-6 col-sm-12 fadeffect';
	return (
		<>
			<div className="destination-area destination-style-two pt-110 pb-110">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8 col-sm-10 ">
							<div
								className="section-head-alpha text-center mx-auto"
								id="top-destination">
								<h2>Top Destination</h2>
								<p>
									Découvrez de nouveaux horizons avec nos
									destinations sélectionnées.
								</p>
							</div>
						</div>
					</div>
					<div className="row d-flex justify-content-center g-4">
						{json.map((destination) => (
							<div className={other_style}>
								<Link
									key={destination}
									onClick={() => {
										window.scrollTo({
											top: 0,
											behavior: 'smooth',
										});
										history.push({
											pathname: '/package',
											state: { type: destination.type },
										});
									}}>
									<DestinationCart
										image={
											process.env.PUBLIC_URL +
											destination.image
										}
										palce={destination.place}
										palceCount={destination.places}
									/>
								</Link>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
}

export default TopDestination;
