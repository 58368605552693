// Import the functions you need from the SDKs you need
// import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth } from "firebase/auth";
import { getDatabase } from "firebase/database";


import * as firebase from 'firebase/app'
// TODO: Add SDKs for Firebase products that you want to use

// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyCmfzsR1Ah0TSkihc8ZI4TOPWswk1Y6xro",
    authDomain: "verison-ddd3d.firebaseapp.com",
    projectId: "verison-ddd3d",
    storageBucket: "verison-ddd3d.appspot.com",
    messagingSenderId: "493301007470",
    appId: "1:493301007470:web:1581c3f8db8047a4e7927f",
    measurementId: "G-ZH3NEMSNFZ"
};

// Initialize Firebase
export const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

export const cleanData = (querySnapshot) => {
    const newData = querySnapshot.docs
        .map((doc) => ({ ...doc.data(), id: doc.id }));
    return newData
}

// var functions = firebase.functions();

// Export firestore database
// It will be imported into your react app whenever it is needed
export const db = getFirestore(app);
export const database = getDatabase(app);
export const storage = getStorage(app)
export const auth = getAuth(app);
