import { FilterList, Refresh, UploadFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Card, CardContent, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, MenuItem, TextField, Typography } from '@mui/material';
import { grey } from '@mui/material/colors';
import { Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { collection, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { cleanData, db } from '../../../Firebase';
import { UserAuth } from '../../context/UserContext';
import DialogMesReservation from './DialogMesReservation';
import { table_columns } from './TableColumns';





export default function MesReservations() {

    const [color, setAge] = React.useState('#ff4838');


    const columns = table_columns
        ;


    const [rows, setRows] = React.useState([

    ]);
    const { usr } = UserAuth();


    const getData = async () => {
        setRows([])

        let detailRef = collection(db, "reservation");
        let agence = JSON.parse(sessionStorage.getItem("verison_dt")).nom_agence
        let q = await query(detailRef, where("nom_agence", "==", agence), where("type", "!=", 'omra'))
        let querySnapshot = await getDocs(q)
            .then((querySnapshot) => {
                const newData = cleanData(querySnapshot)
                setRows(newData)
            }).catch((err) => {
                console.log(err);
            })

    }

    const [filter, setfilter] = React.useState(false);

    const handleFilter = async (values) => {
        setRows([])

        var detailRef = null;
        var q = null
        var querySnapshot = null;
        let tmp = []

        let name = values.name.toLowerCase()
        let agence = JSON.parse(sessionStorage.getItem("verison_dt")).nom_agence
        let destination = values.destination

        if (values.depart != null) {
            let depart = format(values.depart, "dd-MM-yyyy")
            if (destination == "") {
                if (name == "") {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("depart", "==", depart), where("type", "!=", 'omra'))
                    querySnapshot = await getDocs(q);
                }
                else {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("nom", "==", name), where("depart", "==", depart), where("type", "!=", 'omra'))
                    querySnapshot = await getDocs(q);
                }

            } else {
                if (name == "") {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("destination", "==", destination), where("depart", "==", depart), where("type", "!=", 'omra'))
                    querySnapshot = await getDocs(q);
                }
                else {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("destination", "==", destination), where("nom", "==", name), where("depart", "==", depart), where("type", "!=", 'omra'))
                    querySnapshot = await getDocs(q);
                }

            }
        }
        else {
            if (destination == "") {
                if (name == "") {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("type", "!=", 'omra'))
                    querySnapshot = await getDocs(q);
                }
                else {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("nom", "==", name), where("type", "!=", 'omra'))
                    querySnapshot = await getDocs(q);
                }
            } else {
                if (name == "") {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("destination", "==", destination), where("type", "!=", 'omra'))
                    querySnapshot = await getDocs(q);
                }
                else {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("destination", "==", destination), where("nom", "==", name), where("type", "!=", 'omra'))
                    querySnapshot = await getDocs(q);
                }
            }
        }

        await querySnapshot.forEach(async (doc) => {
            tmp.push({ ...doc.data(), id: doc.id })
            // doc.data() is never undefined for query doc snapshots
        });

        setRows(tmp)


    }

    const editData = async () => {

        setdone(true)
        let itm = item
        try {
            const docRef = await updateDoc(doc(db, "reservation", itm.id), {
                confirmed: true
            });
            getData()
            setdone(false)
            handleCloseConfirmation()
            handleClose()

        } catch (e) {
            setdone(false)
            handleCloseConfirmation()

        }


    }

    const [destinations, setdestinations] = React.useState([]);
    const getDestinations = async (e) => {
        setdestinations([])

        var detailRef = null;
        var q = null
        var querySnapshot = null;
        let tmp = []

        detailRef = collection(db, "destination_detail");
        q = await query(detailRef, where("type", "!=", "omra"))
        querySnapshot = await getDocs(q);

        await querySnapshot.forEach(async (doc) => {
            tmp.push({ ...doc.data(), id: doc.id })
            // doc.data() is never undefined for query doc snapshots
        });
        setdestinations(tmp)

    };



    useEffect(() => {
        getData()
        getDestinations()
    }, [])



    // dialog
    const [item, setItem] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);


    const [itemIndex, setitemIndex] = useState(0);
    const [itemDeatil, setitemDeatil] = useState({});

    const [done, setdone] = useState(false);
    const [confirmation, setconfirmation] = React.useState(false);
    const handleOpenConfirmation = (values) => {
        setconfirmation(true);
    };
    const handleCloseConfirmation = () => setconfirmation(false);

    return (
        <div style={{ backgroundColor: grey[900] }}>
            <div style={{ backgroundColor: grey[900], paddingTop: 90, }}>
            </div>

            <div style={{ backgroundColor: "white", paddingTop: 20, height: filter ? 1000 : 720 }}>
                <div style={{ height: 640, width: '100%', }}>
                    <Dialog
                        fullWidth
                        maxWidth="sm"
                        open={confirmation}
                        onClose={handleCloseConfirmation}>
                        <DialogTitle>
                            <strong>Confirmation</strong>
                        </DialogTitle>
                        <DialogContent>
                            <DialogContentText>
                                êtes-vous sûr de vouloir confirmer cette modification ?{' '}
                            </DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button
                                variant="outlined"
                                autoFocus
                                color="inherit"
                                onClick={handleCloseConfirmation}>
                                Annuler
                            </Button>
                            <LoadingButton loading={done} variant="contained" onClick={editData}>
                                Confirmer
                            </LoadingButton>
                        </DialogActions>
                    </Dialog>

                    <Dialog
                        scroll='body'
                        fullWidth
                        maxWidth="md"
                        open={open}
                        onClose={handleClose}
                    >
                        <DialogTitle>
                            <Stack justifyContent={"space-between"} direction="row" alignItems={"center"}>
                                <Typography variant='h4'>
                                    <strong>
                                        Informations
                                    </strong>
                                </Typography>
                            </Stack>

                        </DialogTitle>
                        <DialogMesReservation data={item}></DialogMesReservation>

                    </Dialog>
                    <Container>
                        <Stack
                            direction={"row"}
                            alignItems="center"
                            justifyContent="space-between"
                        >
                            <Typography variant='h3' mb={2}>
                                <strong>
                                    <i className="bi bi-file-diff-fill"></i> Réservations
                                </strong>
                            </Typography>
                            <Button onClick={() => setfilter(!filter)} style={{ backgroundColor: color }} variant='contained' endIcon={<FilterList ></FilterList>}>filter</Button>

                        </Stack>
                        <Collapse in={filter}>
                            <Card>

                                <CardContent>
                                    <Typography variant='h6' mb={2}>
                                        <strong>
                                            <i style={{ color: color }} className="bi bi-search"></i> Filter
                                        </strong>
                                    </Typography>

                                    <Formik
                                        initialValues={{
                                            name: "",
                                            destination: "",
                                            depart: null
                                        }}
                                        validationSchema={
                                            Yup.object().shape({
                                                // agence: Yup.string().max(255).required('champ obligatoire'),
                                                // name: Yup.string().max(255).required('champ obligatoire'),
                                            }

                                            )
                                        }
                                        onSubmit={(values) => {
                                            handleFilter(values)
                                        }}
                                    >
                                        {({

                                            setFieldValue,
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting, isValid
                                        }) => (
                                            <Form>
                                                <div className="row">

                                                    <div className="col-sm-6">
                                                        <TextField
                                                            // disabled={values.agence != ""}

                                                            error={Boolean(touched.name && errors.name)}
                                                            fullWidth
                                                            helperText={touched.name && errors.name}
                                                            label="Nom client"
                                                            margin="normal"
                                                            name="name"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.name}
                                                            variant="outlined"

                                                        >

                                                        </TextField>

                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-sm-6'>
                                                        <TextField
                                                            error={Boolean(touched.destination && errors.destination)}
                                                            fullWidth
                                                            helperText={touched.destination && errors.destination}
                                                            label="Destination"
                                                            margin="normal"
                                                            name="destination"
                                                            onBlur={handleBlur}
                                                            onChange={(
                                                                event,
                                                                value
                                                            ) => {
                                                                setFieldValue(
                                                                    'destination',
                                                                    event.target
                                                                        .value
                                                                );
                                                            }}
                                                            value={values.destination}
                                                            variant="outlined"
                                                            select
                                                        >
                                                            {

                                                                destinations.map((destination, index) => {

                                                                    return (
                                                                        <MenuItem key={index} value={destination.title}>
                                                                            {destination.title}
                                                                        </MenuItem>)
                                                                })
                                                            }

                                                        </TextField>

                                                    </div>
                                                    <div className='col-sm-6 mt-3'>
                                                        <LocalizationProvider
                                                            adapterLocale={fr}
                                                            dateAdapter={AdapterDateFns}
                                                        >
                                                            <DesktopDatePicker
                                                                error={Boolean(touched.depart && errors.depart)}
                                                                helperText={touched.depart && errors.depart}
                                                                fullWidth
                                                                label="Départ"
                                                                name="depart"
                                                                ampm={false}
                                                                inputFormat="dd/MM/yyyy"
                                                                onBlur={handleBlur}
                                                                onChange={(event, value) => {
                                                                    setFieldValue("depart", event)
                                                                }}
                                                                value={values.depart}
                                                                variant="outlined"
                                                                renderInput={(params) => <TextField {...params} />}
                                                            />
                                                        </LocalizationProvider>
                                                    </div>


                                                </div>
                                                <div className='row'>
                                                    <div className='col'>

                                                        <Button type='submit' style={{ backgroundColor: color }} variant='contained'>Appliquer</Button>
                                                        <IconButton onClick={() => {
                                                            setFieldValue("name", "")
                                                            setFieldValue("destination", "")
                                                            setFieldValue("depart", null)
                                                            getData()
                                                        }}>
                                                            <Refresh></Refresh>
                                                        </IconButton>
                                                    </div>
                                                </div>
                                            </Form>)}
                                    </Formik>
                                </CardContent>

                            </Card>
                        </Collapse>
                    </Container>

                    <DataGrid
                        sx={{ mt: 2 }}
                        disableColumnMenu
                        disableColumnFilter
                        rows={rows}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5]}
                        onCellClick={(cell) => {
                            setItem(cell.row)
                            setitemDeatil(cell.row.members[0])
                            handleOpen()
                        }}

                        components={{
                            NoRowsOverlay: () => (
                                <Stack height="100%" alignItems="center" justifyContent="center">
                                    <Alert severity="error">
                                        Aucune réservations trouvé !
                                    </Alert>
                                </Stack>
                            ),
                            NoResultsOverlay: () => (
                                <Alert severity="error">
                                    Aucune réservations trouvé !
                                </Alert>
                            )
                        }}

                    />
                </div>

            </div>

        </div>
    );
}