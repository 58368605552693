import { Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";

function AdminPackageCard(props) {

    const [sum, setsum] = useState(0);
    const calculerReservations = () => {
        let tmp = 0
        for (var i = 0; i < props.programme?.departs.length; i++) {
            tmp = tmp + props.programme?.departs[i].reservations
        }
        setsum(tmp)
    };


    useEffect(() => {
        calculerReservations()
    }, [props.programme?.departs.length])


    return (
        <>
            <div className="package-card-alpha" >


                <div className="package-thumb">

                    <img src={props.image} alt="images" style={{ height: 300 }} />

                    <p className="card-lavel">
                        <i className="bi bi-clock" /> <span>{props.date}</span>
                    </p>
                </div>
                <div className="package-card-body">
                    <h3 className="p-card-title" style={{ height: 50, wordWrap: 'break-word', overflow: "hidden", textOverflow: "ellipsis" }}>
                        {props.title}

                    </h3>
                    <div className="row">
                        <Typography>
                            Réservations : {sum} personnes
                        </Typography>
                    </div>
                    <div className="p-card-bottom">
                        <div className="book-btn">
                            <Link
                            >
                                Details <i className="bx bxs-right-arrow-alt" />
                            </Link>

                        </div>
                        <div className="p-card-info">

                            <h6>
                                {props.price} <span>Par Person</span>
                            </h6>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminPackageCard;
