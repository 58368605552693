import { Check, FlightLand, FlightTakeoff, Group, MonetizationOn } from '@mui/icons-material'
import { Divider, Typography } from '@mui/material'
import React from 'react'

export default function DetailTable(props) {
    let color = '#ff4838'

    let programme = props.programme
    return (
        <table className="table package-info-table mb-0">
            <tbody>
                <tr>
                    <th>Durée</th>
                    <td>
                        {
                            programme.duration
                        }
                    </td>
                </tr>
                <tr>
                    <th>Prix</th>
                    <td>

                        <Typography variant="body1" gutterBottom>
                            <MonetizationOn
                                style={{
                                    color: color,
                                }}></MonetizationOn>
                            <strong>
                                - Prix adulte : {' '}
                            </strong>
                            <ul
                                style={{ paddingLeft: 30 }}
                            >
                                <li>
                                    <Check /> Chamber single :
                                    <strong>
                                        {
                                            programme.priceAdulte && programme.priceAdulte[0] + " DA"
                                        }
                                    </strong>
                                </li>
                                <li>
                                    <Check /> Chamber double :
                                    <strong>
                                        {
                                            programme.priceAdulte && programme.priceAdulte[1] + " DA"
                                        }
                                    </strong>
                                </li>
                                <li>
                                    <Check /> Chamber triple :
                                    <strong>
                                        {
                                            programme.priceAdulte && programme.priceAdulte[2] + " DA"
                                        }
                                    </strong>
                                </li>
                                <li>
                                    <Check /> Chamber Quadruple :
                                    <strong>
                                        {
                                            programme.priceAdulte && programme.priceAdulte[3] + " DA"
                                        }
                                    </strong>
                                </li>
                            </ul>
                        </Typography>

                        {
                            programme.enfants &&
                            programme.enfants.map((item, ind) =>
                                <div>
                                    <div className="col-sm-6">
                                        <Typography variant="body1" gutterBottom>
                                            <MonetizationOn
                                                style={{
                                                    color: color,
                                                }}></MonetizationOn>
                                            <strong>
                                                - Prix enfant {item.age} : {' '}
                                            </strong>
                                            <ul
                                                style={{ paddingLeft: 30 }}
                                            >
                                                <li>
                                                    <Check /> Chamber single :
                                                    <strong>
                                                        {
                                                            item.prix[0] + " DA"
                                                        }
                                                    </strong>
                                                </li>
                                                <li>
                                                    <Check /> Chamber double :
                                                    <strong>
                                                        {
                                                            item.prix[1] + " DA"
                                                        }
                                                    </strong>
                                                </li>
                                                <li>
                                                    <Check /> Chamber triple :
                                                    <strong>
                                                        {
                                                            item.prix[2] + " DA"
                                                        }
                                                    </strong>
                                                </li>
                                                <li>
                                                    <Check /> Chamber Quadruple :
                                                    <strong>
                                                        {
                                                            item.prix[3] + " DA"
                                                        }
                                                    </strong>
                                                </li>
                                            </ul>
                                        </Typography>
                                    </div>
                                </div>
                            )
                        }

                    </td>
                </tr>
                <tr>
                    <th>Prix de Transport</th>
                    <td>
                        <Typography variant="body1" gutterBottom>
                            <MonetizationOn
                                style={{
                                    color: color,
                                }}></MonetizationOn>

                            {
                                programme.prixTransport + " DA"
                            }
                        </Typography>
                    </td>

                </tr>
                <tr>
                    <th>Destination</th>
                    <td>
                        {
                            programme.destination
                        }
                    </td>
                </tr>
                <tr>
                    <th>Hôtel</th>
                    <td>
                        {programme.hotel}
                    </td>
                </tr>

                <tr>
                    <th>Depart / retour</th>
                    <td>
                        <ul className="included-list">
                            {programme.departs &&
                                programme.departs.map(
                                    (
                                        item,
                                        ind
                                    ) => (
                                        <li
                                            key={
                                                ind
                                            }
                                            value={
                                                item
                                            }>
                                            <FlightTakeoff style={{
                                                color: color,
                                            }}></FlightTakeoff>{' '}
                                            :{' '}
                                            {
                                                item.depature
                                            }{' '}

                                            <FlightLand
                                                style={{ marginLeft: 30, color: color, }}

                                            ></FlightLand>{' '}
                                            :{' '}
                                            {
                                                item.return
                                            }
                                            {' '}
                                            {' '}
                                            <Group style={{ marginLeft: 30, color: color, }}></Group> Places : {item.places}
                                            <Divider sx={{ width: 350, mt: 2 }}></Divider>
                                        </li>
                                    )
                                )}
                        </ul>
                    </td>
                </tr>

                <tr>
                    <th>Inclue</th>
                    <td>
                        <ul className="included-list">
                            {programme.include &&
                                programme.include.map(
                                    (
                                        item,
                                        index
                                    ) => (
                                        <li
                                            key={
                                                index
                                            }>
                                            <i className="bi bi-check2" style={{
                                                color: color,
                                            }} />
                                            {
                                                item
                                            }
                                        </li>
                                    )
                                )}
                        </ul>
                    </td>
                </tr>
            </tbody>
        </table >
    )
}
