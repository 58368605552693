/** @format */

import { grey } from '@mui/material/colors';
import React from 'react';
import Footer from './common/Footer';
import Header from './common/Header';
import AdminHeader from './pages/admin/AdminHeader';

function App(props) {
	return (
		<>
			{[
				'destination',
				'package',
				'mesreservations',
				'mesreservationsomra',
				'package-details',
				'',
				'404',
				'about',
				'contact',
				'hotelerie',
				'billetterie',
				'signin',
				'visa',
				'mesvisa',
				'inscription',
			].includes(window.location.href.split('/').at(-1)) ? (
				<div>
					<Header />
					{props.children}
					<Footer className="footer-area mt-50" />
				</div>
			) : (
				<div>
					<AdminHeader />
					{props.children}
					<div className="footer-area pt-100" />
				</div>
			)}
		</>
	);
}

export default App;
