/** @format */

import { Alert, Card, CardContent, Collapse } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { grey } from '@mui/material/colors';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { signInWithEmailAndPassword } from 'firebase/auth';
import * as React from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { auth } from '../../../Firebase';

function Copyright(props) {
	return (
		<Typography
			variant="body2"
			color="text.secondary"
			align="center"
			{...props}>
			{'Copyright © '}
			<Link color="inherit">Version</Link> {new Date().getFullYear()}
			{'.'}
		</Typography>
	);
}

const theme = createTheme();

export default function Signin() {
	let history = useHistory();

	const handleSubmit = (event) => {
		event.preventDefault();
		handleCloseSnake();
		const data = new FormData(event.currentTarget);

		signInWithEmailAndPassword(
			auth,
			data.get('email'),
			data.get('password')
		)
			.then((userCredential) => {
				// Signed in
				const user = userCredential.user;
				// ...
				if (user.displayName == 'client') {
					history.push('/');
				} else {
					history.push('/admin/destinations');
				}
			})
			.catch((error) => {
				const errorCode = error.code;
				const errorMessage = error.message;
				console.log(errorCode, errorMessage);
				handleSnake();
			});

		// if (data.get('email') == "admin") {
		// 	sessionStorage.setItem('type', "admin");
		// 	history.push('/admin/destinations');
		// }
		// else {
		// 	sessionStorage.setItem('type', "client");
		// 	history.push('/');

		// }
	};

	const [snake, setSnake] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const handleSnake = async () => {
		setSnake(true);
	};
	const handleCloseSnake = () => setSnake(false);

	useEffect(() => {
		// axios
		// 	.get(
		// 		'https://us-central1-verison-ddd3d.cloudfunctions.net/helloWorld'
		// 	)
		// 	.then((e) => {
		// 		console.log(e);
		// 	});
	}, []);

	return (
		<ThemeProvider theme={theme}>
			<div
				style={{
					paddingTop: '80px',

					height: '100vh',
					backgroundColor: grey[900],
				}}>
				<div
					style={{
						backgroundImage:
							'url(/images/wanderlust' +
							Math.floor(Math.random() * 4) +
							'.jpg)',
						backgroundRepeat: 'no-repeat',
						backgroundColor: grey[900],
						backgroundSize: 'cover',
						backgroundPosition: 'center',
						display: 'flex',
						flexDirection: 'column',
						alignItems: 'center',
						height: '105vh',
					}}>
					<Card sx={{ mt: 5, mb: 10, bgcolor: 'white' }}>
						<CardContent sx={{ p: 5 }}>
							<Typography component="h1" variant="h4">
								Se connecter
							</Typography>
							<Collapse in={snake}>
								<Alert
									onClose={handleCloseSnake}
									severity="error"
									sx={{ width: '100%' }}>
									Invalide email ou mot de passe !
								</Alert>
							</Collapse>
							<Box
								component="form"
								noValidate
								onSubmit={handleSubmit}
								sx={{ mt: 1 }}>
								<TextField
									margin="normal"
									required
									fullWidth
									id="email"
									label="Email Address"
									name="email"
									autoComplete="email"
									autoFocus
								/>
								<TextField
									margin="normal"
									required
									fullWidth
									name="password"
									label="Mot de passe"
									type="password"
									id="password"
									autoComplete="current-password"
								/>
								{/* <FormControlLabel
								control={<Checkbox value="remember" color="primary" />}
								label="Remember me"
							/> */}
								<Button
									type="submit"
									fullWidth
									variant="contained"
									sx={{
										mt: 3,
										mb: 2,
										bgcolor: '#ff4838',
										'&:hover': {
											backgroundColor: '#ff4838',
											opacity: 0.4,
										},
									}}>
									Se connecter
								</Button>
								<Grid container>
									<Grid item xs={12} md={6}>
										Mot de passe oublié ?
										<Link href="/contact" variant="body2">
											{' '}
											Contactez Verison
										</Link>
									</Grid>
									<Grid item xs={12} md={6}>
										Vous n'avez pas de compte ?
										<Link
											href="/inscription"
											variant="body2">
											{" S'inscrire"}
										</Link>
									</Grid>
								</Grid>
								<Copyright sx={{ mt: 5 }} />
							</Box>
						</CardContent>
					</Card>
				</div>
			</div>
		</ThemeProvider>
	);
}
