import { Stack, Typography } from "@mui/material";

export const IconLabelItem = (iconValue, label, value) => {
    return (

        <Stack
            direction="row"
            alignItems="center"
            justifyContent="start"
            spacing={1}
            sx={{ width: "100%" }}
        >
            <Typography gutterBottom>
                <strong>{iconValue}</strong>
            </Typography>
            <Typography gutterBottom>
                <strong>{label} :</strong> {value}
            </Typography>
        </Stack>

    )
};