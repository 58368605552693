/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

function ContactWrapperArea() {
	return (
		<>
			<div className="contact-wrapper pt-110">
				<div className="container">
					<div className="row align-items-center justify-content-lg-center gy-5">
						<div className="col-lg-6">
							<div className="contatc-intro-figure">
								<img
									src={
										process.env.PUBLIC_URL +
										'/images/banner/contact-bg.png'
									}
									alt="ContactBannerIMG"
									className="img-fluid"
								/>
							</div>
						</div>
						<div className="col-lg-5">
							<div className="contact-info">
								<h3>Coordonnées.</h3>
								<ul>
									<li>
										<h6>Contact</h6>
										<a href="mailto:info@example.com">
											info@example.com
										</a>
										<a href="mailto:support@example.com">
											info@support.com
										</a>
										<a
											rel="noopener noreferrer"
											target="_blank"
											href="tel:+213798889717">
											+213798889717
										</a>
										<a
											rel="noopener noreferrer"
											target="_blank"
											href="tel:+213796276168">
											+213796276168
										</a>
									</li>
									<li>
										<h6>Localisation.</h6>
										<a
											rel="noopener noreferrer"
											target="_blank"
											href="https://goo.gl/maps/nkaCPJPjATZrijv86">
											Rue de SÉTIF, Mohammadia, Algeria
										</a>
									</li>
									<li>
										<h6>Réseaux sociaux.</h6>
										<a
											rel="noopener noreferrer"
											target="_blank"
											href="https://www.facebook.com/verisontour">
											<a
												rel="noopener noreferrer"
												href="https://www.facebook.com/verisontour"
												target="_blank">
												<i className="bx bxl-facebook" />
											</a>
											Facebook:
											https://www.facebook.com/verisontour
										</a>
										<a
											rel="noopener noreferrer"
											target="_blank"
											href="https://www.instagram.com/verisontour">
											<a
												rel="noopener noreferrer"
												href="https://www.instagram.com/verisontour"
												target="_blank">
												<i className="bx bxl-instagram" />
											</a>
											Instagram:
											https://www.instagram.com/verisontour
										</a>
										<a
											rel="noopener noreferrer"
											target="_blank"
											href="https://call.whatsapp.com/voice/xwMfSV1xBXO27puuJUAlJg">
											<a
												rel="noopener noreferrer"
												href="https://call.whatsapp.com/voice/xwMfSV1xBXO27puuJUAlJg"
												target="_blank">
												<i className="bx bxl-whatsapp" />
											</a>
											WhatsApp
										</a>
									</li>
								</ul>
							</div>
						</div>
					</div>
				</div>
				<div className="container-fluid p-0">
					<div className="contact-map mt-120">
						<div className="mapouter">
							<div className="gmap_canvas">
								<iframe
									src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3197.738975222374!2d3.1413189!3d36.7288297!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x128e5345d80166d9%3A0x61ab8d7fccabbc30!2sVerison%20-%20agence%20de%20voyage%20et%20tourisme!5e0!3m2!1sfr!2sfr!4v1679148592179!5m2!1sfr!2sfr"
									width="600"
									height="450"
									style={{ border: 0 }}
									allowfullscreen=""
									loading="lazy"
									referrerpolicy="no-referrer-when-downgrade"></iframe>
								<Link to={'https://123movies-to.org'} />
								<br />
							</div>
						</div>
					</div>
				</div>
				<div className="container pt-110">
					<form
						onSubmit={(e) => e.preventDefault()}
						id="contact_form">
						<div className="contact-form-wrap">
							<h4>Get a free key stroke quote now</h4>
							<p>
								Your email address will not be published.
								Required fields are marked *
							</p>
							<div className="row">
								<div className="col-lg-6">
									<div className="custom-input-group">
										<label htmlFor="name">Name</label>
										<input
											required
											type="text"
											placeholder="Your name"
											id="name"
										/>
									</div>
								</div>
								<div className="col-lg-6">
									<div className="custom-input-group">
										<label htmlFor="email">Email</label>
										<input
											required
											type="text"
											placeholder="Your Email"
											id="email"
										/>
									</div>
								</div>
							</div>
							<div className="custom-input-group">
								<textarea
									cols={20}
									rows={7}
									required
									placeholder="Your message"
									defaultValue={''}
								/>
							</div>
							<div className="custom-input-group">
								<div className="submite-btn">
									<button type="submit">Send Message</button>
								</div>
							</div>
						</div>
					</form>
				</div>
			</div>
		</>
	);
}

export default ContactWrapperArea;
