import { AccessTime, AddTask, AirplanemodeActive, ArrowBack, ArrowForward, Check, Close, Delete, Description, Edit, Explore, FlightLand, FlightTakeoff, Group, Hotel, InsertDriveFile, LocationOn, MonetizationOn, Padding, Title, UploadFile } from "@mui/icons-material";
import { default as Add, default as AddIcon } from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import UploadIcon from '@mui/icons-material/Upload';
import { LoadingButton } from "@mui/lab";
import { Alert, Box, Button, Card, CardActionArea, CardContent, Collapse, Container, Dialog, DialogActions, DialogContent, DialogTitle, Divider, Grid, IconButton, Input, MenuItem, Snackbar, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { Stack } from "@mui/system";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { format } from "date-fns";
import { fr } from "date-fns/locale";
import { addDoc, collection, deleteDoc, doc, getDocs, query, setDoc, where } from "firebase/firestore";
import { getDownloadURL, ref, uploadBytes, uploadBytesResumable } from "firebase/storage";
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from "react";
import { Link, useHistory, useLocation } from "react-router-dom";
import * as Yup from 'yup';
import { cleanData, db, storage } from "../../../../Firebase";
import AdminPackageCard from "./AdminPackageCard";


function AdminPackage() {
    let color = '#ff4838'

    const location = useLocation();
    let type = location.state?.type

    const [listProgramme, setListProgramme] = useState([])

    const [loading, setloading] = useState(false);

    const [imgUrl, setImgUrl] = useState(null)
    const [docRef, setDocRef] = useState(null)
    const [imageUrls, setImageUrls] = useState([])
    const [imagePrincipale, setimagePrincipale] = useState("")

    const steps = [
        'Information générale',
        'Dates/places',
        'Les Prix',
        'Albume images',
        'Création',
    ];


    const [progress, setProgress] = React.useState(0);
    const [progressAlbum, setProgressAlbum] = React.useState(100);
    const [activeStep, setActiveStep] = React.useState(0);


    const addProgramme = async (values) => {
        setloading(true)
        let promises = []
        let snapshotRef = null
        let pdfUrl = ""
        let imgUrl = ""
        let gallaryUrls = []
        let imageRef = ""
        let waiting = ""

        try {
            for (let file in values.gallary) {
                imageRef = ref(
                    storage,
                    'images/destination/' + docRef + '/' + file
                );
                promises.push(
                    uploadBytesResumable(imageRef, values.gallary[file].url).then(
                        (uploadResult) => {
                            return getDownloadURL(uploadResult.ref);
                        }
                    )
                );
            }


        }
        catch (e) {
            console.log(e);
        }

        gallaryUrls = await Promise.all(promises);
        promises = []
        try {
            imageRef = ref(storage, "images/destination/" + docRef + "/" + "principale")

            promises.push(
                uploadBytes(imageRef, values.image).then((snapshot) => {
                    snapshotRef = snapshot.ref
                })
            )
            waiting = await Promise.all(promises);
            promises.push(
                getDownloadURL(snapshotRef).then((downloadURL) => {
                    imgUrl = downloadURL
                })
            )

        }
        catch (e) {
            console.log(e);
        }

        waiting = await Promise.all(promises);
        promises = []

        try {
            if (values.pdf != null) {
                imageRef = ref(storage, "images/destination/" + docRef + "/" + "pdf")

                promises.push(
                    uploadBytes(imageRef, values.pdf).then((snapshot) => {
                        snapshotRef = snapshot.ref
                    })
                )
                waiting = await Promise.all(promises);
                promises.push(
                    getDownloadURL(snapshotRef).then((downloadURL) => {
                        pdfUrl = downloadURL
                    })
                )
            }
        }
        catch (e) {
            console.log(e);
        }




        // upload images

        waiting = await Promise.all(promises);
        const destination_detail = {
            duration: values.duration,
            tour: values.tour,
            title: values.title,
            destination: values.destination,
            hotel: values.hotel,
            departs: values.departs,
            type: type ? type : "",
            include: values.include,
            files: values.files,
            description: values.description,
            prixGenerale: values.prixGenerale,
            priceAdulte: values.priceAdulte,
            enfants: values.enfants,
            prixTransport: values.prixTransport,
            gallary: gallaryUrls,
            image: imgUrl,
            pdf: pdfUrl,
        }



        try {


            await setDoc(doc(db, "destination_detail", docRef), destination_detail).then(() => {
                getData()
                handleClose()
                setIsError(false)
                handleSnake()
                setloading(false)

            });


        } catch (values) {
            setloading(false)
            setIsError(true)
            console.error("Error adding document: ", values);
        }

        setloading(false)

    }

    const getData = async () => {
        setListProgramme([])

        const detailRef = collection(db, "destination_detail");
        const q = await query(detailRef, where("type", "==", type))
        const querySnapshot = await getDocs(q);
        let tmp = []

        await querySnapshot.forEach(async (doc) => {
            tmp.push({ ...doc.data(), id: doc.id })
            // doc.data() is never undefined for query doc snapshots
        });

        setListProgramme(tmp)
    }

    const [listTour, setListTour] = useState([])

    const getTour = async () => {
        setListTour([])
        await getDocs(collection(db, "tour"))
            .then((querySnapshot) => {
                const newData = cleanData(querySnapshot)
                setListTour(newData)
            }).catch((err) => {
                console.log(err);
            })

    }

    const addTour = (value) => {
        addDoc(collection(db, "tour"), {
            label: value
        })
        getTour()
        settourCollapse(false)
    }
    const deleteTour = async (e) => {
        await deleteDoc(doc(db, "tour", e.id)).then((e) => {
            getTour()
        })
    };

    const [tourCollapse, settourCollapse] = React.useState(false);


    useEffect(() => {
        console.log(location.state);
        if (location.state != undefined) {
            type = location.state.type
        }
        else {
            console.log("go back");
            history.goBack()
        }

        getData()
        getTour()
    }, [])


    const [open, setOpen] = React.useState(false);
    const handleOpen = async () => {
        const reference = await doc(collection(db, "destination"))
        setDocRef(reference.id);
        setActiveStep(0)
        setOpen(true);
    };
    const handleClose = () => setOpen(false);

    let history = useHistory()

    const [ind, setInd] = React.useState(0);



    const [snake, setSnake] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const handleSnake = async () => {
        setSnake(true);
    };
    const handleCloseSnake = () => setSnake(false);

    const [inclueHover, setInclueHover] = React.useState([false]);
    const [filesHover, setFilesHover] = React.useState([false]);



    return (
        <>
            <Snackbar open={snake} autoHideDuration={5000} onClose={handleCloseSnake}>
                {
                    isError ?

                        <Alert onClose={handleCloseSnake} severity="error" sx={{ width: '100%' }}>
                            Erreur !
                        </Alert> :
                        <Alert onClose={handleCloseSnake} severity="success" sx={{ width: '100%' }}>
                            Destination ajouté !
                        </Alert>
                }
            </Snackbar>
            <Dialog
                fullWidth
                scroll="body"
                maxWidth="lg"
                open={open}
            >
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    <Container>
                        <Typography variant="h4">
                            <strong>
                                Ajouter un programme
                            </strong>
                        </Typography>
                    </Container>
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                            color: (theme) => theme.palette.grey[500],
                        }}
                    >
                        <Close />
                    </IconButton>

                </DialogTitle>
                <Container>

                    <CardContent>
                        {/* {
                               duration: "null",
                                tour: "",
                                places: 0,
                                priceAdulte: 0,
                                priceEnf0: 0,
                                priceEnf2: 0,
                                priceEnf6: 0,
                                title: "null",
                                prixTransport: 0,
                                type: "omra",
                                departs: [],
                                depature: null,
                                return: null,
                                description: "null",
                                hotel: "",
                                destination: "null",
                                gallary: [],
                                include: [],
                                files: [],
                                image: null,
                                pdf: null,
                                temp: "",
                                tempFiles: "",
                                tempTour: "",

                            } */}
                        <Formik
                            initialValues={{
                                duration: "",
                                tour: "",
                                title: "",
                                type: type,
                                departs: [],
                                depature: null,
                                return: null,
                                places: 0,
                                hotel: "",
                                destination: "",
                                include: [],
                                files: [],
                                description: "",
                                temp: "",
                                tempFiles: "",
                                tempTour: "",
                                //les prix
                                prixGenerale: 0,
                                priceAdulte: [0, 0, 0, 0],
                                enfants: [],
                                age: "",
                                prix: [0, 0, 0, 0],
                                prixTransport: 0,
                                ///   les images
                                gallary: [],
                                image: null,
                                pdf: null,

                            }}
                            validationSchema={
                                Yup.object().shape({
                                    duration: Yup.string().max(255).required('champ obligatoire'),
                                    tour: Yup.string().max(255).required('champ obligatoire'),
                                    places: Yup.number().integer().required('champ obligatoire'),
                                    title: Yup.string().max(255).required('champ obligatoire'),
                                    departs: Yup.array().min(1, "Ajouter au mois une date de departs"),
                                    description: Yup.string().max(5000).required('champ obligatoire'),
                                    destination: Yup.string().max(255).required('champ obligatoire'),
                                    hotel: Yup.string().max(255).required('champ obligatoire'),
                                    prixGenerale: Yup.number().integer().required('champ obligatoire'),
                                    prixTransport: Yup.number().integer().required('champ obligatoire'),
                                    // files: Yup.mixed()
                                    //     .test('files', "Ajouter au mois un nom de fichier", (value) => {
                                    //         return (
                                    //             (value && value.length != 0)
                                    //         )
                                    //     }),
                                    // include: Yup.string().max(255).required('champ obligatoire'),
                                }

                                )
                            }
                            onSubmit={(values) => {
                                addProgramme(values)
                            }}
                        >
                            {({

                                setFieldValue,
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting, isValid
                            }) => (
                                <Form>

                                    <DialogContent>
                                        <Box sx={{ width: '100%' }}>
                                            <Stepper activeStep={activeStep}>
                                                {steps.map((label, index) => {
                                                    const labelProps = {};
                                                    if (index == 0 && isValid == false) {
                                                        labelProps.optional = (
                                                            <Typography variant="caption" color="error">
                                                                Rempliser le formulaire
                                                            </Typography>
                                                        );

                                                        labelProps.error = true;
                                                    }

                                                    return (
                                                        <Step key={label}>
                                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                                        </Step>
                                                    );
                                                })}
                                            </Stepper>
                                        </Box>
                                        <Divider style={{ marginTop: 20 }}></Divider>

                                        {
                                            activeStep == 0 &&
                                            <div style={{ marginTop: 2 }}>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <TextField
                                                            error={Boolean(touched.title && errors.title)}
                                                            fullWidth
                                                            helperText={touched.title && errors.title}
                                                            label="Titre"
                                                            margin="normal"
                                                            name="title"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.title}
                                                            variant="outlined"
                                                        />

                                                    </div>
                                                    <div className="col-sm-6">
                                                        <TextField
                                                            error={Boolean(touched.duration && errors.duration)}
                                                            fullWidth
                                                            helperText={touched.duration && errors.duration}
                                                            label="Durée"
                                                            margin="normal"
                                                            name="duration"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.duration}
                                                            variant="outlined"
                                                        />


                                                    </div>

                                                </div>


                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <TextField
                                                            error={Boolean(touched.destination && errors.destination)}
                                                            fullWidth
                                                            helperText={touched.destination && errors.destination}
                                                            label="Destination"
                                                            margin="normal"
                                                            name="destination"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.destination}
                                                            variant="outlined"
                                                        />


                                                    </div>
                                                    <div className="col-sm-6">
                                                        <TextField
                                                            error={Boolean(touched.hotel && errors.hotel)}
                                                            fullWidth
                                                            helperText={touched.hotel && errors.hotel}
                                                            label="Hotel"
                                                            margin="normal"
                                                            name="hotel"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.hotel}
                                                            variant="outlined"
                                                        />


                                                    </div>
                                                </div>




                                                <div className="row mt-3">
                                                    <div className="col-sm-6">

                                                        <Stack direction={"row"}>
                                                            <TextField
                                                                error={Boolean(touched.tour && errors.tour)}

                                                                helperText={touched.tour && errors.tour}

                                                                select
                                                                fullWidth
                                                                label="Tour"
                                                                name="tour"
                                                                onChange={(event, value) => {
                                                                    setFieldValue("tour", event.target.value)
                                                                }}
                                                                value={values.tour}
                                                                variant="outlined"
                                                            >
                                                                {listTour.map((l, ind) =>
                                                                    <MenuItem key={ind} value={l.label}>{l.label}</MenuItem>
                                                                )
                                                                }


                                                            </TextField>
                                                            <IconButton aria-label="add" onClick={(event, value) => {
                                                                settourCollapse(!tourCollapse)
                                                            }}>
                                                                <Add />
                                                            </IconButton>

                                                        </Stack>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <Collapse in={tourCollapse}>
                                                        <TextField
                                                            error={Boolean(touched.tempTour && errors.tempTour)}

                                                            helperText={touched.tempTour && errors.tempTour}
                                                            label="Ajouter Tour"
                                                            margin="normal"
                                                            name="tempTour"
                                                            onBlur={handleBlur}
                                                            onKeyDown={(event) => {
                                                                if (event.key === 'Enter') {
                                                                    addTour(values.tempTour)
                                                                    setFieldValue("tempTour", "")
                                                                }
                                                            }}
                                                            onChange={(event) => {

                                                                setFieldValue("tempTour", event.target.value)

                                                            }}
                                                            value={values.tempTour}
                                                            variant="outlined"
                                                        />
                                                        <Typography>Liste tours :</Typography>
                                                        <ul>
                                                            {
                                                                listTour.map((itm, index) =>
                                                                    <li className="mt-2 mb-2">
                                                                        <Card


                                                                            sx={{
                                                                                '&:hover': {
                                                                                    backgroundColor: 'red',
                                                                                    opacity: 0.4,
                                                                                },
                                                                            }}>
                                                                            <CardActionArea onClick={() => {
                                                                                deleteTour(itm)

                                                                            }}>
                                                                                <CardContent>
                                                                                    <Stack
                                                                                        direction="row"
                                                                                        alignItems="center"
                                                                                        justifyContent="space-between"
                                                                                    >
                                                                                        <div>
                                                                                            <i className="bi bi-check2" />

                                                                                            {"  " + itm.label}

                                                                                        </div>


                                                                                        <Delete></Delete>

                                                                                    </Stack>




                                                                                </CardContent>
                                                                            </CardActionArea>
                                                                        </Card>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>
                                                        <Divider sx={{ borderBottomWidth: 5 }}></Divider>

                                                    </Collapse>
                                                </div>

                                                <div className="row ">
                                                    <div className="col-sm-6">
                                                        <Stack direction={"row"}>

                                                            <TextField
                                                                error={Boolean(touched.temp && errors.temp)}
                                                                fullWidth
                                                                helperText={touched.temp && errors.temp}
                                                                label="Inclue"
                                                                margin="normal"
                                                                name="temp"
                                                                onBlur={handleBlur}

                                                                onChange={(event) => {

                                                                    setFieldValue("temp", event.target.value)

                                                                }}
                                                                value={values.temp}
                                                                variant="outlined"
                                                            />
                                                            <IconButton aria-label="add" onClick={(event, value) => {
                                                                let tmp = [...values.include]
                                                                if (values.temp != "") {
                                                                    tmp.push(values.temp)
                                                                    setFieldValue("include", tmp)
                                                                    setFieldValue("temp", "")
                                                                    let x = [...inclueHover]
                                                                    x.push(false)
                                                                    setInclueHover(x)
                                                                }
                                                            }}>
                                                                <Add />
                                                            </IconButton>

                                                        </Stack>
                                                    </div>

                                                </div>
                                                {
                                                    values.include.length > 0 &&
                                                    <div className="row" >
                                                        <Container >

                                                            <Typography variant="body1">
                                                                <strong>
                                                                    Inclue :
                                                                </strong>
                                                            </Typography>
                                                            <ul>
                                                                {
                                                                    values.include.map((inc, index) =>
                                                                        <li className="mt-2 mb-2">
                                                                            <Card
                                                                                onMouseEnter={() => {
                                                                                    let x = inclueHover.map(i => false)
                                                                                    x[index] = true
                                                                                    setInclueHover(x)
                                                                                }}
                                                                                onMouseLeave={() => {
                                                                                    let x = inclueHover.map(i => false)
                                                                                    setInclueHover(x)
                                                                                }}

                                                                                sx={{
                                                                                    '&:hover': {
                                                                                        backgroundColor: 'red',
                                                                                        opacity: 0.4,
                                                                                    },
                                                                                }}>
                                                                                <CardActionArea onClick={() => {
                                                                                    let tmp = [...values.include]
                                                                                    tmp = tmp.filter(item => item != inc)
                                                                                    setFieldValue("include", tmp)

                                                                                }}>
                                                                                    <CardContent>
                                                                                        <Stack
                                                                                            direction="row"
                                                                                            alignItems="center"
                                                                                            justifyContent="space-between"
                                                                                        >
                                                                                            <div>
                                                                                                <i className="bi bi-check2" />

                                                                                                {"  " + inc}

                                                                                            </div>

                                                                                            {
                                                                                                inclueHover[index] ?
                                                                                                    <DeleteIcon></DeleteIcon> : <></>
                                                                                            }
                                                                                        </Stack>




                                                                                    </CardContent>
                                                                                </CardActionArea>
                                                                            </Card>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>

                                                        </Container>
                                                        <Divider></Divider>
                                                    </div>
                                                }

                                                <div class="row">
                                                    <div className="col-sm-6">

                                                        <Stack direction={"row"}>
                                                            <TextField
                                                                error={values.files.length == 0}
                                                                helperText={values.files.length == 0 && touched.files && errors.files}
                                                                fullWidth
                                                                label="Fichies"
                                                                margin="normal"
                                                                name="tempFiles"
                                                                onBlur={handleBlur}

                                                                onChange={(event) => {
                                                                    setFieldValue("tempFiles", event.target.value)
                                                                }}
                                                                value={values.tempFiles}
                                                                variant="outlined"
                                                            />
                                                            <IconButton aria-label="add" onClick={(event, value) => {
                                                                let tmp = [...values.files]
                                                                if (values.tempFiles != "") {
                                                                    tmp.push(values.tempFiles)
                                                                    setFieldValue("files", tmp)
                                                                    setFieldValue("tempFiles", "")
                                                                    let x = [...inclueHover]
                                                                    x.push(false)
                                                                    setFilesHover(x)
                                                                }
                                                            }}>
                                                                <Add />
                                                            </IconButton>

                                                        </Stack>
                                                    </div>

                                                    {
                                                        values.files.length > 0 &&
                                                        <div className="row">
                                                            <Container >

                                                                <Typography variant="body1">
                                                                    <strong>
                                                                        Fichiers :
                                                                    </strong>
                                                                </Typography>


                                                                <ul>
                                                                    {
                                                                        values.files.map((inc, index) =>
                                                                            <li className="mt-2 mb-2">
                                                                                <Card
                                                                                    onMouseEnter={() => {
                                                                                        let x = filesHover.map(i => false)
                                                                                        x[index] = true
                                                                                        setFilesHover(x)
                                                                                    }}
                                                                                    onMouseLeave={() => {
                                                                                        let x = filesHover.map(i => false)
                                                                                        setFilesHover(x)
                                                                                    }}

                                                                                    sx={{
                                                                                        '&:hover': {
                                                                                            backgroundColor: 'red',
                                                                                            opacity: 0.4,
                                                                                        },
                                                                                    }}>
                                                                                    <CardActionArea onClick={() => {
                                                                                        let tmp = [...values.files]
                                                                                        tmp = tmp.filter(item => item != inc)
                                                                                        setFieldValue("files", tmp)

                                                                                    }}>
                                                                                        <CardContent>
                                                                                            <Stack
                                                                                                direction="row"
                                                                                                alignItems="center"
                                                                                                justifyContent="space-between"
                                                                                            >
                                                                                                <div>
                                                                                                    <i className="bi bi-check2" />

                                                                                                    {"  " + inc}

                                                                                                </div>

                                                                                                {
                                                                                                    filesHover[index] ?
                                                                                                        <Delete></Delete> : <></>
                                                                                                }
                                                                                            </Stack>
                                                                                        </CardContent>
                                                                                    </CardActionArea>
                                                                                </Card>
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>

                                                            </Container>
                                                            <Divider></Divider>

                                                        </div>
                                                    }


                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <TextField
                                                            error={Boolean(touched.description && errors.description)}
                                                            fullWidth
                                                            helperText={touched.description && errors.description}
                                                            label="Description"
                                                            margin="normal"
                                                            name="description"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.description}
                                                            variant="outlined"
                                                            multiline
                                                            rows={5}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            activeStep == 1 &&
                                            <Box mt={2}>
                                                <Typography variant="h5">
                                                    Dates/places
                                                </Typography>
                                                <div className="row  mt-1">
                                                    <Grid container spacing={2}>
                                                        <Grid item sx={12} md={6}>
                                                            <LocalizationProvider
                                                                adapterLocale={fr}
                                                                dateAdapter={AdapterDateFns}>
                                                                <DesktopDatePicker
                                                                    label="Départ"
                                                                    margin="normal"
                                                                    name="depature"
                                                                    ampm={false}
                                                                    inputFormat="dd/MM/yyyy"
                                                                    minDate={new Date()}
                                                                    onBlur={handleBlur}
                                                                    onChange={(event, value) => {
                                                                        setFieldValue("depature", event)
                                                                    }}
                                                                    value={values.depature}
                                                                    variant="outlined"
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            error={values.departs.length == 0}
                                                                            helperText={values.departs.length == 0 && errors.departs}
                                                                            fullWidth
                                                                            {...params}
                                                                        />}
                                                                />
                                                            </LocalizationProvider>
                                                        </Grid>
                                                        <Grid item sx={12} md={6}>

                                                            <LocalizationProvider dateAdapter={AdapterDateFns}>
                                                                <DesktopDatePicker
                                                                    disabled={values.depature == null}

                                                                    label="Retour"
                                                                    margin="normal"
                                                                    name="return"
                                                                    ampm={false}
                                                                    minDate={values.depature}
                                                                    inputFormat="dd/MM/yyyy"
                                                                    onBlur={handleBlur}
                                                                    onChange={(event, value) => {
                                                                        setFieldValue("return", event)
                                                                    }}
                                                                    value={values.return}
                                                                    variant="outlined"
                                                                    renderInput={(params) =>
                                                                        <TextField
                                                                            error={Boolean(touched.return && errors.return)}

                                                                            helperText={touched.return && errors.return}
                                                                            fullWidth
                                                                            {...params}
                                                                        />}
                                                                />
                                                            </LocalizationProvider>



                                                        </Grid>
                                                        <Grid item>
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="center"
                                                            >
                                                                <TextField
                                                                    error={Boolean(touched.places && errors.places)}
                                                                    fullWidth
                                                                    helperText={touched.places && errors.places}
                                                                    type="number"
                                                                    label="Places"
                                                                    margin="normal"
                                                                    name="places"
                                                                    onBlur={handleBlur}
                                                                    onChange={handleChange}
                                                                    value={values.places}
                                                                    variant="outlined"

                                                                    inputProps={{ min: 0 }}
                                                                />

                                                                <IconButton aria-label="add" onClick={(event, value) => {
                                                                    let tmp = [...values.departs]
                                                                    if (values.return != null) {
                                                                        let item = {
                                                                            depature: format(values.depature, "dd-MM-yyyy"),
                                                                            return: format(values.return, "dd-MM-yyyy"),
                                                                            places: values.places,
                                                                            reservations: 0
                                                                        }
                                                                        tmp.push(item)
                                                                        setFieldValue("departs", tmp)
                                                                        setFieldValue("depature", null)
                                                                        setFieldValue("return", null)
                                                                        setFieldValue("places", 0)
                                                                    }
                                                                }}>

                                                                    <Add />
                                                                </IconButton>
                                                            </Stack>
                                                        </Grid>

                                                    </Grid>
                                                </div>
                                                {
                                                    values.departs.length > 0 &&
                                                    <div className="row mt-2">
                                                        <Container >

                                                            <Typography variant="body1">
                                                                <strong>
                                                                    dates de departs :
                                                                </strong>
                                                            </Typography>

                                                            <ul>
                                                                {
                                                                    values.departs.map((inc, index) =>
                                                                        <li className="mt-2 mb-2">
                                                                            <Card

                                                                                sx={{
                                                                                    '&:hover': {
                                                                                        backgroundColor: 'red',
                                                                                        opacity: 0.4,
                                                                                    },
                                                                                }}>
                                                                                <CardActionArea onClick={() => {


                                                                                }}>
                                                                                    <CardContent>
                                                                                        <Stack
                                                                                            direction="row"
                                                                                            alignItems="center"
                                                                                            justifyContent="space-between"
                                                                                        >
                                                                                            <div>
                                                                                                <i className="bi bi-check2" />

                                                                                                {"  " + inc.depature + " / " + inc.return + " ---- Places : " + inc.places}

                                                                                            </div>

                                                                                            <IconButton
                                                                                                onClick={() => {
                                                                                                    let tmp = [...values.departs]
                                                                                                    tmp = tmp.filter(item => item != inc)
                                                                                                    setFieldValue("departs", tmp)
                                                                                                }}
                                                                                            >
                                                                                                <Delete></Delete>
                                                                                            </IconButton>

                                                                                        </Stack>
                                                                                    </CardContent>
                                                                                </CardActionArea>
                                                                            </Card>
                                                                        </li>
                                                                    )
                                                                }
                                                            </ul>

                                                        </Container>


                                                        <Divider></Divider>
                                                    </div>
                                                }
                                            </Box>
                                        }
                                        {
                                            activeStep == 2 &&
                                            <div style={{ marginTop: 2 }}>
                                                <Typography variant="h5">
                                                    Prix
                                                </Typography>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <TextField
                                                            error={Boolean(touched.prixGenerale && errors.prixGenerale)}
                                                            fullWidth
                                                            helperText={touched.prixGenerale && errors.prixGenerale}
                                                            label="Prix (DA)"
                                                            margin="normal"
                                                            name="prixGenerale"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.prixGenerale}
                                                            variant="outlined"
                                                            inputProps={{ min: 0 }}
                                                            type="number"
                                                        />
                                                    </div>
                                                </div>
                                                <Typography variant="h5">
                                                    Prix des chambre pour adultes
                                                </Typography>

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <TextField
                                                            error={Boolean(touched.priceAdulte && errors.priceAdulte)}
                                                            fullWidth
                                                            helperText={touched.priceAdulte && errors.priceAdulte}
                                                            label="Chamber single (DA)"
                                                            margin="normal"
                                                            name="priceAdulte"
                                                            inputProps={{ min: 0 }}
                                                            type="number"
                                                            onBlur={handleBlur}
                                                            onChange={(event) => {
                                                                let tmp = values.priceAdulte
                                                                tmp[0] = event.target.value
                                                                setFieldValue("priceAdulte", tmp)
                                                            }}
                                                            value={values.priceAdulte[0]}
                                                            variant="outlined"
                                                        />


                                                    </div>
                                                    <div className="col-sm-6">
                                                        <TextField
                                                            error={Boolean(touched.priceAdulte && errors.priceAdulte)}
                                                            fullWidth
                                                            helperText={touched.priceAdulte && errors.priceAdulte}
                                                            label="Chamber double (DA)"
                                                            margin="normal"
                                                            name="priceAdulte"
                                                            onBlur={handleBlur}
                                                            inputProps={{ min: 0 }}
                                                            type="number"
                                                            onChange={(event) => {
                                                                let tmp = values.priceAdulte
                                                                tmp[1] = event.target.value
                                                                setFieldValue("priceAdulte", tmp)
                                                            }}
                                                            value={values.priceAdulte[1]}
                                                            variant="outlined"
                                                        />


                                                    </div>
                                                    <div className="col-sm-6">
                                                        <TextField
                                                            error={Boolean(touched.priceAdulte && errors.priceAdulte)}
                                                            fullWidth
                                                            helperText={touched.priceAdulte && errors.priceAdulte}
                                                            label="Chamber triple (DA)"
                                                            margin="normal"
                                                            inputProps={{ min: 0 }}
                                                            type="number"
                                                            name="priceAdulte"
                                                            onBlur={handleBlur}
                                                            onChange={(event) => {
                                                                let tmp = values.priceAdulte
                                                                tmp[2] = event.target.value
                                                                setFieldValue("priceAdulte", tmp)
                                                            }}
                                                            value={values.priceAdulte[2]}
                                                            variant="outlined"
                                                        />


                                                    </div>
                                                    <div className="col-sm-6">
                                                        <TextField
                                                            error={Boolean(touched.priceAdulte && errors.priceAdulte)}
                                                            fullWidth
                                                            helperText={touched.priceAdulte && errors.priceAdulte}
                                                            label="Chamber quadruple (DA)"
                                                            inputProps={{ min: 0 }}
                                                            margin="normal"
                                                            type="number"
                                                            name="priceAdulte"
                                                            onBlur={handleBlur}
                                                            onChange={(event) => {
                                                                let tmp = values.priceAdulte
                                                                tmp[3] = event.target.value
                                                                setFieldValue("priceAdulte", tmp)
                                                            }}
                                                            value={values.priceAdulte[3]}
                                                            variant="outlined"
                                                        />


                                                    </div>


                                                </div>

                                                <Typography variant="h5">
                                                    Prix des chambre pour enfants
                                                </Typography>
                                                <div className="row">
                                                    <Stack
                                                        direction="row"
                                                    >
                                                        <div className="col-sm-6">
                                                            <TextField
                                                                error={Boolean(touched.age && errors.age)}
                                                                fullWidth
                                                                helperText={touched.age && errors.age}
                                                                label="Age"
                                                                margin="normal"
                                                                name="age"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.age}
                                                                variant="outlined"
                                                            />
                                                        </div>

                                                        <IconButton
                                                            disabled={values.age == ""}
                                                            aria-label="add"
                                                            onClick={(event, value) => {
                                                                let tmp = [...values.enfants]
                                                                if (values.prix != null) {
                                                                    let item = {
                                                                        age: values.age,
                                                                        prix: values.prix
                                                                    }
                                                                    tmp.push(item)
                                                                    setFieldValue("enfants", tmp)
                                                                    setFieldValue("prix", [0, 0, 0, 0])
                                                                    setFieldValue("age", "")
                                                                }
                                                            }}>
                                                            <Add />
                                                        </IconButton>
                                                    </Stack>
                                                    <div className="row">
                                                        <div className="col-sm-6">
                                                            <TextField
                                                                error={Boolean(touched.prix && errors.prix)}
                                                                fullWidth
                                                                helperText={touched.prix && errors.prix}
                                                                label="Chamber single (DA)"
                                                                inputProps={{ min: 0 }}
                                                                type="number"
                                                                margin="normal"
                                                                name="prix"
                                                                onBlur={handleBlur}
                                                                onChange={(event) => {
                                                                    let tmp = values.prix
                                                                    tmp[0] = event.target.value
                                                                    setFieldValue("prix", tmp)
                                                                }}
                                                                value={values.prix[0]}
                                                                variant="outlined"
                                                            />


                                                        </div>
                                                        <div className="col-sm-6">
                                                            <TextField
                                                                error={Boolean(touched.prix && errors.prix)}
                                                                fullWidth
                                                                helperText={touched.prix && errors.prix}
                                                                label="Chamber double (DA)"
                                                                inputProps={{ min: 0 }}
                                                                type="number"
                                                                margin="normal"
                                                                name="prix"
                                                                onBlur={handleBlur}
                                                                onChange={(event) => {
                                                                    let tmp = values.prix
                                                                    tmp[1] = event.target.value
                                                                    setFieldValue("prix", tmp)
                                                                }}
                                                                value={values.prix[1]}
                                                                variant="outlined"
                                                            />


                                                        </div>
                                                        <div className="col-sm-6">
                                                            <TextField
                                                                error={Boolean(touched.prix && errors.prix)}
                                                                fullWidth
                                                                helperText={touched.prix && errors.prix}
                                                                label="Chamber triple (DA)"
                                                                inputProps={{ min: 0 }}
                                                                type="number"
                                                                margin="normal"
                                                                name="prix"
                                                                onBlur={handleBlur}
                                                                onChange={(event) => {
                                                                    let tmp = values.prix
                                                                    tmp[2] = event.target.value
                                                                    setFieldValue("prix", tmp)
                                                                }}
                                                                value={values.prix[2]}
                                                                variant="outlined"
                                                            />


                                                        </div>
                                                        <div className="col-sm-6">
                                                            <TextField
                                                                error={Boolean(touched.prix && errors.prix)}
                                                                fullWidth
                                                                helperText={touched.prix && errors.prix}
                                                                label="Chamber quadruple (DA)"
                                                                inputProps={{ min: 0 }}
                                                                type="number"
                                                                margin="normal"
                                                                name="prix"
                                                                onBlur={handleBlur}
                                                                onChange={(event) => {
                                                                    let tmp = values.prix
                                                                    tmp[3] = event.target.value
                                                                    setFieldValue("prix", tmp)
                                                                }}
                                                                value={values.prix[3]}
                                                                variant="outlined"
                                                            />


                                                        </div>


                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {
                                                        values.enfants.length > 0 &&
                                                        <div>

                                                            <Container >



                                                                <Typography variant="body1">
                                                                    <strong>
                                                                        Age enfant / prix :
                                                                    </strong>
                                                                </Typography>


                                                                <ul>
                                                                    {
                                                                        values.enfants.map((inc, index) =>
                                                                            <li className="mt-2 mb-2">
                                                                                <Card

                                                                                >
                                                                                    <CardActionArea onClick={() => {
                                                                                    }}>
                                                                                        <CardContent>
                                                                                            <Stack
                                                                                                direction="row"
                                                                                                alignItems="center"
                                                                                                justifyContent="space-between"
                                                                                            >
                                                                                                <div>
                                                                                                    <i className="bi bi-check2" />
                                                                                                    {"Age :" + inc.age + " | Single : " + inc.prix[0] + " DA" + " | Double : " + inc.prix[1] + " DA" + " | Triple : " + inc.prix[2] + " DA" + " | Quadruple : " + inc.prix[3] + " DA"}
                                                                                                </div>

                                                                                                <IconButton
                                                                                                    sx={{
                                                                                                        '&:hover': {
                                                                                                            backgroundColor: 'red',

                                                                                                        },
                                                                                                    }}
                                                                                                    onClick={() => {
                                                                                                        let tmp = [...values.enfants]
                                                                                                        tmp = tmp.filter(item => item != inc)
                                                                                                        setFieldValue("enfants", tmp)
                                                                                                    }}
                                                                                                >
                                                                                                    <Delete></Delete>
                                                                                                </IconButton>

                                                                                            </Stack>
                                                                                        </CardContent>
                                                                                    </CardActionArea>
                                                                                </Card>
                                                                            </li>
                                                                        )
                                                                    }
                                                                </ul>

                                                            </Container>

                                                            <Divider></Divider>

                                                        </div>
                                                    }

                                                </div>


                                                <Typography variant="h5">
                                                    Prix Transport
                                                </Typography>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <TextField
                                                            error={Boolean(touched.prixTransport && errors.prixTransport)}
                                                            fullWidth
                                                            helperText={touched.prixTransport && errors.prixTransport}
                                                            label="Prix Transport (DA)"
                                                            margin="normal"
                                                            inputProps={{ min: 0 }}
                                                            type="number"
                                                            name="prixTransport"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.prixTransport}
                                                            variant="outlined"
                                                        />

                                                    </div>
                                                </div>
                                            </div>
                                        }
                                        {
                                            activeStep == 3 &&
                                            <div >
                                                <Typography variant="h6" >
                                                    <strong>
                                                        Image principale
                                                    </strong>

                                                </Typography>
                                                <input
                                                    id="contained-button-file"
                                                    style={{ display: "none" }}
                                                    onChange={value => {
                                                        if (value.currentTarget.files[0] != null) {
                                                            setFieldValue("image", value.currentTarget.files[0]);
                                                            // uploadImage(value.currentTarget.files[0], true, "principale")
                                                        }
                                                    }}
                                                    name="image"
                                                    accept="image/*"
                                                    type="file"

                                                />
                                                <Card sx={{ maxWidth: 250, maxHeight: 250, mt: 2 }}>
                                                    <CardActionArea>

                                                        {
                                                            values.image == null ?
                                                                <label htmlFor="contained-button-file" style={{ width: 250, height: 250 }}>

                                                                    <Stack
                                                                        direction="row"
                                                                        justifyContent="center"
                                                                        alignItems="center"
                                                                        style={{ width: 250, height: 250 }}
                                                                    >
                                                                        <AddIcon></AddIcon>

                                                                    </Stack>
                                                                </label>

                                                                :



                                                                <label htmlFor="contained-button-file" style={{ width: 250, height: 250 }}>

                                                                    <img
                                                                        style={{ width: 250, height: 250 }}
                                                                        src={URL.createObjectURL(values.image)}
                                                                    />
                                                                </label>

                                                        }


                                                    </CardActionArea>



                                                </Card>
                                                <Divider sx={{ mt: 3 }}></Divider>
                                                <Typography variant="h6" >
                                                    <strong>
                                                        Albume
                                                    </strong>

                                                </Typography>


                                                <Card sx={{
                                                    mt: 2
                                                }}>
                                                    <Container sx={{ pb: 4 }}>
                                                        <input
                                                            id="gallary-input"
                                                            style={{ display: "none" }}
                                                            onChange={value => {
                                                                let tmp = [...values.gallary]
                                                                if (ind != 0) {
                                                                    tmp[ind] = { type: 1, url: value.currentTarget.files[0] }
                                                                }
                                                                else {
                                                                    if (values.gallary.length < 3) {

                                                                        tmp.push({ type: 1, url: value.currentTarget.files[0] })
                                                                    }
                                                                    else {
                                                                        tmp[ind] = { type: 1, url: value.currentTarget.files[0] }
                                                                    }
                                                                }
                                                                setFieldValue("gallary", tmp);

                                                            }}
                                                            name="image"
                                                            accept="image/*"
                                                            type="file"

                                                        />

                                                        <Grid container spacing={2} sx={{ mt: 2 }}>
                                                            {
                                                                values.gallary.length < 3 ?
                                                                    <Grid item xs={12} md={6} lg={4}>
                                                                        <Card sx={{ maxWidth: 250, maxHeight: 250 }}>
                                                                            <CardActionArea>

                                                                                <label htmlFor="gallary-input" style={{ width: 250, height: 250 }}>
                                                                                    <Stack
                                                                                        direction="row"
                                                                                        justifyContent="center"
                                                                                        alignItems="center"
                                                                                        style={{ width: 250, height: 250 }}
                                                                                    >
                                                                                        <AddIcon></AddIcon>
                                                                                    </Stack>
                                                                                </label>
                                                                            </CardActionArea>
                                                                        </Card>
                                                                    </Grid>
                                                                    : <div></div>
                                                            }
                                                            {values.gallary.map((img, index) =>
                                                                <Grid item xs={12} md={6} lg={4}>


                                                                    <Card sx={{ maxWidth: 250, maxHeight: 250 }}>
                                                                        <CardActionArea>

                                                                            <label htmlFor="gallary-input" style={{ width: 250, height: 250 }} onClick={() => setInd(index)}>
                                                                                <img
                                                                                    style={{ width: 250, height: 250 }}
                                                                                    src={URL.createObjectURL(img.url)}
                                                                                />
                                                                            </label>
                                                                        </CardActionArea>
                                                                    </Card>
                                                                </Grid>
                                                            )
                                                            }
                                                        </Grid>
                                                    </Container>

                                                </Card>




                                                <Divider sx={{ mt: 3 }}></Divider>
                                                <Typography variant="h6" >
                                                    <strong>
                                                        PDF
                                                    </strong>

                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                >

                                                    <label htmlFor="button-file">
                                                        <Button variant="contained" component="span" size="large" startIcon={<UploadIcon />}>
                                                            <Input
                                                                id="button-file"
                                                                style={{ display: "none" }}
                                                                onChange={value => {
                                                                    setFieldValue("pdf", value.currentTarget.files[0]);
                                                                }

                                                                }
                                                                name="pdf"
                                                                accept=".pdf"
                                                                type="file"
                                                                inputProps={{
                                                                    accept: '.pdf',
                                                                }}
                                                            />
                                                            Importer
                                                        </Button>
                                                    </label>
                                                    <div>
                                                        {
                                                            values.pdf == null ? "" : <div>
                                                                <Button variant="contained" color="error" endIcon={<DeleteIcon />}
                                                                    onClick={() => setFieldValue("pdf", null)}
                                                                >
                                                                    Supprimer
                                                                </Button>

                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            values.pdf == null ? "" : <div>

                                                                <strong>

                                                                    {" " + values.pdf.name}
                                                                </strong>
                                                            </div>
                                                        }
                                                    </div>

                                                </Stack>


                                            </div>
                                        }
                                        {
                                            activeStep == 4 &&
                                            <Container>
                                                <div className="row mt-3">
                                                    <div className="col-sm-6">

                                                        <Typography variant="body1" gutterBottom>
                                                            <AccessTime
                                                                style={{
                                                                    color: color,
                                                                }}
                                                            ></AccessTime>
                                                            <strong>
                                                                - Durée : {' '}
                                                            </strong>
                                                            {
                                                                values.duration
                                                            }
                                                        </Typography>
                                                    </div>

                                                </div>
                                                <div className="row">

                                                    <div className="col-sm-6">
                                                        <Typography variant="body1">
                                                            <Title
                                                                style={{
                                                                    color: color,
                                                                }}></Title>
                                                            <strong>
                                                                - Titre : {' '}
                                                            </strong>
                                                            {
                                                                values.title
                                                            }
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <Typography variant="body1" gutterBottom>
                                                            <AirplanemodeActive
                                                                style={{
                                                                    color: color,
                                                                }}></AirplanemodeActive>
                                                            <strong>
                                                                -  Type : {' '}
                                                            </strong>
                                                            {
                                                                values.type
                                                            }
                                                        </Typography>
                                                    </div>
                                                    <div className="col-sm-6">
                                                        <Typography variant="body1" gutterBottom>
                                                            <LocationOn
                                                                style={{
                                                                    color: color,
                                                                }}></LocationOn>
                                                            <strong>
                                                                - Destination : {' '}
                                                            </strong>
                                                            {
                                                                values.destination
                                                            }
                                                        </Typography>
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <Typography variant="body1" gutterBottom>
                                                            <Explore
                                                                style={{
                                                                    color: color,
                                                                }}></Explore>
                                                            <strong>
                                                                - Tour : {' '}
                                                            </strong>
                                                            {
                                                                values.tour ? values.tour : ""

                                                            }
                                                        </Typography>
                                                    </div>

                                                    <div className="col-sm-6">
                                                        <Typography variant="body1" gutterBottom>
                                                            <Hotel
                                                                style={{
                                                                    color: color,
                                                                }}></Hotel>
                                                            <strong>
                                                                - Hotel : {' '}
                                                            </strong>
                                                            {
                                                                values.hotel ? values.hotel : ""

                                                            }
                                                        </Typography>
                                                    </div>


                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <Typography variant="body1" >
                                                            <FlightTakeoff
                                                                style={{
                                                                    color: color,
                                                                }}
                                                            />
                                                            <strong>- Departs / retour :</strong>
                                                            <br />
                                                        </Typography>
                                                        <ul
                                                            style={{ marginLeft: 30 }}
                                                        >
                                                            {values.departs.map((item, ind) =>
                                                                <li key={ind}>
                                                                    <FlightTakeoff></FlightTakeoff> - {item.depature} / <FlightLand></FlightLand> - {item.return} --  <Group></Group> Places : {item.places}
                                                                </li>)}
                                                        </ul>
                                                        <br />
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <Typography variant="body1" gutterBottom>
                                                            <AddTask
                                                                style={{
                                                                    color: color,
                                                                }}></AddTask>
                                                            <strong>- Inclue : {' '}</strong>
                                                            <br />

                                                        </Typography>
                                                        <ul
                                                            style={{ marginLeft: 30 }}
                                                        >
                                                            {values.include.map((item, ind) =>
                                                                <li key={ind}>

                                                                    <Typography>
                                                                        <Check></Check>
                                                                        {item}
                                                                    </Typography>
                                                                </li>
                                                            )}
                                                        </ul>
                                                        <br />

                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <Typography variant="body1" component="div" sx={{
                                                            display: "-webkit-box",
                                                            boxOrient: "vertical",
                                                            wordBreak: "break-all",
                                                            overflow: "hidden",
                                                            color: "black"
                                                        }}>
                                                            <InsertDriveFile
                                                                style={{
                                                                    color: color,
                                                                }}></InsertDriveFile>
                                                            <strong>- Fichiers :</strong>
                                                            <br />

                                                        </Typography>
                                                        <ul
                                                            style={{ marginLeft: 30 }}
                                                        >
                                                            {values.files.map((item, ind) =>
                                                                <li key={ind}>
                                                                    <UploadFile></UploadFile>
                                                                    {item}</li>)}
                                                        </ul>
                                                        <br />
                                                    </div>

                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <Typography variant="body1" gutterBottom>
                                                            <MonetizationOn
                                                                style={{
                                                                    color: color,
                                                                }}></MonetizationOn>
                                                            <strong>
                                                                - Prix : {' ' + values.prixGenerale + " DA"}
                                                            </strong>


                                                        </Typography>
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <Typography variant="body1" gutterBottom>
                                                            <MonetizationOn
                                                                style={{
                                                                    color: color,
                                                                }}></MonetizationOn>
                                                            <strong>
                                                                - Prix adulte : {' '}
                                                            </strong>
                                                            <ul
                                                                style={{ paddingLeft: 30 }}
                                                            >
                                                                <li>
                                                                    <Check /> Chmaber single :
                                                                    <strong>
                                                                        {
                                                                            values.priceAdulte[0] + " DA"
                                                                        }
                                                                    </strong>
                                                                </li>
                                                                <li>
                                                                    <Check /> Chmaber double :
                                                                    <strong>
                                                                        {
                                                                            values.priceAdulte[1] + " DA"
                                                                        }
                                                                    </strong>
                                                                </li>
                                                                <li>
                                                                    <Check /> Chmaber triple :
                                                                    <strong>
                                                                        {
                                                                            values.priceAdulte[2] + " DA"
                                                                        }
                                                                    </strong>
                                                                </li>
                                                                <li>
                                                                    <Check /> Chmaber Quadruple :
                                                                    <strong>
                                                                        {
                                                                            values.priceAdulte[3] + " DA"
                                                                        }
                                                                    </strong>
                                                                </li>
                                                            </ul>

                                                        </Typography>
                                                    </div>
                                                    {
                                                        values.enfants.map((item, ind) =>
                                                            <div>
                                                                <div className="col-sm-6">
                                                                    <Typography variant="body1" gutterBottom>
                                                                        <MonetizationOn
                                                                            style={{
                                                                                color: color,
                                                                            }}></MonetizationOn>
                                                                        <strong>
                                                                            - Prix enfant {item.age} : {' '}
                                                                        </strong>
                                                                        <ul
                                                                            style={{ paddingLeft: 30 }}
                                                                        >
                                                                            <li>
                                                                                <Check /> Chmaber single :
                                                                                <strong>
                                                                                    {
                                                                                        item.prix[0] + " DA"
                                                                                    }
                                                                                </strong>
                                                                            </li>
                                                                            <li>
                                                                                <Check /> Chmaber double :
                                                                                <strong>
                                                                                    {
                                                                                        item.prix[1] + " DA"
                                                                                    }
                                                                                </strong>
                                                                            </li>
                                                                            <li>
                                                                                <Check /> Chmaber triple :
                                                                                <strong>
                                                                                    {
                                                                                        item.prix[2] + " DA"
                                                                                    }
                                                                                </strong>
                                                                            </li>
                                                                            <li>
                                                                                <Check /> Chmaber Quadruple :
                                                                                <strong>
                                                                                    {
                                                                                        item.prix[3] + " DA"
                                                                                    }
                                                                                </strong>
                                                                            </li>
                                                                        </ul>
                                                                    </Typography>
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <Typography variant="body1" gutterBottom>
                                                            <MonetizationOn
                                                                style={{
                                                                    color: color,
                                                                }}></MonetizationOn>
                                                            <strong>
                                                                - Prix Transport : {' '}
                                                            </strong>
                                                            {
                                                                values.prixTransport + " DZD"
                                                            }
                                                        </Typography>
                                                    </div>
                                                </div>

                                                <div className="row mt-2">

                                                    <Typography variant="body1" component="div" sx={{
                                                        display: "-webkit-box",
                                                        boxOrient: "vertical",
                                                        wordBreak: "break-all",
                                                        overflow: "hidden",
                                                        color: "black"
                                                    }}>
                                                        <Description
                                                            style={{
                                                                color: color,
                                                            }}></Description>
                                                        <strong>- Description :</strong><br /> {values.description}<br />
                                                    </Typography>


                                                </div>
                                            </Container>
                                        }




                                    </DialogContent>
                                    <DialogActions>
                                        <Button
                                            variant="outlined"
                                            onClick={() => { activeStep == 0 ? handleClose() : setActiveStep(activeStep - 1) }}
                                            startIcon={<ArrowBack></ArrowBack>}
                                        >
                                            {
                                                activeStep == 0 ?
                                                    'Fermer'
                                                    :
                                                    "Retour"
                                            }
                                        </Button>
                                        {
                                            activeStep < 4 ?
                                                <Button
                                                    onClick={() => { setActiveStep(activeStep + 1) }}
                                                    variant="contained"
                                                    endIcon={<ArrowForward></ArrowForward>}

                                                >
                                                    Suivant
                                                </Button>
                                                :
                                                <div></div>
                                        }
                                        {
                                            activeStep == 4 ?
                                                <LoadingButton
                                                    loading={loading}
                                                    disabled={!isValid}
                                                    type="submit"
                                                    variant="contained"
                                                    endIcon={<Edit></Edit>}
                                                >
                                                    Ajouter
                                                </LoadingButton> :
                                                <div></div>
                                        }


                                    </DialogActions>
                                </Form>

                            )}
                        </Formik>
                    </CardContent>
                </Container>
            </Dialog>

            {/* ===============  Package gird area start =============== */}
            <div className="package-wrapper pt-20">
                <div className="container">
                    <Button variant="contained" onClick={handleOpen} sx={{
                        backgroundColor: "#ff4838", ':hover': {
                            bgcolor: '#ff4860', // theme.palette.primary.main
                            color: 'white',
                        },
                    }}>Ajouter un programme +</Button>
                    <div style={{ marginTop: "20px" }}>
                        {
                            listProgramme.length == 0 ?
                                <Stack mt={10} height="100%" alignItems="center" justifyContent="center">
                                    <Alert severity="error">
                                        Aucun programme trouvé !
                                    </Alert>
                                </Stack> : <div></div>
                        }
                        <div className="row g-4">
                            {
                                listProgramme.map((programme, index) =>
                                    <div className="col-lg-4 col-md-6" key={index}>
                                        <Link
                                            onClick={() => {
                                                history.push({ pathname: `${process.env.PUBLIC_URL}/admin/packages-detail`, state: { programme: programme } }
                                                )
                                            }}
                                        >
                                            <AdminPackageCard
                                                programme={programme}
                                                image={programme.image}
                                                date={programme.duration}
                                                title={programme.title}
                                                price={programme.prixGenerale + " DA"}
                                            />

                                        </Link>
                                    </div>

                                )
                            }
                        </div>

                    </div>
                    {/* <Pagination /> */}
                </div>
            </div>
            {/* ===============  Package gird area end =============== */}
        </>
    );
}

export default AdminPackage;
