import React from 'react';
import { Redirect } from 'react-router-dom';
import { UserAuth } from './UserContext';

const ProtectedRoute = ({ children }) => {
    const { user, logout } = UserAuth();

    let data = JSON.parse(sessionStorage.getItem("verison"))


    if (!data || data.displayName == "client") {
        logout()
        return <Redirect to={`${process.env.PUBLIC_URL}/signin`} />;
    }
    return children;
};

export default ProtectedRoute;