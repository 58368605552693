/** @format */

import React, { useRef } from 'react';
import { Link, useLocation } from 'react-router-dom';
// import Swiper core and required modules
import SwiperCore, { EffectFade, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import TopDestination from './TopDestination';
import { Alert, Snackbar } from '@mui/material';
// install Swiper modules
SwiperCore.use([Navigation, Pagination, EffectFade]);
function Home3HeroArea(props) {
	const hero3Silde = {
		slidesPerView: 1,
		speed: 1500,
		parallax: true,
		loop: true,
		spaceBetween: 0,
		centeredSlides: true,
		roundLengths: true,
		effect: 'fade',
		navigation: {
			nextEl: '.hero-next3',
			prevEl: '.hero-prev3',
		},

		autoplay: {
			delay: 5000,
		},
		pagination: {
			el: '.hero-two-pagination',
			clickable: true,
			renderBullet: function (index, className) {
				return (
					'<span class="' +
					className +
					'">' +
					0 +
					(index + 1) +
					'</span>'
				);
			},
		},
	};

	const destination = useRef(null);

	const scrollTo = (e) => {
		destination.current.scrollIntoView({ behavior: 'smooth' });
	};

	const location = useLocation();

	const [snake, setSnake] = React.useState(
		location.state?.snake == true ? true : false
	);
	const handleCloseSnake = () => setSnake(false);

	return (
		<>
			<Snackbar
				open={snake}
				autoHideDuration={5000}
				onClose={handleCloseSnake}>
				<Alert
					onClose={handleCloseSnake}
					severity="success"
					sx={{ width: '100%' }}>
					Votre demande d'inscription a été ajouté !
				</Alert>
			</Snackbar>
			<div className="hero-area hero-style-three">
				<img
					src={
						process.env.PUBLIC_URL +
						'/images/banner/banner-plane.svg'
					}
					className="img-fluid banner-plane"
					alt="banners-img"
				/>
				<div className="hero-main-wrapper position-relative">
					<div className="hero-social">
						<ul className="social-list d-flex justify-content-center align-items-center gap-4">
							<li>
								<Link
									onClick={() => {
										window.open(
											'https://www.facebook.com/verisontour/'
										);
									}}>
									Facebook
								</Link>
							</li>
							<li>
								<Link
									onClick={() => {
										window.open(
											'https://www.instagram.com/verisontour/'
										);
									}}>
									instagram
								</Link>
							</li>
							<li>
								<Link
									onClick={() => {
										window.open(
											'https://call.whatsapp.com/voice/xwMfSV1xBXO27puuJUAlJg'
										);
									}}>
									WhatsApp
								</Link>
							</li>
						</ul>
					</div>
					<Swiper
						className="swiper  hero-slider-three "
						{...hero3Silde}>
						<div className="swiper-wrapper">
							<SwiperSlide className="swiper-slide">
								<div className="slider-bg-1">
									<div className="container">
										<div className="row d-flex justify-content-center align-items-center">
											<div className="col-lg-8">
												<div className="hero3-content">
													<span className="title-top">
														Bienvenu à Verison
													</span>
													<h1>
														Voyage à la découverte
														du monde
													</h1>
													<p>
														{' '}
														Il m'est agréable de
														vous présenter, notre
														agence{' '}
														<strong
															style={{
																color: '#ff4838',
															}}>
															«VERISON BOOKING»
														</strong>
														. Dont la vocation est
														de transformer chaque
														client en hôte
														privilégié et chaque
														étape de son séjour en
														souvenir inoubliable.
													</p>
													<a
														href="#destination"
														className="button-fill-primary banner3-btn">
														Réservez votre voyage
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>

							<SwiperSlide className="swiper-slide">
								<div className="slider-bg-3">
									<div className="container">
										<div className="row d-flex justify-content-center align-items-center">
											<div className="col-lg-8">
												<div className="hero3-content">
													<span className="title-top">
														Bienvenu à Verison
													</span>
													<h1>
														Profitez de votre
														nouvelle aventure
													</h1>
													<p>
														{' '}
														Il m'est agréable de
														vous présenter, notre
														agence{' '}
														<strong
															style={{
																color: '#ff4838',
															}}>
															«VERISON BOOKING»
														</strong>
														. Dont la vocation est
														de transformer chaque
														client en hôte
														privilégié et chaque
														étape de son séjour en
														souvenir inoubliable.
													</p>
													<a
														href="#destination"
														className="button-fill-primary banner3-btn">
														Réservez votre voyage
													</a>
												</div>
											</div>
										</div>
									</div>
								</div>
							</SwiperSlide>
						</div>
					</Swiper>
					<div className="slider-arrows text-center d-lg-flex flex-column d-none gap-5">
						<div
							className="hero-prev3"
							tabIndex={0}
							role="button"
							aria-label="Previous slide">
							{' '}
							<i className="bi bi-arrow-left" />
						</div>
						<div
							className="hero-next3"
							tabIndex={0}
							role="button"
							aria-label="Next slide">
							<i className="bi bi-arrow-right" />
						</div>
					</div>
				</div>
			</div>
			<div id="destination">
				<TopDestination />
			</div>
		</>
	);
}

export default Home3HeroArea;
