/** @format */

import { collection, getDocs } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { SRLWrapper } from 'simple-react-lightbox';
import { cleanData, db } from '../../Firebase';

function Footer(props) {
	// smooth scrol
	const scrollTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const [listTour, setListTour] = useState([]);

	const getTour = async () => {
		setListTour([]);
		await getDocs(collection(db, 'tour'))
			.then((querySnapshot) => {
				const newData = cleanData(querySnapshot);
				setListTour(newData);
			})
			.catch((err) => {
				console.log(err);
			});
	};

	useEffect(() => {
		getTour();
	}, []);

	let history = useHistory();

	return (
		<>
			<div className={props.className}>
				<div className="footer-main-wrapper">
					<div className="footer-vactor">
						<img
							src={
								process.env.PUBLIC_URL +
								'/images/banner/footer-bg.png'
							}
							alt="FooterIMG"
						/>
					</div>
					<div className="container">
						<div className="row justify-content-center g-4">
							<div className="col-lg-4">
								<div className="footer-about text-lg-start text-center">
									<p>
										Il m'est agréable de vous présenter,
										notre agence{' '}
										<strong style={{ color: '#ff4838' }}>
											«VERISON BOOKING»
										</strong>
										. Dont la vocation est de transformer
										chaque client en hôte privilégié et
										chaque étape de son séjour en souvenir
										inoubliable.
									</p>
									<div className="footer-social-wrap">
										<h5>Suivez-nous sur:</h5>
										<ul className="footer-social-links justify-content-lg-start justify-content-center">
											<li>
												<a
													rel="noopener noreferrer"
													href="https://www.instagram.com/verisontour"
													target="_blank">
													<i className="bx bxl-instagram" />
												</a>
											</li>
											<li>
												<a
													rel="noopener noreferrer"
													target="_blank"
													href="https://www.facebook.com/verisontour">
													<i className="bx bxl-facebook" />
												</a>
											</li>
											{/* <li>
												<a
													rel="noopener noreferrer"
													href="https://www.twitter.com/"
													target="_blank">
													<i className="bx bxl-twitter" />
												</a>
											</li> */}
											<li>
												<a
													rel="noopener noreferrer"
													href="https://call.whatsapp.com/voice/xwMfSV1xBXO27puuJUAlJgs"
													target="_blank">
													<i className="bx bxl-whatsapp" />
												</a>
											</li>
											{/* <li>
												<a
													rel="noopener noreferrer"
													href="https://www.pinterest.com/"
													target="_blank"
													to={'#'}>
													<i className="bx bxl-pinterest-alt" />
												</a>
											</li> */}
										</ul>
									</div>
								</div>
							</div>
							<div className="col-lg-2 col-md-4">
								<div className="footer-widget">
									<h3 className="footer-widget-title">
										Lien rapide
									</h3>
									<ul className="footer-links">
										<li
										// className="has-child-menu"
										>
											<Link
												to={`${process.env.PUBLIC_URL}/`}
												onClick={scrollTop}>
												<div
													style={{
														borderBottom:
															window.location.href
																.split('/')
																.at(-1) == ''
																? '3px solid #ff4838'
																: '',
													}}>
													Acceuil
												</div>
											</Link>
										</li>

										<li className="has-child-menu">
											<Link
												to={'/destination'}
												onClick={scrollTop}>
												<div
													style={{
														borderBottom: [
															'destination',
															'package',
															'package-details',
														].includes(
															window.location.href
																.split('/')
																.at(-1)
														)
															? '3px solid #ff4838'
															: '',
													}}>
													Destination
												</div>
											</Link>
										</li>

										<li>
											<Link
												onClick={() => {
													scrollTop();
													history.push({
														pathname: '/package',
														state: {
															type: 'omra',
															tour: false,
														},
													});
												}}>
												Omra
											</Link>
										</li>

										<li>
											<Link
												to={'/visa'}
												onClick={scrollTop}>
												<div
													style={{
														borderBottom:
															window.location.href
																.split('/')
																.at(-1) ==
															'visa'
																? '3px solid #ff4838'
																: '',
													}}>
													Visa
												</div>
											</Link>
										</li>

										<li>
											<Link
												to={'/billetterie'}
												onClick={scrollTop}>
												<div
													style={{
														borderBottom:
															window.location.href
																.split('/')
																.at(-1) ==
															'billetterie'
																? '3px solid #ff4838'
																: '',
													}}>
													Billetterie
												</div>
											</Link>
										</li>

										<li>
											<Link
												to={'/hotelerie'}
												onClick={scrollTop}>
												<div
													style={{
														borderBottom:
															window.location.href
																.split('/')
																.at(-1) ==
															'hotelerie'
																? '3px solid #ff4838'
																: '',
													}}>
													Hotelerie
												</div>
											</Link>
										</li>

										<li>
											<Link
												to={'/contact'}
												onClick={scrollTop}>
												<div
													style={{
														borderBottom:
															window.location.href
																.split('/')
																.at(-1) ==
															'contact'
																? '3px solid #ff4838'
																: '',
													}}>
													Contact
												</div>
											</Link>
										</li>

										<li>
											<Link
												to={'/about'}
												onClick={scrollTop}>
												<div
													style={{
														borderBottom:
															window.location.href
																.split('/')
																.at(-1) ==
															'about'
																? '3px solid #ff4838'
																: '',
													}}>
													A propos
												</div>
											</Link>
										</li>
									</ul>
								</div>
							</div>
							<div className="col-lg-4 col-md-4">
								<div className="footer-widget">
									<h4 className="footer-widget-title">
										Type de voyage
									</h4>
									<ul className="footer-links">
										{listTour.map((l, ind) => (
											<li key={ind}>
												<Link
													replace={true}
													to={{
														pathname: '/package',

														state: {
															type: '',
															tour: l.label,
														},
													}}
													onClick={scrollTop}>
													{l.label}
												</Link>
											</li>
										))}
									</ul>
								</div>
							</div>
							<div className="col-lg-2 col-md-4"></div>
							{/* <>
                <div className="col-lg-4 col-md-8">
                  <SRLWrapper>
                    <div className="footer-widget">
                      <h4 className="footer-widget-title text-center">
                        Gallery
                      </h4>
                      <div className="footer-gallary-grid">
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron01.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-1.png"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron02.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-2.png"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron06.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-3.png"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron05.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-4.png"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron03.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-5.png"} alt="footerIMG" />
                          </a>
                        </div>
                        <div className="footer-gallary-item">
                          <a
                            href={process.env.PUBLIC_URL + "/images/gallary/fullsecrron06.png"}
                            data-fancybox="footer"
                            data-caption="Caption Here"
                          >
                            <img src={process.env.PUBLIC_URL + "/images/gallary/fg-6.png"} alt="footerIMG" />
                          </a>
                        </div>
                      </div>
                    </div>
                  </SRLWrapper>
                </div>
              </> */}
						</div>
						<div className="footer-contact-wrapper">
							<h5>Contact :</h5>
							<ul className="footer-contact-list">
								<li>
									<i className="bi bi-telephone-x" />{' '}
									<a
										rel="noopener noreferrer"
										href="tel:+213 796276168">
										+213 796276168
									</a>
								</li>
								<li>
									<i className="bi bi-envelope-open" />{' '}
									<a href="mailto:info@example.com">
										info@example.com
									</a>
								</li>
								<li>
									<i className="bi bi-geo-alt" />{' '}
									<a
										rel="noopener noreferrer"
										target="_blank"
										href="https://goo.gl/maps/nkaCPJPjATZrijv86">
										Rue de SÉTIF, Mohammadia, Algeria
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div>
				<div className="footer-bottom">
					<div className="container">
						<div className="row align-items-center justify-content-center">
							<div className="col-lg-4 col-md-6 order-lg-1 order-3 ">
								<div className="copyright-link text-lg-start text-center">
									<p>Copyright 2023 Verison</p>
								</div>
							</div>
							<div className="col-lg-4  order-lg-2 order-1">
								<div className="footer-logo text-center">
									<Link
										onClick={scrollTop}
										to={`${process.env.PUBLIC_URL}/`}>
										<img
											width={'150'}
											src={
												process.env.PUBLIC_URL +
												'/images/verison.png'
											}
											alt="FooterIMG"
										/>
									</Link>
								</div>
							</div>
							<div className="col-lg-4 col-md-6 order-lg-3 order-2">
								<div className="policy-links">
									<ul className="policy-list justify-content-lg-end justify-content-center">
										<li>
											<Link to={'#'}>
												Terms &amp; Condition
											</Link>
										</li>
										<li>
											<Link to={'#'}>Privacy Policy</Link>
										</li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Footer;
