/** @format */

import React from 'react';

import { Link, useHistory } from 'react-router-dom';
import DestinationCart from './DestinationCart';

function DestionationWrapper() {
	const listDestination = [
		{
			image: '/images/mecca.jpg',
			place: 'Omra',
			places: '-',
			type: 'omra',
		},
		{
			image: '/images/algeria.jpg',
			place: 'National',
			places: '-',
			type: 'national',
		},
		{
			image: '/images/paris.jpg',
			place: 'International',
			places: '-',
			type: 'international',
		},
	];

	let history = useHistory();

	return (
		<>
			<div className="destination-wrapper pt-110">
				<div className="container">
					<div className="row g-3">
						{listDestination.map((destination, index) => (
							<div className="col-lg-4 col-md-4 col-sm-6">
								<Link
									id={index}
									onClick={() => {
										history.push({
											pathname: '/package',
											state: { type: destination.type },
										});
									}}>
									<DestinationCart
										image={
											process.env.PUBLIC_URL +
											destination.image
										}
										palce={destination.place}
										palceCount={destination.places}
									/>
								</Link>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
}

export default DestionationWrapper;
