import { AccountBox, AlternateEmail, Article, Badge, Fax, Home, LocalPhone, LocalPostOffice, LocationCity, MonetizationOn, Public, Smartphone, UploadFile } from '@mui/icons-material'
import { Alert, Divider } from '@mui/material'
import React from 'react'
import { IconLabelItem } from './IconLabelItem'

export default function DialogInscription(props) {
    const item = props.item
    const color = '#ff4838'

    return (
        <div>
            <div className='row'>
                <div className='col'>
                    {IconLabelItem(<AccountBox style={{ color: color }}></AccountBox>, "Nom agence", item?.nom_agence)}
                </div>
                <div className='col'>
                    {IconLabelItem(<Article style={{ color: color }}></Article>, "licence", <a href={item?.licence_file} download rel="noopener noreferrer"
                        target="_blank">{item?.licence}</a>)}
                </div>

            </div>
            <div className='row'>
                <div className='col'>
                    {IconLabelItem(<MonetizationOn style={{ color: color }}></MonetizationOn>, "Solde", item?.montant + ' DA')}
                </div>
            </div>
            <div className='row'>
                <div className='col'>
                    <Alert icon={false} severity={item?.versement >= 0 ? 'success' : 'error'}>
                        {IconLabelItem(<MonetizationOn style={{ color: color }}></MonetizationOn>, "Crédit", item?.versement + ' DA')}
                    </Alert>
                </div>
            </div>
            <Divider sx={{ mt: 1, mb: 1, color: color, borderBottomWidth: 3 }} variant="middle" ></Divider>

            <div class="row">
                <div className="col-sm-6">
                    {IconLabelItem(
                        <Home
                            style={{
                                color: color,
                            }}></Home>,
                        'Adresse',
                        item?.adresse
                    )}
                </div>

                <div className="col-sm-6">
                    {IconLabelItem(
                        <LocationCity
                            style={{
                                color: color,
                            }}></LocationCity>,
                        'Ville',
                        item?.ville
                    )}
                </div>
                <div className="col-sm-6">
                    {IconLabelItem(
                        <Public
                            style={{
                                color: color,
                            }}></Public>,
                        'Pays',
                        item?.pays
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-4">
                    {IconLabelItem(
                        <LocalPhone
                            style={{
                                color: color,
                            }}></LocalPhone>,
                        'Téléphone',
                        item?.telephone
                    )}
                </div>
                <div className="col-sm-4">
                    {IconLabelItem(
                        <LocalPostOffice
                            style={{
                                color: color,
                            }}></LocalPostOffice>,
                        'Code postal',
                        item?.code_postal
                    )}
                </div>

                <div className="col-sm-4">
                    {IconLabelItem(
                        <Fax
                            style={{
                                color: color,
                            }}></Fax>,
                        'Fax',
                        item?.fax
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    {IconLabelItem(
                        <UploadFile
                            style={{
                                color: color,
                            }}></UploadFile>,
                        'Registre commerce',
                        <a
                            href={
                                item?.registre_commerce_file
                            }
                            download
                            rel="noopener noreferrer"
                            target="_blank">
                            {
                                item?.registre_commerce
                            }
                        </a>
                    )}
                </div>
            </div>
            <div className="row">
                <div className="col-sm-6">
                    {IconLabelItem(
                        <UploadFile
                            style={{
                                color: color,
                            }}></UploadFile>,
                        'NIF',
                        <a
                            href={item?.nif_file}
                            download
                            rel="noopener noreferrer"
                            target="_blank">
                            {item?.nif}
                        </a>
                    )}
                </div>
            </div>
            <Divider
                sx={{ mt: 1, mb: 1, color: color, borderBottomWidth: 3 }}
                variant="middle"
            ></Divider>
            <div className="row">
                <div className="col-sm-4">
                    {IconLabelItem(
                        <Badge
                            style={{
                                color: color,
                            }}></Badge>,
                        'Civilité',
                        item?.civilite
                    )}
                </div>
                <div className="col-sm-4">
                    {IconLabelItem(
                        <Badge
                            style={{
                                color: color,
                            }}></Badge>,
                        'Nom',
                        item?.nom
                    )}
                </div>
                <div className="col-sm-4">
                    {IconLabelItem(
                        <Badge
                            style={{
                                color: color,
                            }}></Badge>,
                        'Prénom',
                        item?.prenom
                    )}
                </div>
            </div>
            <div className='row'>
                <div className="col-sm-6">
                    {IconLabelItem(
                        <Smartphone
                            style={{
                                color: color,
                            }}></Smartphone>,
                        'Mobile',
                        item?.mobile
                    )}
                </div>
                <div className="col-sm-6">
                    {IconLabelItem(
                        <AlternateEmail
                            style={{
                                color: color,
                            }}></AlternateEmail>,
                        'Email',
                        item?.email
                    )}
                </div>
            </div>
        </div >
    )
}
