/** @format */

import React from 'react';
import Home3HeroArea from './Home3HeroArea';
import MoreUpdate from './MoreUpdate';
import Testimonial3 from './Testimonial';
import Tourguide3 from './Tourguide';

function HomeFour() {
	return (
		<>
			<Home3HeroArea />
			<Testimonial3 />
			<Tourguide3 />
			<MoreUpdate />
		</>
	);
}

export default HomeFour;
