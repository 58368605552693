/** @format */

import {
	Alert,
	Avatar,
	Button,
	Card,
	CardContent,
	Container,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Grid,
	IconButton,
	Input,
	ListItem,
	ListItemIcon,
	Menu,
	MenuItem,
	Snackbar,
	Stack,
	TextField,
	Typography,
} from '@mui/material';
import React, { useEffect, useReducer, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { UserAuth } from '../context/UserContext';
import {
	AccountBox,
	AccountCircle,
	Article,
	Badge,
	CheckCircle,
	ContactEmergency,
	Edit,
	Fax,
	Home,
	LocalPhone,
	LocalPostOffice,
	LocationCity,
	Logout,
	Paid,
	Public,
	Settings,
	UploadFile,
} from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../../Firebase';
import { getAuth } from 'firebase/auth';
import { IconLabelItem } from './IconLabelItem';
import DialogInscription from './DialogInscription';

function Header() {
	const [searchActive, setSearchActive] = useState(0);
	const [catagoryActive, setCatagoryActive] = useState(0);
	const [userDropdown, setuserDropdown] = useState(0);
	const [mobileSideberMenu, setMobileSideberMenu] = useState(0);
	const scrollTop = () => window.scrollTo({ top: 0, behavior: 'smooth' });

	/*useing reducer to manage the active or inactive menu*/

	useEffect(() => {
		// Add scroll event when the component is loaded
		// window.addEventListener("scroll", isSticky);
		// return () => {
		//   window.removeEventListener("scroll", isSticky);
		// };
	});
	/* Method that will fix header after a specific scrollable */
	const isSticky = (e) => {
		const header = document.querySelector('header');
		const scrollTop = window.scrollY;
		scrollTop <= 400
			? header.classList.add('sticky')
			: header.classList.remove('sticky');
	};

	const initialState = { activeMenu: 'acceuil' };
	const [state, dispatch] = useReducer(reducer, initialState);

	function reducer(state, action) {
		switch (action.type) {
			case 'acceuil':
				return { activeMenu: 'acceuil' };
			case 'propos':
				return { activeMenu: 'propos' };
			case 'destination':
				return { activeMenu: 'destination' };
			case 'contact':
				return { activeMenu: 'contact' };
		}
	}
	const handleSearhBar = () => {
		if (searchActive === false || searchActive === 0) {
			setSearchActive(1);
		} else {
			setSearchActive(false);
		}
	};
	const handleCatagorybtn = () => {
		if (catagoryActive === false || catagoryActive === 0) {
			setCatagoryActive(1);
		} else {
			setCatagoryActive(false);
		}
	};
	const handleUserDropDown = () => {
		if (userDropdown === false || userDropdown === 0) {
			setuserDropdown(1);
		} else {
			setuserDropdown(false);
		}
	};
	const handleMobileSiderbar = () => {
		if (mobileSideberMenu === false || mobileSideberMenu === 0) {
			setMobileSideberMenu(1);
		} else {
			setMobileSideberMenu(false);
		}
	};

	let history = useHistory();

	const { user, usr, getUsr, logout, updateMail } = UserAuth();

	const deconnecter = (e) => {
		logout();
		history.push('/signin');
	};

	////  menu
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	//// mon compte
	const [color] = React.useState('#ff4838');

	const item = usr;
	const [openCompte, setOpenCompte] = React.useState(false);
	const handleOpenCompte = () => setOpenCompte(true);
	const handleCloseCompte = () => setOpenCompte(false);

	const editEmail = async (email) => {
		try {
			updateMail(email);
		} catch (e) {
			setIsError(true);
			handleSnake();
			setdone(false);
			handleCloseConfirmation();
			return false;
		}
	};

	const editCompte = async () => {
		setdone(true);
		let itm = item;
		let values = confirmationValues;
		if (item.email != values.email) {
			editEmail(values.email);
		}
		try {
			const docRef = await updateDoc(doc(db, 'inscription', itm.id), {
				adresse: values.adresse,
				pays: values.pays,
				ville: values.ville,
				// code_postal: values.code_postal,
				telephone: values.telephone,
				// fax: values.fax,
				// civilite: values.civilite,
				// nom: values.nom,
				// prenom: values.prenom,
				mobile: values.mobile,
				email: values.email,
			}).then(() => {
				// setChange(false);
				getUsr();
				setdone(false);
				handleCloseConfirmation();
				setIsError(false);
				handleSnake();
			});
		} catch (e) {
			// setdone(false);
			handleCloseConfirmation();
			setIsError(true);
			handleSnake();
		}
	};
	// confirmation
	const [done, setdone] = useState(false);
	const [confirmationValues, setconfirmationValues] = useState();
	const [confirmation, setconfirmation] = React.useState(false);
	const handleOpenConfirmation = (item, values) => {
		setdone(false);
		setconfirmationValues(values);
		setconfirmation(true);
	};
	const handleCloseConfirmation = () => setconfirmation(false);

	const [snake, setSnake] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const handleSnake = async () => {
		setSnake(true);
	};
	const handleCloseSnake = () => setSnake(false);

	return (
		<>
			<Snackbar
				open={snake}
				autoHideDuration={5000}
				onClose={handleCloseSnake}>
				{!isError ? (
					<Alert
						onClose={handleCloseSnake}
						severity="success"
						sx={{ width: '100%' }}>
						Modification faite avec success !
					</Alert>
				) : (
					<Alert
						onClose={handleCloseSnake}
						severity="error"
						sx={{ width: '100%' }}>
						Erreur !
					</Alert>
				)}
			</Snackbar>
			<Dialog
				fullWidth
				maxWidth="sm"
				open={confirmation}
				onClose={handleCloseConfirmation}>
				<DialogTitle>
					<strong>Confirmation</strong>
				</DialogTitle>
				<DialogContent>
					<DialogContentText>
						êtes-vous sûr de vouloir confirmer cette modification ?{' '}
					</DialogContentText>
				</DialogContent>
				<DialogActions>
					<Button
						variant="outlined"
						autoFocus
						color="inherit"
						onClick={handleCloseConfirmation}>
						Annuler
					</Button>
					<LoadingButton
						loading={done}
						variant="contained"
						onClick={editCompte}>
						Confirmer
					</LoadingButton>
				</DialogActions>
			</Dialog>
			<Dialog
				scroll="body"
				fullWidth
				maxWidth="md"
				open={openCompte}
				onClose={handleCloseCompte}>
				<Card>
					<Container sx={{ mt: 3 }}>
						<Grid container>
							<Grid item xs={12} md={6}>
								<Typography variant="h4">
									<strong>Mon compte</strong>
								</Typography>
							</Grid>
							<Grid item xs={12} md={6}>
								<Stack
									direction="row"
									justifyContent="flex-end"
									alignItems="center">
									<Typography gutterBottom>
										<strong>
											<Paid></Paid>
										</strong>
									</Typography>
									<Typography gutterBottom>
										<strong>Solde :</strong>{' '}
										<strong>{usr.montant + ' DA'}</strong>
									</Typography>
								</Stack>
							</Grid>
						</Grid>

						<CardContent>
							<Formik
								initialValues={{
									adresse: item.adresse,
									pays: item.pays,
									ville: item.ville,
									code_postal: item.code_postal,
									telephone: item.telephone,
									fax: item.fax,
									civilite: item.civilite,
									prenom: item.prenom,
									nom: item.nom,
									mobile: item.mobile,
									email: item.email,
								}}
								validationSchema={Yup.object().shape({
									adresse: Yup.string()
										.max(255)
										.required('champ obligatoire'),
									pays: Yup.string()
										.max(255)
										.required('champ obligatoire'),
									ville: Yup.string()
										.max(255)
										.required('champ obligatoire'),

									telephone: Yup.string().test(
										'telephone',
										'Le numéro est invalide',
										(value) =>
											!Number.isNaN(Number(value)) &&
											value.length == 10 &&
											value[0] == '0'
									),
									// fax: Yup.string()
									// 	.max(255)
									// 	.required('champ obligatoire'),
									// civilite: Yup.string()
									// 	.max(255)
									// 	.required('champ obligatoire'),
									// prenom: Yup.string()
									// 	.max(255)
									// 	.required('champ obligatoire'),
									// nom: Yup.string()
									// 	.max(255)
									// 	.required('champ obligatoire'),
									mobile: Yup.string().test(
										'mobile',
										'Le numéro est invalide',
										(value) =>
											!Number.isNaN(Number(value)) &&
											value.length == 10 &&
											value[0] == '0'
									),
									email: Yup.string()
										.email("L'email est invalide")
										.max(255)
										.required('champ obligatoire'),
								})}
								onSubmit={(values) => {
									handleOpenConfirmation(item, values);
								}}>
								{({
									setFieldValue,
									values,
									errors,
									touched,
									handleChange,
									handleBlur,
									handleSubmit,
									isSubmitting,
									isValid,
								}) => (
									<Form>
										<div>
											<DialogInscription
												item={item}></DialogInscription>
											<div className="row mt-2">
												<div className="col-sm-6">
													<TextField
														error={Boolean(
															touched.mobile &&
																errors.mobile
														)}
														fullWidth
														helperText={
															touched.mobile &&
															errors.mobile
														}
														label="Mobile"
														margin="normal"
														name="mobile"
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.mobile}
														variant="outlined"
													/>
												</div>
												<div className="col-sm-6">
													<TextField
														error={Boolean(
															touched.email &&
																errors.email
														)}
														fullWidth
														helperText={
															touched.email &&
															errors.email
														}
														label="Email"
														margin="normal"
														name="email"
														onBlur={handleBlur}
														onChange={handleChange}
														value={values.email}
														variant="outlined"
													/>
												</div>
											</div>
										</div>

										{true && (
											<DialogActions>
												<Button
													// sx={{
													// 	bgcolor: color,
													// 	':hover': {
													// 		bgcolor: 'red',
													// 	},
													// }}
													startIcon={<Edit></Edit>}
													variant="contained"
													type="submit">
													Modifier
												</Button>
											</DialogActions>
										)}
									</Form>
								)}
							</Formik>
						</CardContent>
					</Container>
				</Card>
			</Dialog>

			<header style={{ width: '100%' }}>
				<div className="header-area header-style-four position-absolute w-100">
					<div className="container-fluid">
						<div className="row">
							<div className="col-xxl-2 col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-items-center d-xl-flex d-lg-block">
								<div className="nav-logo d-flex justify-content-between align-items-center">
									<Link to={'#'} onClick={scrollTop}>
										<img
											src={
												process.env.PUBLIC_URL +
												'/images/verison.png'
											}
											alt="logo"
											width={100}
											height={100}
											style={{ marginTop: -20 }}
										/>
									</Link>
									<div className="mobile-menu d-flex ">
										<div className="d-flex align-items-center">
											<div className="nav-right-icons d-xl-none d-flex align-items-center ">
												<div className="user-dropdown">
													<a
														onClick={
															user &&
															user.displayName ==
																'client'
																? handleClick
																: deconnecter
														}>
														<i className="bx bx-user-circle" />
													</a>
												</div>
												{/* <div
                          className="category-toggle"
                          onClick={handleCatagorybtn}
                        >
                          <i className="bx bx-category" />
                        </div> */}
											</div>
											<Link
												className={
													mobileSideberMenu === 1
														? 'hamburger d-block d-xl-none h-active'
														: 'hamburger d-block d-xl-none'
												}
												onClick={handleMobileSiderbar}>
												<span className="h-top" />
												<span className="h-middle" />
												<span className="h-bottom" />
											</Link>
										</div>
									</div>
								</div>
							</div>
							<div className="col-xxl-8 col-xl-9 col-lg-9 col-md-10 col-sm-10 col-xs-10">
								<nav
									className={
										mobileSideberMenu === 1
											? 'main-nav slidenav'
											: 'main-nav'
									}>
									<div className="inner-logo d-xl-none text-center">
										<Link to={`${process.env.PUBLIC_URL}/`}>
											<img
												width={'100'}
												src={
													process.env.PUBLIC_URL +
													`/images/VERISON-2023.png`
												}
												alt=""
											/>
										</Link>
									</div>
									<ul>
										<li
										// className="has-child-menu"
										>
											<Link
												to={`${process.env.PUBLIC_URL}/`}>
												<div
													style={{
														borderBottom:
															window.location.href
																.split('/')
																.at(-1) == ''
																? '3px solid #ff4838'
																: '',
													}}>
													Acceuil
												</div>
											</Link>
										</li>

										<li
											className="has-child-menu"
											onClick={() =>
												dispatch({ type: 'homeOne' })
											}>
											<Link to={'/destination'}>
												<div
													style={{
														borderBottom: [
															'destination',
															'package',
															'package-details',
														].includes(
															window.location.href
																.split('/')
																.at(-1)
														)
															? '3px solid #ff4838'
															: '',
													}}>
													{'Voyage organisé'}
												</div>
											</Link>
											<i className="fl flaticon-plus">
												+
											</i>
											<ul
												className={
													state &&
													state.activeMenu ===
														'destination'
														? 'sub-menu d-block'
														: 'sub-menu d-none'
												}>
												<li>
													<Link
														onClick={() => {
															history.replace({
																pathname:
																	'/package',
																state: {
																	type: 'national',
																	tour: false,
																},
															});
														}}>
														National
													</Link>
												</li>
												<li>
													<Link
														onClick={() => {
															history.replace({
																pathname:
																	'/package',
																state: {
																	type: 'international',
																	tour: false,
																},
															});
														}}>
														International
													</Link>
												</li>
												{user && (
													<li>
														<Link
															onClick={() => {
																history.push({
																	pathname:
																		'/mesreservations',
																});
															}}>
															Mes réservations
														</Link>
													</li>
												)}
											</ul>
										</li>

										{!user ? (
											<li>
												<Link
													onClick={() => {
														history.push({
															pathname:
																'/package',
															state: {
																type: 'omra',
																tour: false,
															},
														});
													}}>
													Omra
												</Link>
											</li>
										) : (
											<li
												className="has-child-menu"
												onClick={() =>
													dispatch({
														type: 'homeOne',
													})
												}>
												<Link
													onClick={() => {
														history.replace({
															pathname:
																'/package',
															state: {
																type: 'omra',
																tour: false,
															},
														});
													}}>
													Omra
												</Link>

												<i className="fl flaticon-plus">
													+
												</i>
												<ul
													className={
														state &&
														state.activeMenu ===
															'destination'
															? 'sub-menu d-block'
															: 'sub-menu d-none'
													}>
													<li>
														<Link
															onClick={() => {
																history.replace(
																	{
																		pathname:
																			'/package',
																		state: {
																			type: 'omra',
																			tour: false,
																		},
																	}
																);
															}}>
															Omra
														</Link>
													</li>
													<li>
														<Link
															onClick={() => {
																history.push({
																	pathname:
																		'/mesreservationsomra',
																});
															}}>
															Mes réservations
															omra
														</Link>
													</li>
												</ul>
											</li>
										)}

										{!user ? (
											<li>
												<Link to={'/visa'}>
													<div
														style={{
															borderBottom:
																window.location.href
																	.split('/')
																	.at(-1) ==
																'visa'
																	? '3px solid #ff4838'
																	: '',
														}}>
														Visa
													</div>
												</Link>
											</li>
										) : (
											<li
												className="has-child-menu"
												onClick={() =>
													dispatch({
														type: 'homeOne',
													})
												}>
												<Link to={'/visa'}>
													<div
														style={{
															borderBottom:
																window.location.href
																	.split('/')
																	.at(-1) ==
																'visa'
																	? '3px solid #ff4838'
																	: '',
														}}>
														Visa
													</div>
												</Link>

												<i className="fl flaticon-plus">
													+
												</i>
												<ul
													className={
														state &&
														state.activeMenu ===
															'destination'
															? 'sub-menu d-block'
															: 'sub-menu d-none'
													}>
													<li>
														<Link
															onClick={() => {
																history.push({
																	pathname:
																		'/visa',
																});
															}}>
															Demande visa
														</Link>
													</li>
													<li>
														<Link
															onClick={() => {
																history.push({
																	pathname:
																		'/mesvisa',
																});
															}}>
															Mes demandes visa
														</Link>
													</li>
												</ul>
											</li>
										)}

										<li>
											<Link to={'/billetterie'}>
												<div
													style={{
														borderBottom:
															window.location.href
																.split('/')
																.at(-1) ==
															'billetterie'
																? '3px solid #ff4838'
																: '',
													}}>
													Billetterie
												</div>
											</Link>
										</li>

										<li>
											<Link to={'/hotelerie'}>
												<div
													style={{
														borderBottom:
															window.location.href
																.split('/')
																.at(-1) ==
															'hotelerie'
																? '3px solid #ff4838'
																: '',
													}}>
													Hotelerie
												</div>
											</Link>
										</li>

										<li>
											<Link to={'/contact'}>
												<div
													style={{
														borderBottom:
															window.location.href
																.split('/')
																.at(-1) ==
															'contact'
																? '3px solid #ff4838'
																: '',
													}}>
													Contact
												</div>
											</Link>
										</li>

										<li>
											<Link to={'/about'}>
												<div
													style={{
														borderBottom:
															window.location.href
																.split('/')
																.at(-1) ==
															'about'
																? '3px solid #ff4838'
																: '',
													}}>
													A propos
												</div>
											</Link>
										</li>
									</ul>
								</nav>
							</div>
							<div className="col-xxl-2 col-xl-1 col-lg-1">
								<div className="nav-right d-xl-flex d-none">
									<div className="nav-right-hotline d-xxl-flex d-none">
										{user &&
											user.displayName == 'client' && (
												<div>
													<Stack
														direction="column"
														justifyContent={
															'center'
														}
														alignItems={'center'}>
														<Stack
															direction="row"
															justifyContent={
																'center'
															}
															alignItems={
																'center'
															}>
															<Paid fontSize="10"></Paid>
															<Typography variant="caption">
																<strong>
																	Solde
																</strong>
															</Typography>
														</Stack>

														<Typography variant="caption">
															<strong>
																{user &&
																	usr &&
																	usr.montant +
																		'DA'}
															</strong>
														</Typography>
													</Stack>
												</div>
											)}
									</div>
									<div className="nav-right-icons">
										{user &&
											user.displayName == 'client' && (
												<div style={{ color: 'white' }}>
													<Stack
														direction="column"
														justifyContent={
															'center'
														}
														alignItems={'center'}>
														<Stack
															direction="row"
															justifyContent={
																'center'
															}
															alignItems={
																'center'
															}>
															<Paid fontSize="10"></Paid>
															<Typography variant="caption">
																<strong>
																	Crédit
																</strong>
															</Typography>
														</Stack>

														<Typography variant="caption">
															<strong>
																{user &&
																	usr &&
																	usr.versement +
																		'DA'}
															</strong>
														</Typography>
													</Stack>
												</div>
											)}
									</div>
									<div className="nav-right-icons">
										<div className="header-search search-btn">
											<a
												onClick={
													user &&
													user.displayName == 'client'
														? handleClick
														: deconnecter
												}>
												<i className="bx bx-user-circle" />
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				<Menu
					anchorEl={anchorEl}
					id="account-menu"
					open={open}
					onClose={handleClose}
					onClick={handleClose}
					PaperProps={{
						elevation: 0,
						sx: {
							overflow: 'visible',
							filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
							mt: 1.5,
							'& .MuiAvatar-root': {
								width: 32,
								height: 32,
								ml: -0.5,
								mr: 1,
							},
							'&:before': {
								content: '""',
								display: 'block',
								position: 'absolute',
								top: 0,
								right: 14,
								width: 10,
								height: 10,
								bgcolor: 'background.paper',
								transform: 'translateY(-50%) rotate(45deg)',
								zIndex: 0,
							},
						},
					}}
					transformOrigin={{ horizontal: 'right', vertical: 'top' }}
					anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>
					<MenuItem
						onClick={
							user && user.displayName == 'client'
								? handleOpenCompte
								: deconnecter
						}>
						<ListItemIcon>
							<AccountCircle fontSize="small" />
						</ListItemIcon>
						Mon compte
					</MenuItem>
					<MenuItem
						onClick={() => {
							deconnecter();
							handleClose();
						}}>
						<ListItemIcon>
							<Logout fontSize="small" />
						</ListItemIcon>
						Déconnecter
					</MenuItem>
				</Menu>
			</header>
		</>
	);
}

export default Header;
