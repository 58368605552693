/** @format */

import { Alert, Container, Stack } from '@mui/material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { db } from '../../../Firebase';
import PackageCard from './PackageCard';

function PackageGridWrapper() {
	const scrollTop = () => {
		window.scrollTo({
			top: 0,
			behavior: 'smooth',
		});
	};

	const [listOffers, setListOffers] = useState([]);

	const location = useLocation();
	let type = '';
	let tour = '';

	const getOffres = async () => {
		setListOffers([]);
		var detailRef = null;
		var q = null;
		var querySnapshot = null;
		let tmp = [];

		if (tour) {
			detailRef = collection(db, 'destination_detail');
			q = await query(detailRef, where('tour', '==', tour));
			querySnapshot = await getDocs(q);
		} else {
			detailRef = collection(db, 'destination_detail');
			q = await query(detailRef, where('type', '==', type));
			querySnapshot = await getDocs(q);
		}

		await querySnapshot.forEach(async (doc) => {
			tmp.push({ ...doc.data(), id: doc.id });
			// doc.data() is never undefined for query doc snapshots
		});

		setListOffers(tmp);
	};

	useEffect(() => {
		(async () => {
			if (location.state != undefined) {
				type = location.state.type;
				tour = location.state.tour;
			} else {
				return history.goBack();
			}
			// scrollTop()
			getOffres();
		})();
	}, [location.state]);

	let history = useHistory();

	return (
		<>
			{/* ===============  Package gird area start =============== */}
			<div className="package-wrapper pt-110 pb-100">
				<Container>
					{listOffers.length == 0 ? (
						<Stack
							mt={5}
							mb={15}
							height="100%"
							alignItems="center"
							justifyContent="center">
							<Alert severity="error">
								Aucun programme trouvé !
							</Alert>
						</Stack>
					) : (
						<div></div>
					)}
					<div className="row g-4">
						{listOffers.map((offer, index) => (
							<div className="col-lg-4 col-md-6" key={index}>
								<Link
									onClick={() => {
										history.push({
											pathname: `${process.env.PUBLIC_URL}/package-details`,
											state: { programme: offer },
										});
									}}>
									<PackageCard
										image={offer.image}
										date={offer.duration}
										title={offer.title}
										price={offer.prixGenerale + ' DA'}
									/>
								</Link>
							</div>
						))}
					</div>
					{/* <Pagination /> */}
				</Container>
			</div>
			{/* ===============  Package gird area end =============== */}
		</>
	);
}

export default PackageGridWrapper;
