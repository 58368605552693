import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { Alert, Box, Button, Card, CardContent, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, Input, MenuItem, Tab, Tabs, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useEffect } from 'react';
import * as Yup from 'yup';
import { cleanData, db, storage } from '../../../../Firebase';

import { FilterList, ListAlt, Public, Refresh, Upload, UploadFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from 'firebase/storage';
import { useState } from 'react';
import DialogVisa from '../../Visa/DialogVisa';
import ListPays from './ListPays';

function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}



function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}



export default function AdminVisa() {

    const [color, setAge] = React.useState('#ff4838');

    const [actions, setactions] = useState();

    const columns = [
        // {
        //     field: 'confirmed',
        //     align: "center",
        //     headerName: 'Confirmé',
        //     width: 90,
        //     renderCell: (cell) => {
        //         return (
        //             <div>
        //                 {
        //                     cell.row.confirmed ?
        //                         <Recommend color='success'></Recommend>
        //                         :
        //                         <AccessAlarm color='warning'></AccessAlarm>

        //                 }
        //             </div>
        //         );
        //     }
        // },
        { field: 'nom_agence', headerName: 'Agence', width: 180 },
        { field: 'nom', headerName: 'Nom prénom', width: 200 },
        // {
        //     field: 'age',
        //     headerName: 'Age',
        //     width: 80,
        // },

        { field: 'date', headerName: 'Date', width: 180 },

        // { field: 'expiration_passport', headerName: 'Expiration passport', width: 150 },
        {
            field: 'visa', headerName: 'Visa', width: 200, renderCell: (cell) => {
                return (
                    <div>{cell.row.pays} - {cell.row.type}</div>
                )
            }
        },
        {
            field: 'total', headerName: 'Total (DA)', width: 120
        },
        {
            field: 'etat',
            align: "center",
            headerName: 'Etat',
            width: 180,
            renderCell: (cell) => {
                return (
                    cell.row.etat == "done" ?
                        <div value={"done"}><CheckCircleIcon color={"success"}></CheckCircleIcon><strong> Confirmé</strong> </div>
                        : cell.row.etat == "waiting" ?
                            <div value={"waiting"}><HourglassTopIcon color={"warning"}></HourglassTopIcon><strong> En traitement</strong> </div>
                            :
                            <div value={"cancelled"}><DoDisturbOnIcon color="error"></DoDisturbOnIcon><strong> Annulé</strong></div>
                );
            }
        },

    ];

    const [rows, setRows] = React.useState([

    ]);

    const getData = async () => {
        setRows([])
        await getDocs(collection(db, "visa"))
            .then((querySnapshot) => {
                const newData = cleanData(querySnapshot)
                setRows(newData)
            }).catch((err) => {
                console.log(err);
            })
    }

    const [filter, setfilter] = React.useState(false);
    const [destinations, setdestinations] = React.useState([]);
    const [agences, setagences] = useState([]);
    const getDestinations = async (e) => {
        setdestinations([])

        await getDocs(collection(db, "listvisa"))
            .then((querySnapshot) => {
                const newData = cleanData(querySnapshot)
                setdestinations(newData)
            }).catch((err) => {
                console.log(err);
            })

    };

    const getAgences = async (e) => {
        setagences([])

        await getDocs(collection(db, "inscription"))
            .then((querySnapshot) => {
                const newData = cleanData(querySnapshot)
                setagences(newData)
            }).catch((err) => {
                console.log(err);
            })

    };

    const handleFilter = async (values) => {
        setRows([])

        var detailRef = null;
        var q = null
        var querySnapshot = null;
        let tmp = []

        let name = values.name.toLowerCase()
        let agence = values.agence
        let destination = values.destination

        if (values.destination == "") {
            if (agence == "") {
                detailRef = collection(db, "visa");
                q = await query(detailRef, where("nom", name == "" ? ">=" : "==", name))
                querySnapshot = await getDocs(q);
            } else {
                detailRef = collection(db, "visa");
                q = await query(detailRef, where("nom_agence", "==", agence), where("nom", name == "" ? ">=" : "==", name))
                querySnapshot = await getDocs(q);
            }

        } else {
            if (agence == "") {
                detailRef = collection(db, "visa");
                q = await query(detailRef, where("pays", "==", values.pays), where("type", "==", values.visa.type), where("nom", ">=", name))
                querySnapshot = await getDocs(q);
            } else {
                detailRef = collection(db, "visa");
                q = await query(detailRef, where("nom_agence", "==", agence), where("pays", "==", values.pays), where("type", "==", values.visa.type), where("nom", ">=", name))
                querySnapshot = await getDocs(q);
            }
        }



        await querySnapshot.forEach(async (doc) => {
            tmp.push({ ...doc.data(), id: doc.id })
            // doc.data() is never undefined for query doc snapshots
        });

        setRows(tmp)


    }

    const editData = async () => {

        setdone(true)
        let itm = item
        let values = confirmationValues
        let pdfUrl = item.pdf
        let promises = []
        let waiting = ""
        let snapshotRef = null

        try {
            if (values.pdf != "") {
                let imageRef = ref(storage, "images/demande_visa/" + item.id + "/" + "pdf")

                promises.push(
                    uploadBytes(imageRef, values.pdf).then((snapshot) => {
                        snapshotRef = snapshot.ref
                    })
                )
                waiting = await Promise.all(promises);
                promises.push(
                    getDownloadURL(snapshotRef).then((downloadURL) => {
                        pdfUrl = downloadURL
                    })
                )
            }
        }
        catch (e) {
            console.log(e);
        }
        waiting = await Promise.all(promises);

        try {
            const docRef = await updateDoc(doc(db, "visa", itm.id), {
                etat: values.etat,
                pdf: pdfUrl
            });
            setChange(false)
            getData()
            setdone(false)
            handleCloseConfirmation()
            handleClose()
        } catch (e) {
            setChange(false)
            setdone(false)
            handleCloseConfirmation()
            alert(e)
        }


    }



    useEffect(() => {
        getData()
        getDestinations()
        getAgences()
    }, [])



    // dialog
    const [item, setItem] = React.useState({});
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setChange(false)
        setOpen(true)
    };
    const handleClose = () => setOpen(false);

    //supprimer
    const [loadingDelete, setloadingDelete] = useState(false);
    const [supmsg, setsupmsg] = React.useState(false);
    const handleOpensupmsg = () => setsupmsg(true);
    const handleClosesupmsg = () => setsupmsg(false);

    const supprimer = async () => {
        setloadingDelete(true)
        try {

            const folderRef = ref(storage, 'images/demande_visa/' + item.id);
            await listAll(folderRef)
                .then(dir => {
                    dir.items.forEach(fileRef => {
                        const desertRef = ref(storage, fileRef.fullPath);
                        deleteObject(desertRef).catch(error => alert(error))
                    }
                    );
                })
                .catch(error => alert(error));


            getData()
            handleClosesupmsg()
            handleClose()

        } catch (e) {

            console.log("echec !! " + e);
        }
        try {
            await deleteDoc(doc(db, "visa", item.id));
            let tmp = [...rows]
            const newtmp = tmp.filter(e => e.id != item.id)
            setRows(newtmp)
            handleClosesupmsg()
            handleClose()
            setloadingDelete(false)

        } catch (e) {
            setloadingDelete(false)
            console.log("error!");
        }
        setloadingDelete(false)

    }
    /////////////////////////////////////////////////////////////////////////////////////////////////////////////////////////
    // confirmation
    const [done, setdone] = useState(false);
    const [confirmationValues, setconfirmationValues] = useState();
    const [confirmation, setconfirmation] = React.useState(false);
    const handleOpenConfirmation = (item, values) => {
        setconfirmationValues(values);
        setconfirmation(true);
    };
    const handleCloseConfirmation = () => setconfirmation(false);
    const [change, setChange] = React.useState(false);
    const [newValue, setnewValue] = useState();


    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
        if (newValue == 0) {
            getData()
        }

    };

    return (
        <Container sx={{ width: '100%' }}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
                    <Tab icon={<ListAlt></ListAlt>} iconPosition="start" label="Demandes visa" {...a11yProps(0)} />
                    <Tab icon={<Public></Public>} iconPosition="start" label="List des pays" {...a11yProps(1)} />

                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>

                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={confirmation}
                    onClose={handleCloseConfirmation}>
                    <DialogTitle>
                        <strong>Confirmation</strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            êtes-vous sûr de vouloir confirmer cette modification ?{' '}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button
                            variant="outlined"
                            autoFocus
                            color="inherit"
                            onClick={handleCloseConfirmation}>
                            Annuler
                        </Button>
                        <LoadingButton loading={done} variant="contained" onClick={editData}>
                            Confirmer
                        </LoadingButton>
                    </DialogActions>
                </Dialog>


                <Dialog
                    fullWidth
                    maxWidth="sm"
                    open={supmsg}
                    onClose={handleClosesupmsg}
                >
                    <DialogTitle>
                        <strong>
                            Supprimer
                        </strong>
                    </DialogTitle>
                    <DialogContent>
                        <DialogContentText>
                            êtes-vous sûr de vouloir supprimer cette visa ? <br />
                            Agence :
                            <strong>
                                {item.nom_agence}
                            </strong><br />
                            Nom et prénom :
                            <strong>
                                {item.nom}
                            </strong>
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" autoFocus color="inherit" onClick={handleClosesupmsg}>
                            Annuler
                        </Button>
                        <LoadingButton loading={loadingDelete} variant="contained" style={{ backgroundColor: "#d50000" }} onClick={supprimer} startIcon={<DeleteIcon />}>
                            Supprimer
                        </LoadingButton>
                    </DialogActions>

                </Dialog>

                <Dialog
                    scroll='body'
                    fullWidth
                    maxWidth="md"
                    open={open}
                    onClose={handleClose}
                >
                    <Card>

                        <Container sx={{ mt: 3 }}>
                            <Stack justifyContent={"space-between"} direction="row" alignItems={"center"}>
                                <Typography variant='h4'>
                                    <strong>
                                        <Stack
                                            spacing={3}
                                            direction="row"
                                            alignItems="center"
                                        >
                                            <div>
                                                Informations
                                            </div>

                                        </Stack>

                                    </strong>
                                </Typography>
                                <Button variant="contained" style={{ backgroundColor: "#d50000" }} onClick={handleOpensupmsg} startIcon={<DeleteIcon />}>
                                    Supprimer
                                </Button>

                            </Stack>
                            <CardContent>
                                <DialogVisa data={item}></DialogVisa>
                                <Divider
                                    sx={{ mt: 3, mb: 2, color: color, borderBottomWidth: 3 }}
                                    variant="middle"
                                ></Divider>

                                <div className='row mt-2'>
                                    <Formik
                                        initialValues={{
                                            etat: item.etat,
                                            pdf: ""
                                        }}
                                        validationSchema={
                                            Yup.object().shape({

                                            })
                                        }
                                        onSubmit={(values) => {
                                            handleOpenConfirmation(item, values)
                                        }}
                                    >
                                        {({

                                            setFieldValue,
                                            values,
                                            errors,
                                            touched,
                                            handleChange,
                                            handleBlur,
                                            handleSubmit,
                                            isSubmitting, isValid
                                        }) => (
                                            <Form>
                                                <TextField
                                                    select
                                                    fullWidth
                                                    label="Etat"
                                                    name="etat"
                                                    onChange={(event, value) => {
                                                        setnewValue(event.target.value)
                                                        setFieldValue("etat", event.target.value)
                                                        setChange(true)
                                                    }}
                                                    value={values.etat}
                                                    variant="outlined"
                                                >
                                                    <MenuItem value={"done"}><CheckCircleIcon color={"success"}></CheckCircleIcon> Confirmé</MenuItem>
                                                    <MenuItem value={"waiting"}><HourglassTopIcon color={"warning"}></HourglassTopIcon>  En traitement</MenuItem>
                                                    <MenuItem value={"cancelled"}><DoDisturbOnIcon color="error"></DoDisturbOnIcon> Annulé</MenuItem>

                                                </TextField>
                                                {
                                                    values.etat == "done" &&
                                                    <div>
                                                        <Divider
                                                            sx={{ mt: 3, mb: 2, color: color, borderBottomWidth: 3 }}
                                                            variant="middle"
                                                        ></Divider>
                                                        <Typography variant="h6" >
                                                            <strong>
                                                                E-Visa
                                                            </strong>

                                                        </Typography>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            spacing={2}
                                                        >

                                                            <label htmlFor="button-file">
                                                                <Button variant="contained" component="span" size="large" startIcon={<Upload />}>
                                                                    <Input
                                                                        id="button-file"
                                                                        style={{ display: "none" }}
                                                                        onChange={value => {
                                                                            setFieldValue("pdf", value.currentTarget.files[0]);
                                                                            setChange(true)
                                                                        }

                                                                        }
                                                                        name="pdf"
                                                                        accept=".pdf"
                                                                        type="file"
                                                                        inputProps={{
                                                                            accept: '.pdf',
                                                                        }}
                                                                    />
                                                                    Importer
                                                                </Button>
                                                            </label>
                                                            <div>
                                                                {
                                                                    values.pdf == "" ? "" : <div>
                                                                        <Button variant="contained" color="error" endIcon={<DeleteIcon />}
                                                                            onClick={() => setFieldValue("pdf", "")}
                                                                        >
                                                                            Supprimer
                                                                        </Button>

                                                                    </div>
                                                                }
                                                            </div>
                                                            <div>
                                                                {
                                                                    item.pdf != "" && values.pdf == "" &&
                                                                    <div>
                                                                        <a
                                                                            style={{ paddingLeft: 30 }}
                                                                            href={item?.pdf}
                                                                            rel="noopener noreferrer"
                                                                            target="_blank"
                                                                            download
                                                                        >
                                                                            <UploadFile></UploadFile>
                                                                            Téléchager E-Visa
                                                                        </a>
                                                                    </div>
                                                                }
                                                                {
                                                                    values.pdf != "" && < div >

                                                                        <strong>
                                                                            {" " + values.pdf.name}
                                                                        </strong>
                                                                    </div>
                                                                }
                                                            </div>

                                                        </Stack>


                                                    </div>
                                                }
                                                {
                                                    change && (
                                                        <DialogActions>
                                                            <Button
                                                                variant='contained'
                                                                type="submit"

                                                            >Modifier</Button>
                                                        </DialogActions>
                                                    )
                                                }
                                            </Form>)}
                                    </Formik>


                                </div>
                            </CardContent>

                        </Container>
                    </Card >
                </Dialog >

                <Stack
                    direction={"row"}
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant='h3' mb={2}>
                        <strong>
                            <svg xmlns="http://www.w3.org/2000/svg" width="36" height="36" fill="currentColor" className="bi bi-globe-europe-africa" viewBox="0 0 16 16">
                                <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.809.809 0 0 1 1.034-.275.809.809 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34c.078.028.16.044.243.054.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.333.333 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501Z" />
                            </svg> Visa
                        </strong>
                    </Typography>
                    <Button onClick={() => setfilter(!filter)} style={{ backgroundColor: color }} variant='contained' endIcon={<FilterList ></FilterList>}>filter</Button>

                </Stack>
                <Collapse in={filter} sx={{ mb: 2 }}>
                    <Card>

                        <CardContent>
                            <Typography variant='h6' mb={2}>
                                <strong>
                                    <i style={{ color: color }} className="bi bi-search"></i> Filter
                                </strong>
                            </Typography>

                            <Formik
                                initialValues={{
                                    agence: "",
                                    name: "",
                                    destination: "",
                                    visa: {},
                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        // agence: Yup.string().max(255).required('champ obligatoire'),
                                        // name: Yup.string().max(255).required('champ obligatoire'),
                                    }

                                    )
                                }
                                onSubmit={(values) => {
                                    handleFilter(values)
                                }}
                            >
                                {({

                                    setFieldValue,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting, isValid
                                }) => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <TextField
                                                    // disabled={values.name != ""}
                                                    error={Boolean(touched.agence && errors.agence)}
                                                    fullWidth
                                                    helperText={touched.agence && errors.agence}
                                                    label="Agence"
                                                    margin="normal"
                                                    name="agence"
                                                    onBlur={handleBlur}
                                                    onChange={(
                                                        event,
                                                        value
                                                    ) => {
                                                        setFieldValue(
                                                            'agence',
                                                            event.target
                                                                .value
                                                        );
                                                    }}
                                                    value={values.agence}
                                                    variant="outlined"
                                                    select
                                                >
                                                    {

                                                        agences.map((agence, index) => {

                                                            return (
                                                                <MenuItem key={index} value={agence.nom_agence}>
                                                                    {agence.nom_agence}
                                                                </MenuItem>)
                                                        })
                                                    }
                                                </TextField>


                                            </div>
                                            <div className="col-sm-6">
                                                <TextField
                                                    // disabled={values.agence != ""}

                                                    error={Boolean(touched.name && errors.name)}
                                                    fullWidth
                                                    helperText={touched.name && errors.name}
                                                    label="Nom client"
                                                    margin="normal"
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    variant="outlined"

                                                >

                                                </TextField>

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <TextField
                                                    error={Boolean(touched.destination && errors.destination)}
                                                    fullWidth
                                                    helperText={touched.destination && errors.destination}
                                                    label="Destination"
                                                    margin="normal"
                                                    name="destination"
                                                    onBlur={handleBlur}
                                                    onChange={(
                                                        event,
                                                        value
                                                    ) => {
                                                        setFieldValue(
                                                            'destination',
                                                            event.target
                                                                .value
                                                        );

                                                    }}
                                                    value={values.destination}



                                                    variant="outlined"
                                                    select
                                                >
                                                    {

                                                        destinations.map((destination, index) => {

                                                            return (
                                                                <MenuItem key={index} value={destination.id} onClick={() => {
                                                                    setFieldValue(
                                                                        'visa',
                                                                        destination
                                                                    );
                                                                }}>
                                                                    <Stack
                                                                        direction="row"
                                                                        alignItems="center"
                                                                        justifyContent="start"
                                                                        spacing={4}
                                                                    >
                                                                        <img src={process.env.PUBLIC_URL + destination.flag} alt="images" width={50} />
                                                                        <Typography variant="h6">
                                                                            <strong>
                                                                                {destination.pays}
                                                                            </strong>
                                                                        </Typography>
                                                                        <Typography variant="h6">
                                                                            {destination.type}
                                                                        </Typography>
                                                                    </Stack>

                                                                </MenuItem>)
                                                        })
                                                    }

                                                </TextField>

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className="col-sm-6">

                                                <Button type='submit' style={{ backgroundColor: color }} variant='contained'>Appliquer</Button>
                                                <IconButton onClick={() => {
                                                    setFieldValue("agence", "")
                                                    setFieldValue("name", "")
                                                    setFieldValue("destination", "")
                                                    setFieldValue("visa", "")
                                                    getData()
                                                }}>
                                                    <Refresh></Refresh>
                                                </IconButton>
                                            </div>
                                        </div>
                                    </Form>)}
                            </Formik>
                        </CardContent>

                    </Card>
                </Collapse>


                <DataGrid
                    sx={{ mb: 2, height: 630 }}
                    disableColumnMenu
                    disableColumnFilter
                    rows={rows}
                    columns={columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    onCellClick={(cell) => {
                        setItem(cell.row)
                        handleOpen()
                    }}

                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                <Alert severity="error">
                                    Aucune visa trouvé !
                                </Alert>
                            </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Alert severity="error">
                                Aucune visa trouvé !
                            </Alert>
                        )
                    }}

                />
            </TabPanel >
            <TabPanel value={value} index={1}>
                <ListPays></ListPays>

            </TabPanel>

        </Container >

    );
}