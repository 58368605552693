import React, { useEffect } from 'react'

import { Link } from 'react-router-dom'
// import Swiper core and required modules
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { EffectFade, Navigation, Pagination } from "swiper";
import Loading from '../../common/Loading';
import { useState } from 'react';

export default function Billetterie() {
    const hero3Silde = {
        slidesPerView: 1,
        speed: 1500,
        parallax: true,
        loop: true,
        spaceBetween: 0,
        centeredSlides: true,
        roundLengths: true,
        effect: 'fade',
        navigation: {
            nextEl: '.hero-next3',
            prevEl: '.hero-prev3',
        },

        autoplay: {
            delay: 5000
        },
        pagination: {
            el: ".hero-two-pagination",
            clickable: true,
            renderBullet: function (index, className) {
                return '<span class="' + className + '">' + 0 + (index + 1) + "</span>";
            }
        }
    }
    const [load, setLoad] = useState(false);
    useEffect(() => {
        setLoad(true);
        setTimeout(() => {
            setLoad(false);
        }, 500);
    }, []);
    return (


        <>
            {load ? (
                <Loading />
            ) : (
                <div className="hero-area hero-style-three">
                    <img src={process.env.PUBLIC_URL + "/images/banner/banner-plane.svg"} className="img-fluid banner-plane" alt='banners-img' />
                    <div className="hero-main-wrapper position-relative">

                        <Swiper className="swiper  hero-slider-three " {...hero3Silde}>
                            <div className="swiper-wrapper" >
                                <SwiperSlide className="swiper-slide">
                                    <div className="slider-bg-11">
                                        <div className="container">
                                            <div className="row d-flex justify-content-center align-items-center">
                                                <div className="col-lg-8">
                                                    <div className="hero3-content">
                                                        <span className="title-top">Bienvenue chez Verison</span>
                                                        <h1>Billetterie à venir</h1>
                                                        <p> Il m'est agréable de vous présenter, notre agence <strong style={{ color: "#ff4838" }}>«VERISON BOOKING»</strong>. Dont la vocation est
                                                            de transformer chaque client en hôte privilégié et chaque étape de son séjour en souvenir
                                                            inoubliable.</p>
                                                        <Link to={"/"} className="button-fill-primary banner3-btn">Réserver maintenant</Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </SwiperSlide>


                            </div>
                        </Swiper>

                    </div>
                </div>
            )}
        </>

    )
}
