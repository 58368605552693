import { Logout } from "@mui/icons-material";
import { Badge, ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { collection, getCountFromServer, query, where } from "firebase/firestore";
import React, { useEffect, useReducer, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { Link } from "react-router-dom";
import { db } from "../../../Firebase";
import { UserAuth } from "../../context/UserContext";

function AdminHeader() {
    const [isSearchActive, setSearchActive] = useState(false);
    const [selectedOption, setSelectedOption] = useState(null);
    const [startDate, setStartDate] = useState(new Date());
    const [isCatagoryActive, setCatagoryActive] = useState(false);
    const [isOpenActive, setOpenActive] = useState(false);

    /*useing reducer to manage the active or inactive menu*/

    const [countVisa, setcountVisa] = useState(0);
    const [countReservation, setcountReservation] = useState(0);
    const [countInscription, setcountInscription] = useState(0);

    const getCountVisa = async () => {
        const coll = collection(db, "visa");
        const q = query(coll, where("etat", "==", "waiting"));
        const snapshot = await getCountFromServer(q);
        setcountVisa(snapshot.data().count)
    };

    const getCountInscription = async () => {
        const coll = collection(db, "inscription");
        const q = query(coll, where("etat", "==", "waiting"));
        const snapshot = await getCountFromServer(q);
        setcountInscription(snapshot.data().count)
    };

    const getCountReservation = async () => {
        const coll = collection(db, "reservation");
        const q = query(coll, where("etat", "==", "waiting"));
        const snapshot = await getCountFromServer(q);
        setcountReservation(snapshot.data().count)
    };

    useEffect(() => {
        // const timer = setInterval(() => {
        //     getCountVisa()
        //     getCountInscription()
        //     getCountReservation()
        // }, 60000);
        // return () => clearInterval(timer)

        getCountVisa()
        getCountInscription()
        getCountReservation()

        // // Add scroll event when the component is loaded
        // window.addEventListener('scroll', isSticky);
        // return () => {
        //     window.removeEventListener('scroll', isSticky);
        // };


    });
    /* Method that will fix header after a specific scrollable */
    const isSticky = (e) => {
        const header = document.querySelector('header');
        const scrollTop = window.scrollY;
        scrollTop >= 200 ? header.classList.add('sticky') : header.classList.remove('sticky');
    };


    const initialState = { activeMenu: "" };
    const [state, dispatch] = useReducer(reducer, initialState);

    function reducer(state, action) {
        switch (action.type) {
            case "inscriptions":
                return { activeMenu: "inscriptions" };
            case "demandes":
                return { activeMenu: "demandes" };
            case "destination":
                return { activeMenu: "destination" };
            case "visa":
                return { activeMenu: "visa" };
            default:
                return { activeMenu: " " };
        }
    }

    const handleSearcghBtn = () => {
        // if (isSearchActive === false || isSearchActive === 0) {
        //     setSearchActive(1);
        // } else {
        //     setSearchActive(false);
        // }
        logout()
        window.location.href = `${process.env.PUBLIC_URL}/`
    };

    const handleCatagorybtn = () => {
        if (isCatagoryActive === false || isCatagoryActive === 0) {
            setCatagoryActive(1);
        } else {
            setCatagoryActive(false);
        }
    };
    const handleOpenBtn = () => {
        if (isOpenActive === false || isOpenActive === 0) {
            setOpenActive(1);
        } else {
            setOpenActive(false);
        }
    };

    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    const optionsForDestination = [
        { value: "1", label: "India" },
        { value: "2", label: "Sri Lanka" },
        { value: "3", label: "Solt Lake" },
        { value: "4", label: " Kolkata" },
        { value: "6", label: " Mohania" },
        { value: "7", label: " Mumbai" },
        { value: "8", label: " New Delhi" },
    ];
    const optionsForTravel = [
        { value: "1", label: "Adventure Tour" },
        { value: "2", label: "Group Tour" },
        { value: "3", label: "Couple Tour" },
        { value: "4", label: "Sea Beach" },
        { value: "5", label: "Mountain Tour" },
    ];
    const optionsForPerson = [
        { value: "1", label: "01" },
        { value: "2", label: "02" },
        { value: "3", label: "03" },
        { value: "4", label: "04" },
        { value: "5", label: "05" },
    ];

    const { user, logout } = UserAuth();

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <>



            <header>
                <div className="header-area header-style-one">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-2 col-lg-12 col-md-12 col-sm-12 col-xs-12 align-items-center d-xl-flex d-lg-block">
                                <div className="logo d-flex justify-content-between align-items-center">
                                    <Link onClick={scrollTop} to={`${process.env.PUBLIC_URL}/`}>
                                        <img
                                            src={process.env.PUBLIC_URL + "/images/VERISON-2023.png"}
                                            alt="logo"
                                            width={120}
                                            height={100}
                                            style={{ marginTop: -10 }}
                                        />
                                    </Link>

                                    <div className="d-flex align-items-center gap-4">
                                        <div className="nav-right d-xl-none">
                                            <ul className="nav-actions">

                                                <li className="search-toggle" onClick={handleClick}>

                                                    <i className="bi bi-person-circle"></i>
                                                </li>

                                            </ul>
                                        </div>
                                        <div
                                            className="mobile-menu d-flex "
                                            onClick={handleOpenBtn}
                                        >
                                            <Link
                                                className={
                                                    isOpenActive
                                                        ? "hamburger d-block d-xl-none h-active"
                                                        : "hamburger d-block d-xl-none"
                                                }
                                                to={"#"}
                                            >
                                                <span className="h-top" />
                                                <span className="h-middle" />
                                                <span className="h-bottom" />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-xl-8 col-lg-8 col-md-8 col-sm-6 col-xs-6">
                                <nav
                                    className={
                                        isOpenActive
                                            ? "main-nav float-end slidenav"
                                            : "main-nav float-end"
                                    }
                                >
                                    <div className="logo d-xl-none text-center">
                                        <Link to={"#"}>
                                            <img
                                                width={100}
                                                src={process.env.PUBLIC_URL + "/images/VERISON-2023.png"}
                                                alt="catagoryIMG"
                                            />
                                        </Link>
                                    </div>
                                    <ul >


                                        <li onClick={() => dispatch({ type: 'destination' })}>
                                            <Link to={"/admin/destinations"} style={{ color: state.activeMenu == "destination" ? "#ff4838" : "black" }}><i className="bi bi-arrow-up-right-circle-fill"></i> Destination</Link>

                                        </li>
                                        <li onClick={() => dispatch({ type: 'demandes' })}>
                                            <Link to={"/admin/demandes"} style={{ color: state.activeMenu == "demandes" ? "#ff4838" : "black" }}>

                                                <Badge max={999} badgeContent={countReservation} color="primary" sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15, bgcolor: '#ff4838' } }}>
                                                    <Stack
                                                        direction="row"
                                                        spacing={0.5}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >
                                                        <i className="bi bi-file-diff-fill"></i>
                                                        <Typography>
                                                            Réservations
                                                        </Typography>
                                                    </Stack>
                                                </Badge>
                                            </Link>

                                        </li>
                                        <li onClick={() => dispatch({ type: 'inscriptions' })}>
                                            <Link to={"/admin/inscriptions"} style={{ color: state.activeMenu == "inscriptions" ? "#ff4838" : "black" }}>
                                                <Badge max={999} badgeContent={countInscription} color="primary" sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15, bgcolor: '#ff4838' } }}>
                                                    <Stack
                                                        direction="row"
                                                        spacing={0.5}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >

                                                        <i className="bi bi-people-fill"></i>
                                                        <Typography>
                                                            Inscriptions
                                                        </Typography>
                                                    </Stack>
                                                </Badge>
                                            </Link>

                                        </li>
                                        <li onClick={() => dispatch({ type: 'visa' })}>
                                            <Link to={"/admin/demandevisa"} style={{ color: state.activeMenu == "visa" ? "#ff4838" : "black" }}>
                                                <Badge max={999} badgeContent={countVisa} color="primary" sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15, bgcolor: '#ff4838' } }}>
                                                    <Stack
                                                        direction="row"
                                                        spacing={0.5}
                                                        alignItems="center"
                                                        justifyContent="center"
                                                    >

                                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-globe-europe-africa" viewBox="0 0 16 16">
                                                            <path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.809.809 0 0 1 1.034-.275.809.809 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34c.078.028.16.044.243.054.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.333.333 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501Z" />
                                                        </svg>
                                                        <Typography>
                                                            Visa
                                                        </Typography>
                                                    </Stack>
                                                </Badge>
                                            </Link>


                                        </li>




                                    </ul>

                                </nav>
                            </div>
                            <div className="col-xl-2">
                                <div className="nav-right float-end d-xl-flex d-none ">
                                    <ul className="nav-actions">
                                        {/* <li className="category-toggle" onClick={handleCatagorybtn}>
                      <i className="bx bx-category" />
                    </li> */}

                                        <li className="search-toggle" onClick={handleClick}>

                                            <i className="bi bi-person-circle"></i>
                                        </li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            {/* ===============  header area end =============== */}
            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,
                            ml: -0.5,
                            mr: 1,
                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            right: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}>

                <MenuItem
                    onClick={() => {
                        handleClose();
                        handleSearcghBtn()
                    }}>
                    <ListItemIcon>
                        <Logout fontSize="small" />
                    </ListItemIcon>
                    Déconnecter
                </MenuItem>
            </Menu>
        </>
    );
}

export default AdminHeader;
