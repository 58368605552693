/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

function ErrorWrapper() {
	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	return (
		<>
			<div className="error-wrapper pt-110">
				<div className="container">
					<div className="row justify-content-center">
						<div className="col-lg-8">
							<div className="error-content text-center">
								<div className="error-vactor text-center">
									<img
										src={
											process.env.PUBLIC_URL +
											'/images/shapes/error-vactor.png'
										}
										alt="ErrorIMG"
										className="img-fluid"
									/>
								</div>
								<div className="error-text">
									<h2>Cette page est introuvable !</h2>

									<div className="error-btn">
										<Link
											to={`${process.env.PUBLIC_URL}/`}
											onClick={scrollTop}>
											<i className="bi bi-house-door" />{' '}
											Accueil
										</Link>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default ErrorWrapper;
