import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import { AccessAlarm, Recommend } from '@mui/icons-material';
import { Alert } from '@mui/material';
import { gridVisibleSortedTopLevelRowEntriesSelector } from '@mui/x-data-grid';

function getConfirmed(params) {
    if (params.row.etat == "done")
        return 3

    let splitDate = params.row.date.split("-");
    splitDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0]
    let pastTime = new Date(splitDate)
    const now = new Date();

    const thirtyDaysInMs = 3 * 24 * 60 * 60 * 1000;
    const timeDiffInMs = now.getTime() - pastTime.getTime();

    let bool = timeDiffInMs >= thirtyDaysInMs

    if (bool)
        return 1

    return 2

}

export let table_columns = [
    {
        field: 'confirmed',
        align: "center",
        headerName: 'Confirmé',
        width: 120,
        valueGetter: getConfirmed,
        renderCell: (cell) => {
            let bool = getConfirmed(cell)


            return (
                <div>
                    {
                        bool == 3 ?
                            <Recommend color='success'></Recommend>
                            :
                            bool == 1 ?
                                <div value={"cancelled"}><DoDisturbOnIcon color="error"></DoDisturbOnIcon></div>
                                :
                                <AccessAlarm color='warning'></AccessAlarm>
                    }
                </div>
            );
        }
    },
    { field: 'nom_agence', headerName: 'Agence', width: 150 },
    { field: 'nom', headerName: 'Nom prénom', width: 150 },
    { field: 'phone', headerName: 'Téléphone', width: 150 },
    // { field: 'passport', headerName: 'Num Passport', width: 180 },
    {
        field: 'date', headerName: 'Date', width: 150,
        renderCell: (cell) => {
            const pastTime = new Date(cell.row.date);
            const now = new Date();

            const thirtyDaysInMs = 3 * 24 * 60 * 60 * 1000;

            const timeDiffInMs = now.getTime() - pastTime.getTime();
            let bool = timeDiffInMs >= thirtyDaysInMs && cell.row.etat != "done" && cell.row.etat != "cancelled"
            return (


                bool ?
                    <Alert icon={false} severity={'error'}
                        sx={{ width: "100%" }}
                    >
                        {cell.row.date}
                    </Alert>
                    :
                    <div>
                        {cell.row.date}
                    </div>
            );
        }
    },
    { field: 'type', headerName: 'Type', width: 150 },
    { field: 'destination', headerName: 'Destination', width: 150 },
    { field: 'total', headerName: 'Total (DA)', width: 150 },
    // { field: 'expiration_passport', headerName: 'Expiration passport', width: 150 },
    {
        field: 'etat',
        align: "center",
        headerName: 'Etat',
        width: 180,
        renderCell: (cell) => {
            return (
                cell.row.etat == "done" ?
                    <div value={"done"}><CheckCircleIcon color={"success"}></CheckCircleIcon><strong> Confirmé</strong> </div>
                    : cell.row.etat == "waiting" ?
                        <div value={"waiting"}><HourglassTopIcon color={"warning"}></HourglassTopIcon><strong> En traitement</strong> </div>
                        :
                        <div value={"cancelled"}><DoDisturbOnIcon color="error"></DoDisturbOnIcon><strong> Annulé</strong></div>
            );
        }
    },

]