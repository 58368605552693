import React from "react";
import { Link } from "react-router-dom";

function AdminDestinationCart(props) {
    const scrollTop = () => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    };
    return (
        <>
            <div className="destination-card-style-two mb-0 ">
                <div className="d-card-thumb">
                    <img src={props.image} alt="CartIMG" style={{ height: 500 }} />
                </div>
                <div className="d-card-content" style={{
                    backgroundColor: 'rgba(0,0,0,0.6)',
                    p: 3
                }}>
                    <h4 className="destination-title">
                        {" "}

                        {props.palce}
                    </h4>
                    <div className="place-count">
                        <span>{" "}</span> <br />
                    </div>
                </div>
            </div>
        </>
    );
}

export default AdminDestinationCart;
