import { createContext, useContext, useEffect, useState } from 'react';
import {
    createUserWithEmailAndPassword,
    signInWithEmailAndPassword,
    signOut,
    onAuthStateChanged,
    updateEmail,
} from 'firebase/auth';
import { auth, db } from '../../Firebase';
import { collection, getDocs, query, where } from 'firebase/firestore';

const UserContext = createContext();

export const AuthContextProvider = ({ children }) => {
    const [user, setUser] = useState(null);
    const [usr, setUsr] = useState({});



    const signIn = (email, password) => {
        return signInWithEmailAndPassword(auth, email, password)
    }

    const logout = () => {
        sessionStorage.removeItem("verison")
        sessionStorage.removeItem("verison_dt")

        return signOut(auth)
    }

    const getUsr = async (e) => {
        var detailRef = null;
        var q = null
        var querySnapshot = null;
        if (user) {
            detailRef = collection(db, "inscription");
            q = await query(detailRef, where("uid", "==", user.uid))
            querySnapshot = await getDocs(q);
        }

        await querySnapshot.forEach(async (doc) => {

            let data = {
                ...doc.data()
            }
            data.password = ""
            setUsr(data)
            sessionStorage.setItem("verison_dt", JSON.stringify(data))


        });
    };


    const updateMail = async (email) => {

        await updateEmail(auth.currentUser, email).then((resp) => {


        }).catch((error) => {
            // logout()
        });
    }


    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (currentUser) => {


            setUser(currentUser);

            sessionStorage.setItem("verison", JSON.stringify(currentUser))
            var detailRef = null;
            var q = null
            var querySnapshot = null;

            if (currentUser) {
                detailRef = collection(db, "inscription");
                q = await query(detailRef, where("uid", "==", currentUser.uid))
                querySnapshot = await getDocs(q);
                await querySnapshot.forEach(async (doc) => {
                    let data = {
                        ...doc.data()
                    }
                    data.password = ""
                    setUsr(data)
                    sessionStorage.setItem("verison_dt", JSON.stringify(data))
                });
            }


        });
        return () => {
            unsubscribe();
        };
    }, []);

    return (
        <UserContext.Provider value={{ user, usr, getUsr, logout, signIn, updateMail }}>
            {children}
        </UserContext.Provider>
    );
};

export const UserAuth = () => {
    return useContext(UserContext);
};