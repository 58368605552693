import { AccessAlarm, FilterList, Recommend, Refresh, Upload, UploadFile } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { LoadingButton } from '@mui/lab';
import { Alert, Badge, Button, Card, CardContent, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, Hidden, IconButton, Input, MenuItem, TextField, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { DataGrid } from '@mui/x-data-grid';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { collection, deleteDoc, doc, getDoc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { deleteObject, getDownloadURL, listAll, ref, uploadBytes } from "firebase/storage";
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { cleanData, db, storage } from '../../../Firebase';


import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import DialogMesReservation from '../packageGrid/DialogMesReservation';
import { table_columns } from '../packageGrid/TableColumns';



export default function Demandes() {

    const [color, setAge] = React.useState('#ff4838');

    function getConfirmed(params) {
        const pastTime = new Date(params.row.date);
        const now = new Date();

        const thirtyDaysInMs = 3 * 24 * 60 * 60 * 1000;

        const timeDiffInMs = now.getTime() - pastTime.getTime();
        let bool = timeDiffInMs >= thirtyDaysInMs
        console.log(bool);
        return bool

    }

    const columns = [
        {
            field: "confirmed",
            align: "center",
            headerName: 'Confirmé',
            width: 90,
            valueGetter: getConfirmed,

        },
        { field: 'nom_agence', headerName: 'Agence', width: 150 },
        { field: 'nom', headerName: 'Nom prénom', width: 150 },
        { field: 'phone', headerName: 'Téléphone', width: 150 },
        // { field: 'passport', headerName: 'Num Passport', width: 180 },
        {
            field: 'date', headerName: 'Date', width: 150
        },
        { field: 'type', headerName: 'Type', width: 150 },
        { field: 'destination', headerName: 'Destination', width: 150 },
        // { field: 'expiration_passport', headerName: 'Expiration passport', width: 150 },
        {
            field: 'etat',
            align: "center",
            headerName: 'Modifier',
            width: 180,
            renderCell: (cell) => {
                return (
                    cell.row.etat == "done" ?
                        <div value={"done"}><CheckCircleIcon color={"success"}></CheckCircleIcon><strong> Confirmé</strong> </div>
                        : cell.row.etat == "waiting" ?
                            <div value={"waiting"}><HourglassTopIcon color={"warning"}></HourglassTopIcon><strong> En traitement</strong> </div>
                            :
                            <div value={"cancelled"}><DoDisturbOnIcon color="error"></DoDisturbOnIcon><strong> Annulé</strong></div>
                );
            }
        },

    ];

    const [rows, setRows] = React.useState([]);
    const [destinations, setdestinations] = React.useState([]);
    const [agences, setagences] = useState([]);

    const getData = async () => {
        setRows([])

        await getDocs(collection(db, "reservation"))
            .then((querySnapshot) => {
                const newData = cleanData(querySnapshot)
                setRows(newData)
            }).catch((err) => {
                console.log(err);
            })

    }

    const getDestinations = async (e) => {
        setdestinations([])

        await getDocs(collection(db, "destination_detail"))
            .then((querySnapshot) => {
                const newData = querySnapshot.docs
                    .map((doc) => ({ title: doc.data().title }));
                setdestinations(newData)
            }).catch((err) => {
                console.log(err);
            })

    };

    const getAgences = async (e) => {
        setagences([])

        await getDocs(collection(db, "inscription"))
            .then((querySnapshot) => {
                const newData = cleanData(querySnapshot)
                setagences(newData)
            }).catch((err) => {
                console.log(err);
            })

    };

    const [filter, setfilter] = React.useState(false);




    const handleFilter = async (values) => {
        setRows([])

        var detailRef = null;
        var q = null
        var querySnapshot = null;
        let tmp = []

        let name = values.name.toLowerCase()
        let agence = values.agence.toLowerCase()
        let destination = values.destination

        if (values.depart != null) {
            let depart = format(values.depart, "dd-MM-yyyy")
            if (destination == "") {
                if (agence == "") {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom", name == "" ? ">=" : "==", name), where("depart", "==", depart))
                    querySnapshot = await getDocs(q);
                } else {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("nom", name == "" ? ">=" : "==", name), where("depart", "==", depart))
                    querySnapshot = await getDocs(q);
                }

            } else {
                if (agence == "") {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("destination", "==", destination), where("nom", name == "" ? ">=" : "==", name), where("depart", "==", depart))
                    querySnapshot = await getDocs(q);
                } else {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("destination", "==", destination), where("nom", ">=", name), where("depart", "==", depart))
                    querySnapshot = await getDocs(q);
                }
            }
        }
        else {
            if (destination == "") {
                if (agence == "") {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom", name == "" ? ">=" : "==", name))
                    querySnapshot = await getDocs(q);
                } else {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("nom", name == "" ? ">=" : "==", name))
                    querySnapshot = await getDocs(q);
                }

            } else {
                if (agence == "") {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("destination", "==", destination), where("nom", name == "" ? ">=" : "==", name))
                    querySnapshot = await getDocs(q);
                } else {
                    detailRef = collection(db, "reservation");
                    q = await query(detailRef, where("nom_agence", "==", agence), where("destination", "==", destination), where("nom", ">=", name))
                    querySnapshot = await getDocs(q);
                }
            }
        }

        await querySnapshot.forEach(async (doc) => {
            tmp.push({ ...doc.data(), id: doc.id })
            // doc.data() is never undefined for query doc snapshots
        });

        setRows(tmp)


    }


    const [countReservation, setcountReservation] = useState(0);
    const getCancelled = async (filter) => {
        let day = new Date()
        day.setDate(day.getDate() - 3)
        console.log(format(day, "dd-MM-yyyy"));

        let detailRef = collection(db, "reservation");
        let q = await query(detailRef, where("etat", "==", "waiting"), where("date", "<=", format(day, "dd-MM-yyyy")))
        let querySnapshot = await getDocs(q);
        let tmp = []


        await querySnapshot.forEach(async (doc) => {
            tmp.push({ ...doc.data(), id: doc.id })
            // doc.data() is never undefined for query doc snapshots
        });

        setcountReservation(tmp.length)
        if (filter) {
            setRows(tmp)
        }
    };



    const editData = async () => {
        setdone(true)
        let itm = item
        let values = confirmationValues

        let pdfUrl = item.pdf
        let promises = []
        let waiting = ""
        let snapshotRef = null

        try {
            if (values.pdf != "") {
                let imageRef = ref(storage, "images/reservation/" + item.id + "/" + "reservation_" + item.id)

                promises.push(
                    uploadBytes(imageRef, values.pdf).then((snapshot) => {
                        snapshotRef = snapshot.ref
                    })
                )
                waiting = await Promise.all(promises);
                promises.push(
                    getDownloadURL(snapshotRef).then((downloadURL) => {
                        pdfUrl = downloadURL
                    })
                )
            }
        }
        catch (e) {
            console.log(e);
        }
        waiting = await Promise.all(promises);


        if (values.etat == "cancelled") {
            let promises = []
            let waiting = ""


            try {
                const docRef = doc(db, "inscription", item.id_agence);
                await getDoc(docRef).then((docSnap) => {

                    let p = {
                        versement: docSnap.data().versement - item.total,
                    };
                    promises.push(updateDoc(doc(db, 'inscription', item.id_agence), p))
                })
            }
            catch (e) {
            }

            waiting = await Promise.all(promises);
            promises = []

            try {
                const docRef = doc(db, "destination_detail", item.programme.id);
                const docSnap = promises.push(getDoc(docRef));
                waiting = await Promise.all(promises);

                await getDoc(docRef).then((docSnap) => {
                    let tmpDepart = docSnap.data().departs

                    tmpDepart.map((itm, index) => {
                        if (itm.depature == item.depart) {
                            tmpDepart[index] = {
                                depature: tmpDepart[index].depature,
                                return: tmpDepart[index].return,
                                places: tmpDepart[index].places,
                                reservations: tmpDepart[index].reservations - item.members.length
                            };
                        }
                    })
                    let p = {
                        departs: tmpDepart
                    };
                    promises.push(updateDoc(doc(db, 'destination_detail', item.programme.id), p))
                })
            }
            catch (e) {
            }
            waiting = await Promise.all(promises);
        }



        try {
            const docRef = await updateDoc(doc(db, "reservation", itm.id), {
                etat: values.etat,
                pdf: pdfUrl
            });
            setChange(false)
            getData()
            getCancelled(false)
            setdone(false)
            handleCloseConfirmation()
            handleClose()

        } catch (e) {
            setChange(false)
            setdone(false)
            handleCloseConfirmation()
            handleClose()
        }

    }

    const supprimer = async () => {
        setloadingDelete(true)
        let promises = []
        let waiting = ""
        if (item.etat == "waiting") {

            try {
                const docRef = doc(db, "inscription", item.id_agence);
                await getDoc(docRef).then((docSnap) => {

                    let p = {
                        versement: docSnap.data().versement - item.total,
                    };
                    promises.push(updateDoc(doc(db, 'inscription', item.id_agence), p))
                })
            }
            catch (e) {
            }
            waiting = await Promise.all(promises);
            promises = []

            try {
                const docRef = doc(db, "destination_detail", item.programme.id);
                const docSnap = promises.push(getDoc(docRef));
                waiting = await Promise.all(promises);

                await getDoc(docRef).then((docSnap) => {
                    let tmpDepart = docSnap.data().departs

                    tmpDepart.map((itm, index) => {
                        if (itm.depature == item.depart) {
                            tmpDepart[index] = {
                                depature: tmpDepart[index].depature,
                                return: tmpDepart[index].return,
                                places: tmpDepart[index].places,
                                reservations: tmpDepart[index].reservations - item.members.length
                            };
                        }
                    })
                    let p = {
                        departs: tmpDepart
                    };
                    promises.push(updateDoc(doc(db, 'destination_detail', item.programme.id), p))
                })

            }
            catch (e) {
            }
            waiting = await Promise.all(promises);
        }


        try {

            await deleteDoc(doc(db, "reservation", item.id));


            const folderRef = ref(storage, 'images/reservation/' + item.id);
            await listAll(folderRef)
                .then(dir => {
                    dir.items.forEach(fileRef => {
                        const desertRef = ref(storage, fileRef.fullPath);
                        deleteObject(desertRef).catch(error => alert("error"))
                    }
                    );
                })
                .catch(error => alert("error"));


            getData()
            handleClosesupmsg()
            handleClose()
            setloadingDelete(false)

        } catch (e) {
            setloadingDelete(false)

            console.log("echec !! " + e);
        }
    }


    useEffect(() => {
        getData()
        getDestinations()
        getAgences()
        getCancelled(false)
    }, [])



    // dialog
    const [item, setItem] = React.useState({
        date:
            "20-04-2023",
        destination:
            "omra shawal",
        etat:
            "waiting",
        members:
            [],
        age:
            "20-04-2023",
        chambre:
            "Single",
        etat:
            "waiting",
        expiration_passport:
            "26-04-2023",
        files: []
        , nom:
            "test",
        passport:
            "999",
        visa:
            "Non",
        waitingList:
            "en traitement",
        nbadults:
            1,
        nbenf0:
            0,
        nbenf2:
            1,
        nbenf6:
            0,

        nom_agence:
            "agence test",
        phone:
            "0555555555",
        programme: { id: 0, reservations: 0, files: [] },

        type:
            "omra"
    });
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {

        setitemIndex(0);
        setChange(false);
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    const [itemIndex, setitemIndex] = useState(0);
    const [itemDeatil, setitemDeatil] = useState({});

    //supprimer

    const [loadingDelete, setloadingDelete] = useState(false);

    const [supmsg, setsupmsg] = React.useState(false);
    const handleOpensupmsg = () => setsupmsg(true);
    const handleClosesupmsg = () => setsupmsg(false);



    // confirmation
    const [done, setdone] = useState(false);
    const [confirmationValues, setconfirmationValues] = useState();
    const [confirmation, setconfirmation] = React.useState(false);
    const handleOpenConfirmation = (item, values) => {
        setconfirmationValues(values);
        setconfirmation(true);
    };
    const handleCloseConfirmation = () => setconfirmation(false);
    const [change, setChange] = React.useState(false);
    const [newValue, setnewValue] = useState();


    return (
        <div >
            <Dialog
                scroll='body'

                fullWidth
                maxWidth="sm"
                open={confirmation}
                onClose={handleCloseConfirmation}>
                <DialogTitle>
                    <strong>Confirmation</strong>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        êtes-vous sûr de vouloir confirmer cette modification ?{' '}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        autoFocus
                        color="inherit"
                        onClick={handleCloseConfirmation}>
                        Annuler
                    </Button>
                    <LoadingButton loading={done} variant="contained" onClick={editData}>
                        Confirmer
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog
                scroll='body'

                fullWidth
                maxWidth="sm"
                open={supmsg}
                onClose={handleClosesupmsg}
            >
                <DialogTitle>
                    <strong>
                        Supprimer
                    </strong>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        êtes-vous sûr de vouloir supprimer cette réservations ? <br />
                        id :
                        <strong>
                            {item.id}
                        </strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" autoFocus color="inherit" onClick={handleClosesupmsg}>
                        Annuler
                    </Button>
                    <LoadingButton loading={loadingDelete} variant="contained" style={{ backgroundColor: "#d50000" }} onClick={supprimer} startIcon={<DeleteIcon />}>
                        Supprimer
                    </LoadingButton>
                </DialogActions>

            </Dialog>
            <Dialog
                scroll='body'
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleClose}
            >

                <Card >
                    <DialogTitle>
                        <Stack justifyContent={"space-between"} direction="row" alignItems={"center"}>
                            <Typography variant='h4'>
                                <strong>
                                    Informations
                                </strong>
                            </Typography>
                            <Button variant='contained' onClick={handleOpensupmsg} color='error' endIcon={<DeleteIcon></DeleteIcon>} >Supprimer</Button>
                        </Stack>

                    </DialogTitle>
                    <DialogMesReservation data={item}></DialogMesReservation>
                    <Container sx={{ mb: 3 }} >
                        <Divider
                            sx={{ mb: 2, color: color, borderBottomWidth: 3 }}
                            variant="middle"
                        ></Divider>
                        <div className='row'>
                            <Formik
                                initialValues={{

                                    etat: item?.etat,
                                    pdf: ""

                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        // duration: Yup.string().max(255).required('champ obligatoire'),
                                        // places: Yup.number().integer().required('champ obligatoire'),
                                        // price: Yup.number().integer().required('champ obligatoire'),

                                        // include: Yup.string().max(255).required('champ obligatoire'),
                                    }

                                    )
                                }
                                onSubmit={(values) => {
                                    handleOpenConfirmation(item.members[itemIndex], values)
                                }}
                            >
                                {({

                                    setFieldValue,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting, isValid
                                }) => (
                                    <Form>
                                        {
                                            item?.etat != "cancelled" &&
                                            <TextField
                                                select
                                                fullWidth
                                                label="Etat"
                                                name="etat"
                                                onChange={(event, value) => {
                                                    setnewValue(event.target.value)
                                                    setFieldValue("etat", event.target.value)

                                                }}
                                                value={values.etat}
                                                variant="outlined"
                                            >
                                                <MenuItem value={"done"}><CheckCircleIcon color={"success"}></CheckCircleIcon> Confirmé</MenuItem>
                                                <MenuItem value={"waiting"}><HourglassTopIcon color={"warning"}></HourglassTopIcon>  En traitement</MenuItem>
                                                <MenuItem value={"cancelled"}><DoDisturbOnIcon color="error"></DoDisturbOnIcon> Annulé</MenuItem>

                                            </TextField>
                                        }

                                        {
                                            values.etat == "done" &&
                                            <div>
                                                <Divider
                                                    sx={{ mt: 3, mb: 2, color: color, borderBottomWidth: 3 }}
                                                    variant="middle"
                                                ></Divider>
                                                <Typography variant="h6" >
                                                    <strong>
                                                        Voucher de réservation
                                                    </strong>

                                                </Typography>
                                                <Stack
                                                    direction="row"
                                                    alignItems="center"
                                                    spacing={2}
                                                >

                                                    <label htmlFor="button-file">
                                                        <Button variant="contained" component="span" size="large" startIcon={<Upload />}>
                                                            <Input
                                                                id="button-file"
                                                                style={{ display: "none" }}
                                                                onChange={value => {
                                                                    setFieldValue("pdf", value.currentTarget.files[0]);
                                                                }}
                                                                name="pdf"
                                                                accept=".pdf"
                                                                type="file"
                                                                inputProps={{
                                                                    accept: '.pdf',
                                                                }}
                                                            />
                                                            Importer
                                                        </Button>
                                                    </label>
                                                    <div>
                                                        {
                                                            values.pdf == "" ? "" : <div>
                                                                <Button variant="contained" color="error" endIcon={<DeleteIcon />}
                                                                    onClick={() => {
                                                                        setFieldValue("pdf", "")

                                                                    }}
                                                                >
                                                                    Supprimer
                                                                </Button>

                                                            </div>
                                                        }
                                                    </div>
                                                    <div>
                                                        {
                                                            item.pdf != "" && values.pdf == "" &&
                                                            <div>
                                                                <a
                                                                    style={{ paddingLeft: 30 }}
                                                                    href={item?.pdf}
                                                                    rel="noopener noreferrer"
                                                                    target="_blank"
                                                                    download
                                                                >
                                                                    <UploadFile></UploadFile>
                                                                    Téléchager voucher
                                                                </a>
                                                            </div>
                                                        }
                                                        {
                                                            values.pdf != "" &&
                                                            < div >
                                                                <strong>
                                                                    {" " + values.pdf.name}
                                                                </strong>
                                                            </div>
                                                        }
                                                    </div>
                                                </Stack>
                                            </div>
                                        }
                                        <DialogActions>
                                            <Button
                                                disabled={item.pdf == "" && values.pdf == "" && values.etat == "done"}
                                                variant='contained'
                                                type="submit"

                                            >Modifier</Button>
                                        </DialogActions>


                                    </Form>)}
                            </Formik>


                        </div>
                    </Container >


                </Card >
            </Dialog >
            <Container>
                <Hidden mdDown>
                    <Stack
                        direction={"row"}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <Typography variant='h3' mb={2}>
                            <strong>
                                <i className="bi bi-file-diff-fill"></i> Réservations
                            </strong>
                        </Typography>
                        <div>

                            <Badge max={999} badgeContent={countReservation} color="primary" sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15, }, mr: 3 }}>
                                <Button
                                    variant='contained'
                                    onClick={() => getCancelled(true)}
                                    endIcon={<DoDisturbOnIcon color="inherit" />}
                                    color="error"
                                >
                                    Réservations non payées
                                </Button>
                            </Badge>
                            <Button onClick={() => setfilter(!filter)} style={{ backgroundColor: color }} variant='contained' endIcon={<FilterList ></FilterList>}>filter</Button>

                        </div>

                    </Stack>
                </Hidden>
                <Hidden mdUp>
                    <Stack
                        direction={"column"}
                        alignItems="center"
                        justifyContent="space-between"
                    >
                        <div className='row'>

                            <Typography variant='h3' mb={2}>
                                <strong>
                                    <i className="bi bi-file-diff-fill"></i> Réservations
                                </strong>
                            </Typography>
                        </div>
                        <Stack
                            direction={"row"}
                            alignItems="center"
                            justifyContent="space-between"
                        >

                            <Badge max={999} badgeContent={countReservation} color="primary" sx={{ "& .MuiBadge-badge": { fontSize: 9, height: 15, minWidth: 15, }, mr: 3 }}>
                                <Button
                                    variant='contained'
                                    onClick={() => getCancelled(true)}
                                    endIcon={<DoDisturbOnIcon color="inherit" />}
                                    color="error"
                                >
                                    Réservations annulés
                                </Button>
                            </Badge>
                            <Button onClick={() => setfilter(!filter)} style={{ backgroundColor: color }} variant='contained' endIcon={<FilterList ></FilterList>}>filter</Button>

                        </Stack>

                    </Stack>
                </Hidden>
                <Collapse in={filter}>
                    <Card>

                        <CardContent>
                            <Typography variant='h6' mb={2}>
                                <strong>
                                    <i style={{ color: color }} className="bi bi-search"></i> Filter
                                </strong>
                            </Typography>

                            <Formik
                                initialValues={{
                                    agence: "",
                                    name: "",
                                    destination: "",
                                    depart: null
                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        // agence: Yup.string().max(255).required('champ obligatoire'),
                                        // name: Yup.string().max(255).required('champ obligatoire'),
                                    }

                                    )
                                }
                                onSubmit={(values) => {
                                    handleFilter(values)
                                }}
                            >
                                {({

                                    setFieldValue,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting, isValid
                                }) => (
                                    <Form>
                                        <div className="row">
                                            <div className="col-sm-6">
                                                <TextField
                                                    // disabled={values.name != ""}
                                                    error={Boolean(touched.agence && errors.agence)}
                                                    fullWidth
                                                    helperText={touched.agence && errors.agence}
                                                    label="Agence"
                                                    margin="normal"
                                                    name="agence"
                                                    onBlur={handleBlur}
                                                    onChange={(
                                                        event,
                                                        value
                                                    ) => {
                                                        setFieldValue(
                                                            'agence',
                                                            event.target
                                                                .value
                                                        );
                                                    }}
                                                    value={values.agence}
                                                    variant="outlined"
                                                    select
                                                >
                                                    {

                                                        agences.map((agence, index) => {

                                                            return (
                                                                <MenuItem key={index} value={agence.nom_agence}>
                                                                    {agence.nom_agence}
                                                                </MenuItem>)
                                                        })
                                                    }
                                                </TextField>


                                            </div>
                                            <div className="col-sm-6">
                                                <TextField
                                                    // disabled={values.agence != ""}

                                                    error={Boolean(touched.name && errors.name)}
                                                    fullWidth
                                                    helperText={touched.name && errors.name}
                                                    label="Nom client"
                                                    margin="normal"
                                                    name="name"
                                                    onBlur={handleBlur}
                                                    onChange={handleChange}
                                                    value={values.name}
                                                    variant="outlined"

                                                >

                                                </TextField>

                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-sm-6'>
                                                <TextField
                                                    error={Boolean(touched.destination && errors.destination)}
                                                    fullWidth
                                                    helperText={touched.destination && errors.destination}
                                                    label="Destination"
                                                    margin="normal"
                                                    name="destination"
                                                    onBlur={handleBlur}
                                                    onChange={(
                                                        event,
                                                        value
                                                    ) => {
                                                        setFieldValue(
                                                            'destination',
                                                            event.target
                                                                .value
                                                        );
                                                    }}
                                                    value={values.destination}
                                                    variant="outlined"
                                                    select
                                                >
                                                    {

                                                        destinations.map((destination, index) => {

                                                            return (
                                                                <MenuItem key={index} value={destination.title}>
                                                                    {destination.title}
                                                                </MenuItem>)
                                                        })
                                                    }

                                                </TextField>

                                            </div>
                                            <div className='col-sm-6 mt-3'>
                                                <LocalizationProvider
                                                    adapterLocale={fr}
                                                    dateAdapter={AdapterDateFns}
                                                >
                                                    <DesktopDatePicker
                                                        error={Boolean(touched.depart && errors.depart)}
                                                        helperText={touched.depart && errors.depart}
                                                        fullWidth
                                                        label="Départ"
                                                        name="depart"
                                                        ampm={false}
                                                        inputFormat="dd/MM/yyyy"
                                                        onBlur={handleBlur}
                                                        onChange={(event, value) => {
                                                            setFieldValue("depart", event)
                                                        }}
                                                        value={values.depart}
                                                        variant="outlined"
                                                        renderInput={(params) => <TextField {...params} />}
                                                    />
                                                </LocalizationProvider>
                                            </div>


                                        </div>

                                        <div className='row'>
                                            <div className='col-sm-6'>

                                                <Button type='submit' style={{ backgroundColor: color }} variant='contained'>Appliquer</Button>
                                                <IconButton onClick={() => {
                                                    setFieldValue("agence", "")
                                                    setFieldValue("name", "")
                                                    setFieldValue("destination", "")
                                                    setFieldValue("depart", null)
                                                    getData()
                                                }}>
                                                    <Refresh></Refresh>
                                                </IconButton>
                                            </div>
                                        </div>
                                    </Form>)}
                            </Formik>
                        </CardContent>

                    </Card>
                </Collapse>

            </Container>
            <div
                style={{ height: 630, width: '100%' }}
            >
                <DataGrid
                    sx={{ mt: 2 }}
                    disableColumnMenu
                    disableColumnFilter
                    rows={rows}
                    columns={table_columns}
                    pageSize={10}
                    rowsPerPageOptions={[5]}
                    onCellClick={(cell) => {
                        setItem(cell.row)
                        setitemDeatil(cell.row.members[0])
                        handleOpen()
                    }}

                    components={{
                        NoRowsOverlay: () => (
                            <Stack height="100%" alignItems="center" justifyContent="center">
                                <Alert severity="error">
                                    Aucune réservations trouvé !
                                </Alert>
                            </Stack>
                        ),
                        NoResultsOverlay: () => (
                            <Alert severity="error">
                                Aucune réservations trouvé !
                            </Alert>
                        )
                    }}

                />

            </div>
        </ div >
    );
}