import { AccountBox, Article, Badge, CalendarMonth, CalendarToday, Fingerprint, Home, Pin, Public, Smartphone, UploadFile } from '@mui/icons-material'
import { Divider, Stack, Typography } from '@mui/material'
import React from 'react'
import { IconLabelItem } from '../../common/IconLabelItem'

export default function DialogVisa(props) {

    let item = props.data
    let color = '#ff4838'
    return (
        <div>
            <div className='row'>
                <div className="col-sm-6">
                    {IconLabelItem(<AccountBox style={{ color: color }}></AccountBox>, "Nom agence", item.nom_agence)}
                </div>
                <div className="col-sm-6">
                    {IconLabelItem(<Fingerprint style={{ color: color }}></Fingerprint>, "Référence", item.id)}
                </div>
            </div>
            <Divider
                sx={{ mt: 1, mb: 1, color: color, borderBottomWidth: 3 }}
                variant="middle"
            ></Divider>
            <div className='row'>

                <div className="col-sm-6">
                    {IconLabelItem(<Badge style={{ color: color }}></Badge>, "Nom et prénom", item.nom)}
                </div>
                <div className="col-sm-6">
                    {IconLabelItem(<CalendarMonth style={{ color: color }}></CalendarMonth>, "Date de naissance", item.age)}
                </div>

            </div>
            <div className='row'>
                <div className="col-sm-6">
                    {IconLabelItem(<CalendarMonth style={{ color: color }}></CalendarMonth>, "Lieu de naissance", item.lieu)}
                </div>
                <div className="col-sm-6">
                    {IconLabelItem(<Public style={{ color: color }}></Public>, "Nationalité", item.nationalite)}
                </div>
            </div>
            <div className='row'>
                <div className="col-sm-6">
                    {IconLabelItem(<Home style={{ color: color }}></Home>, "Adresse", item.adresse)}
                </div>
                <div className="col-sm-6">
                    {IconLabelItem(<Smartphone style={{ color: color }}></Smartphone>, "Mobile", item.phone)}
                </div>

            </div>
            <div className='row'>
                <div className="col-sm-6">
                    {IconLabelItem(<Pin style={{ color: color }}></Pin>, "Passport", item.passport)}
                </div>
                <div className="col-sm-6">
                    {IconLabelItem(<CalendarToday style={{ color: color }}></CalendarToday>, "Expiration passport", item.expiration_passport)}
                </div>
            </div>
            <Divider
                sx={{ mt: 1, mb: 1, color: color, borderBottomWidth: 3 }}
                variant="middle"
            ></Divider>
            <div className='row'>
                <div className="col-sm-6">
                    {IconLabelItem(<Article style={{ color: color }}></Article>, "Visa", item?.pays ? item?.pays : "")}
                </div>
                <div className="col-sm-6">
                    {IconLabelItem(<Article style={{ color: color }}></Article>, "Type de visa", item?.type + " | Prix : " + item?.total + " DA")}
                </div>
            </div>

            <div className='row'>
                <div className="col-sm-6">
                    {IconLabelItem(<UploadFile style={{ color: color }}></UploadFile>, "Les fichies", "")}
                    <Typography>
                        {item.files && item.files.map((itm, ind) =>
                            <div style={{ paddingLeft: 30 }}>
                                <a href={itm.file} download>
                                    <Stack
                                        direction="row"
                                        justifyContent="start"
                                        alignItems="center"
                                    >
                                        <UploadFile></UploadFile>
                                        <Typography>
                                            {itm.file_name}
                                        </Typography>
                                    </Stack>
                                </a>
                            </div>
                        )}
                    </Typography>
                </div>
            </div>
        </div>
    )
}
