import { ArrowBack, ArrowForward, Check, Delete, FileCopy, HourglassBottom, UploadFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Alert, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, FormGroup, Grid, Input, MenuItem, Snackbar, Step, StepLabel, Stepper, TextField, Typography } from '@mui/material';
import { makeStyles } from '@mui/styles';
import { Stack } from '@mui/system';
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';
import { collection, doc, getDocs, setDoc, updateDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import * as Yup from 'yup';
import { cleanData, db, storage } from '../../../Firebase';
import { UserAuth } from '../../context/UserContext';
// import uae from "../../../../public/images/uae.png"

const useStyles = makeStyles({
    root: {
        '& .MuiOutlinedInput-root': {
            '& fieldset ': {
                borderColor: '#ff4838',
                borderRadius: 200,
            },
            '& input ': {
                borderColor: '#ff4838',
                borderRadius: 200,
            },
        },
        padding: 5,
    },
});

export default function VisaClient(props) {

    const classes = useStyles();

    const [actions, setactions] = useState();

    const [done, setdone] = useState(false);

    const { usr } = UserAuth();


    const addClient = async () => {
        let values = confirmationValues

        setdone(true)

        try {
            const reference = await doc(collection(db, "listvisa"))
            const docRef = reference.id
            const promises = [];
            let i = 0
            for (let file of values.files) {
                const imageRef = ref(storage, "images/demande_visa/" + docRef + "/" + i)
                promises.push(uploadBytesResumable(imageRef, file).then((uploadResult) => { return getDownloadURL(uploadResult.ref) }));
                i = i + 1
            }

            const photos = await Promise.all(promises);
            let files = photos.map(function (file, index) {
                return {
                    file_name: values.selected.files[index],
                    file: file
                }
            })


            let dateNaissance = format(values.age, 'dd-MM-yyyy')
            let dateExp = format(values.expiration_passport, 'dd-MM-yyyy')


            promises.push(getUsr())
            let waiting = await Promise.all(promises);
            let total = parseInt(usr.montant) - parseInt(values.type.prix)

            if (total >= 0) {
                await setDoc(doc(db, "visa", docRef), {
                    confirmed: false,
                    nom_agence: usr.nom_agence,
                    nom: values.nom.toLowerCase(),
                    passport: values.passport,
                    age: dateNaissance,
                    phone: values.phone,
                    visa: values.selected,
                    pays: values.selected.pays,
                    type: values.type.type,
                    total: values.type.prix,
                    email: values.email,
                    lieu: values.lieu,
                    adresse: values.adresse,
                    nationalite: values.nationalite,
                    expiration_passport: dateExp,
                    etat: "waiting",
                    files: files,
                    pdf: "",
                    date: format(new Date(), 'dd-MM-yyyy')
                })


                docRef = await updateDoc(doc(db, 'inscription', usr.id), {
                    montant: total,
                }).then(i => {
                    if (values.nbpersonne == personnesAjoutes + 1) {
                        setActiveStep(0);
                        setpersonnesAjoutes(0)
                        actions.resetForm()
                    }
                    else {
                        setActiveStep(1);
                        setpersonnesAjoutes((prvvalue) => prvvalue + 1)
                    }
                    setdone(false)
                    getUsr()
                    handleCloseConfirmation()
                    handleSnake()
                })
            }

        } catch (values) {
            console.error("Error adding document: ", values);
            setdone(false)
            handleCloseConfirmation()
        }
    }


    const [pays, setPays] = React.useState([]);
    const getListPays = async () => {
        setPays([])
        await getDocs(collection(db, "listvisa"))
            .then((querySnapshot) => {
                const newData = cleanData(querySnapshot)
                setPays(newData)
            }).catch((err) => {
                console.log(err);
            })
    }

    const [selected, setSelected] = React.useState(null);

    const [snake, setSnake] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const handleSnake = async () => {
        setSnake(true);
    };
    const handleCloseSnake = () => setSnake(false);





    useEffect(() => {

        getListPays()
        getUsr()
    }, [])

    const { user, getUsr } = UserAuth();

    //confirmation
    const [confirmationValues, setconfirmationValues] = useState();
    const [confirmation, setconfirmation] = React.useState(false);
    const handleOpenConfirmation = (values) => {
        setconfirmationValues(values);
        setconfirmation(true);
    };
    const handleCloseConfirmation = () => setconfirmation(false);

    // stepper
    const steps = ['Selectionner visa', 'Remplire les champs', 'Confirmer'];
    const [activeStep, setActiveStep] = React.useState(0);


    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleReset = () => {
        setActiveStep(0);
    };


    const [prix, setprix] = useState(0);
    const [personnesAjoutes, setpersonnesAjoutes] = useState(0);

    //  test condition button

    const testConditionButton = (values) => {

        let price = parseInt(values.nbpersonne) * parseInt(values.type.prix)

        setprix(price)

        return values.type == "" || price > usr.montant || values.nbpersonne <= 0 || values.nationalite == ""
    };





    return (
        <div className="col-lg-12" >
            <Dialog
                scroll='body'
                fullWidth
                maxWidth="md"
                open={confirmation}
                onClose={handleCloseConfirmation}>
                <DialogTitle>
                    <strong>Confirmation</strong>
                </DialogTitle>
                <Formik initialValues={{
                    checked: false
                }}

                >
                    {({
                        setFieldValue,
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                    }) => (
                        <Form>
                            <DialogContent>
                                <DialogContentText>
                                    <Typography variant='h5'>
                                        <strong>
                                            êtes-vous sûr de vouloir confirmer cette demande ?{' '}
                                        </strong>

                                    </Typography>


                                    <div>


                                        {
                                            confirmationValues.selected && confirmationValues.selected.description && confirmationValues.selected.description != "" && (
                                                <div>
                                                    <Divider orientation="vertical" variant="middle" flexItem />
                                                    <Typography>
                                                        <strong>
                                                            Conditions :
                                                        </strong>
                                                        <br />
                                                    </Typography>
                                                    <Typography
                                                        sx={{
                                                            whiteSpace: "pre-wrap"
                                                        }}
                                                    >
                                                        {
                                                            confirmationValues.selected.description
                                                        }
                                                    </Typography>
                                                </div>
                                            )
                                        }
                                    </div>


                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={values.checked}
                                                    onChange={(e) => {
                                                        setFieldValue("checked", !values.checked)
                                                    }}
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label="Accepter les conditions" />
                                    </FormGroup>

                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button
                                    variant="outlined"
                                    autoFocus
                                    color="inherit"
                                    onClick={handleCloseConfirmation}>
                                    Annuler
                                </Button>
                                <LoadingButton loading={done} disabled={!values.checked} variant="contained" onClick={addClient}>
                                    Confirmer
                                </LoadingButton>
                            </DialogActions>
                        </Form>
                    )}
                </Formik>
            </Dialog>
            <Snackbar open={snake} autoHideDuration={5000} onClose={handleCloseSnake}>


                <Alert onClose={handleCloseSnake} severity="success" sx={{ width: '100%' }}>
                    Demande de visa a été ajouté !
                </Alert>

            </Snackbar>
            <div className="package-sidebar">
                <aside className="package-widget-style-2 widget-form">
                    <div className="widget-title text-center d-flex justify-content-between" style={{ paddingTop: "15vh" }}>

                    </div>
                    <div className="widget-body">
                        <Typography variant='h3'>
                            <strong>
                                Demander votre visa
                            </strong>
                        </Typography>
                        <Divider
                            sx={{
                                mt: 2, mb: 2
                            }}
                        >

                        </Divider>

                        <Box sx={{ width: '100%' }}>
                            <Stepper activeStep={activeStep}>
                                {steps.map((label, index) => {
                                    const stepProps = {};
                                    const labelProps = {};

                                    return (
                                        <Step key={label} {...stepProps}>
                                            <StepLabel {...labelProps}>{label}</StepLabel>
                                        </Step>
                                    );
                                })}
                            </Stepper>

                            <Formik
                                initialValues={{

                                    // nom: "test",
                                    // email: "test@test.com",
                                    // adresse: "test",
                                    // lieu: "test",
                                    // passport: "99",
                                    // phone: "0555555555",
                                    // age: "10/10/2010",
                                    // expiration_passport: "10/10/2010",
                                    // visa: "test",
                                    // type: "",
                                    // nbpersonne: 0,
                                    // nationalite: "test",
                                    // selected: null,
                                    // files: []

                                    nom: "",
                                    email: "",
                                    adresse: "",
                                    lieu: "",
                                    passport: "",
                                    phone: "",
                                    age: null,
                                    expiration_passport: null,
                                    visa: "",
                                    type: "",
                                    nbpersonne: 0,
                                    nationalite: "",
                                    selected: null,
                                    files: []
                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        nom: Yup.string()
                                            .max(255)
                                            .required('Champ obligatoire'),
                                        adresse: Yup.string()
                                            .max(255)
                                            .required('Champ obligatoire'),
                                        lieu: Yup.string()
                                            .max(255)
                                            .required('Champ obligatoire'),
                                        email: Yup.string().email("incorrect email")
                                            .required('Champ obligatoire'),
                                        passport: Yup.string().test(
                                            'passport',
                                            'Le numéro est invalide',
                                            (value) => !Number.isNaN(Number(value))
                                        ),
                                        phone: Yup.string().test(
                                            'phone',
                                            'Le numéro est invalide (0123456789)',
                                            (value) =>
                                                !Number.isNaN(Number(value)) &&
                                                value.length == 10 &&
                                                value[0] == '0'
                                        ),
                                        age: Yup.date().required(
                                            'Champ obligatoire'
                                        ),
                                        expiration_passport:
                                            Yup.date().required(
                                                'Champ obligatoire'
                                            ),
                                        visa: Yup.string()
                                            .max(255)
                                            .required('Champ obligatoire'),
                                        files: Yup.mixed()
                                            .required('Fichier obligatoire')
                                            .test(
                                                'files',
                                                'Fichier obligatoire',
                                                (value) => {
                                                    return (
                                                        value && value.length != 0
                                                    );
                                                }
                                            )
                                            .test(
                                                'files',
                                                'Taille de fichier (taille de fichier < 20mb)',
                                                (value) => {
                                                    function checkSize(file) {
                                                        return file.size > 20000000
                                                    }
                                                    return (
                                                        value && value.filter(checkSize).length == 0

                                                    );
                                                }
                                            )
                                            .test(
                                                'files',
                                                'Format accepté: .pdf',
                                                (value) => {
                                                    function checkType(file) {
                                                        return file.type != 'application/pdf'
                                                    }
                                                    return (
                                                        value &&
                                                        value.filter(checkType).length == 0
                                                    );
                                                }
                                            ),
                                    })
                                }
                                onSubmit={(values, actions) => {
                                    setactions(actions)
                                    let v = { ...values }
                                    handleOpenConfirmation(v)
                                    values.nom = ""
                                    values.email = ""
                                    values.adresse = ""
                                    values.lieu = ""
                                    values.passport = ""
                                    values.phone = ""
                                    values.age = null
                                    values.expiration_passport = null
                                    values.files = []

                                    // values.nom = "test"
                                    // values.email = "test@test"
                                    // values.adresse = "test"
                                    // values.lieu = "test"
                                    // values.passport = "9999"
                                    // values.phone = "0555555555"
                                    // values.age = "10/10/2010"
                                    // values.expiration_passport = "10/10/2010"

                                }}
                            >
                                {({
                                    setFieldValue,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting,
                                }) => (
                                    <Form>

                                        {activeStep === 2 ? (
                                            <React.Fragment>
                                                <Typography sx={{ mt: 2, mb: 1 }}>
                                                    All steps completed - you&apos;re finished
                                                </Typography>
                                                <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                                                    <Box sx={{ flex: '1 1 auto' }} />
                                                    <Button onClick={handleReset}>Reset</Button>
                                                </Box>
                                            </React.Fragment>
                                        ) :

                                            activeStep === 0 ?
                                                (
                                                    <Box>
                                                        <Container >
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={12} md={6}>
                                                                    <div className="custom-input-group">
                                                                        <p>
                                                                            Visa:
                                                                        </p>
                                                                    </div>
                                                                    <TextField
                                                                        className={
                                                                            classes.root
                                                                        }
                                                                        error={Boolean(
                                                                            touched.visa &&
                                                                            errors.visa
                                                                        )}

                                                                        helperText={
                                                                            touched.visa &&
                                                                            errors.visa
                                                                        }
                                                                        color="warning"
                                                                        fullWidth
                                                                        // sx={{ width: 450 }}
                                                                        id="outlined-select-currency"
                                                                        select
                                                                        placeholder="Pays"
                                                                        value={values.visa}
                                                                        onChange={(event) => {
                                                                            setFieldValue("visa", event.target.value)
                                                                            let tmp = pays.find(p => p.pays == event.target.value)
                                                                            setFieldValue("selected", tmp)
                                                                            setSelected(tmp)
                                                                        }}
                                                                        name="visa"



                                                                    >
                                                                        {
                                                                            pays.map(p =>
                                                                                <MenuItem key={p.pays} value={p.pays}>
                                                                                    <Stack spacing={2} direction="row"
                                                                                        alignItems="center"
                                                                                        justifyContent="space-around">
                                                                                        <div className='col'>
                                                                                            <img src={process.env.PUBLIC_URL + p.flag} alt="images" style={{ maxHeight: 20 }} width={50} />

                                                                                        </div>
                                                                                        <div className='col'>
                                                                                            - {p.pays}
                                                                                        </div>

                                                                                    </Stack>

                                                                                </MenuItem>
                                                                            )

                                                                        }



                                                                    </TextField>


                                                                </Grid>
                                                                <Grid item xs={12} md={6}>

                                                                    {
                                                                        values.selected && (
                                                                            <div>
                                                                                <div className="custom-input-group">
                                                                                    <p>
                                                                                        Type de visa:
                                                                                    </p>
                                                                                </div>
                                                                                <TextField
                                                                                    className={
                                                                                        classes.root
                                                                                    }
                                                                                    error={Boolean(
                                                                                        touched.type &&
                                                                                        errors.type
                                                                                    )}

                                                                                    helperText={
                                                                                        touched.type &&
                                                                                        errors.type
                                                                                    }
                                                                                    color="warning"
                                                                                    // sx={{ width: 450 }}
                                                                                    fullWidth
                                                                                    id="outlined-select-currency"
                                                                                    select
                                                                                    placeholder="Type"
                                                                                    value={values.type}
                                                                                    onChange={(event) => {
                                                                                        setFieldValue("type", event.target.value)
                                                                                        // let tmp = pays.find(p => p.pays == event.target.value)
                                                                                        // setFieldValue("selected", tmp)

                                                                                        // setSelected(tmp)
                                                                                    }}
                                                                                    name="type"
                                                                                >
                                                                                    {
                                                                                        values.selected.types.map(p =>
                                                                                            <MenuItem key={p} value={p}>
                                                                                                {p.type} | <strong> Prix : {p.prix} DA</strong>
                                                                                            </MenuItem>
                                                                                        )
                                                                                    }
                                                                                </TextField>


                                                                            </div>
                                                                        )
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <div className="custom-input-group">
                                                                        <p>Nationalité:</p>
                                                                    </div>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.nationalite &&
                                                                            errors.nationalite
                                                                        )}
                                                                        helperText={
                                                                            touched.nationalite &&
                                                                            errors.nationalite
                                                                        }
                                                                        className={classes.root}
                                                                        fullWidth
                                                                        value={values.nationalite}
                                                                        onChange={handleChange}
                                                                        type="text"
                                                                        placeholder="Nationalité"
                                                                        name="nationalite"
                                                                    />
                                                                </Grid>
                                                                <Grid item xs={12} md={6}>
                                                                    <div className="custom-input-group">
                                                                        <p>Nomber de personnes:</p>
                                                                    </div>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.nbpersonne &&
                                                                            errors.nbpersonne
                                                                        )}
                                                                        helperText={
                                                                            touched.nbpersonne &&
                                                                            errors.nbpersonne
                                                                        }
                                                                        className={classes.root}
                                                                        fullWidth
                                                                        value={values.nbpersonne}
                                                                        onChange={handleChange}

                                                                        placeholder="Nomber de personne"
                                                                        name="nbpersonne"
                                                                        type="number"
                                                                        inputProps={{ min: 1 }}
                                                                    />
                                                                </Grid>

                                                            </Grid>
                                                            <Typography>
                                                                <strong>Total : </strong>{values.type ? values.nbpersonne * values.type.prix : 0} DA
                                                            </Typography>
                                                            {prix > usr.montant &&
                                                                <Alert severity='error'>Solde insuffisant !</Alert>
                                                            }

                                                            <Button
                                                                disabled={testConditionButton(values)}
                                                                sx={{ m: 1, mt: 2 }}
                                                                variant='contained'
                                                                onClick={() => {
                                                                    handleNext()
                                                                    // setFieldValue("nom", "")
                                                                    // setFieldValue("passport", "")
                                                                    // setFieldValue("expiration_passport", "")
                                                                    // setFieldValue("phone", "")
                                                                    // setFieldValue("age", "")
                                                                    // setFieldValue("files", [])


                                                                }}
                                                                endIcon={<ArrowForward></ArrowForward>}
                                                            >
                                                                Suivant
                                                            </Button>
                                                        </Container>
                                                    </Box>
                                                )
                                                : (
                                                    <Box>

                                                        <Button
                                                            variant='contained'
                                                            color="inherit"
                                                            disabled={activeStep === 0}
                                                            onClick={handleBack}
                                                            sx={{ m: 1, mt: 2 }}
                                                            startIcon={<ArrowBack></ArrowBack>}
                                                        >
                                                            Retour
                                                        </Button>


                                                        <div className="booking-form-wrapper">
                                                            <Typography variant='h6'>
                                                                <strong> <FileCopy></FileCopy>  Visa :</strong>   <img src={process.env.PUBLIC_URL + values.selected.flag} alt="images" style={{ maxHeight: 20 }} width={50} /> - {values.selected.pays}

                                                            </Typography>
                                                            <Typography variant="h6">
                                                                <strong> <FileCopy></FileCopy> Type de visa  :</strong>     {values.type.type + " | Prix : " + values.type.prix + " DA"}
                                                            </Typography>
                                                            <Typography variant='h6'>
                                                                <strong> <FileCopy></FileCopy>  Nationalité :</strong>  {values.nationalite}

                                                            </Typography>
                                                            <Typography variant='h6'>
                                                                <strong> <FileCopy></FileCopy>  Nomber de personnes restant :</strong>  {values.nbpersonne - personnesAjoutes}

                                                            </Typography>

                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="custom-input-group">
                                                                        <p>Nom et prénom:</p>
                                                                    </div>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.nom &&
                                                                            errors.nom
                                                                        )}
                                                                        helperText={
                                                                            touched.nom &&
                                                                            errors.nom
                                                                        }
                                                                        className={classes.root}
                                                                        fullWidth
                                                                        value={values.nom}
                                                                        onChange={handleChange}
                                                                        type="text"
                                                                        placeholder="Nom et prenom"
                                                                        name="nom"
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="custom-input-group">
                                                                        <p>
                                                                            Date de naissance:
                                                                        </p>
                                                                    </div>
                                                                    <LocalizationProvider
                                                                        fullWidth
                                                                        dateAdapter={
                                                                            AdapterDateFns
                                                                        }
                                                                        adapterLocale={fr}
                                                                    >
                                                                        <DesktopDatePicker
                                                                            className={
                                                                                classes.root
                                                                            }
                                                                            value={values.age}

                                                                            name="age"
                                                                            onChange={(
                                                                                event,
                                                                                value
                                                                            ) => {
                                                                                setFieldValue(
                                                                                    'age',
                                                                                    event
                                                                                );
                                                                            }}
                                                                            ampm={false}
                                                                            inputFormat="dd/MM/yyyy"
                                                                            maxDate={new Date()}
                                                                            onBlur={handleBlur}
                                                                            variant="outlined"
                                                                            renderInput={(
                                                                                params
                                                                            ) => (
                                                                                <TextField

                                                                                    placeholder="Date de naissance"
                                                                                    error={Boolean(
                                                                                        touched.age &&
                                                                                        errors.age
                                                                                    )}
                                                                                    fullWidth
                                                                                    helperText={
                                                                                        touched.age &&
                                                                                        errors.age
                                                                                    }
                                                                                    {...params}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="custom-input-group">
                                                                        <p>
                                                                            Lieu de naissance:
                                                                        </p>
                                                                    </div>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.lieu &&
                                                                            errors.lieu
                                                                        )}
                                                                        helperText={
                                                                            touched.lieu &&
                                                                            errors.lieu
                                                                        }
                                                                        fullWidth
                                                                        className={classes.root}
                                                                        value={values.lieu}
                                                                        onChange={handleChange}
                                                                        type="text"
                                                                        placeholder="Lieu de naissance"
                                                                        name="lieu"
                                                                    />
                                                                </div>

                                                                <div className="col-sm-6">
                                                                    <div className="custom-input-group">
                                                                        <p>
                                                                            Numéro de téléphone:
                                                                        </p>
                                                                    </div>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.phone &&
                                                                            errors.phone
                                                                        )}
                                                                        helperText={
                                                                            touched.phone &&
                                                                            errors.phone
                                                                        }
                                                                        fullWidth
                                                                        className={classes.root}
                                                                        value={values.phone}
                                                                        onChange={handleChange}
                                                                        type="tel"
                                                                        placeholder="Numero de telephone"
                                                                        name="phone"
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="custom-input-group">
                                                                        <p>
                                                                            Adresse:
                                                                        </p>
                                                                    </div>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.adresse &&
                                                                            errors.adresse
                                                                        )}
                                                                        helperText={
                                                                            touched.adresse &&
                                                                            errors.adresse
                                                                        }
                                                                        fullWidth
                                                                        className={classes.root}
                                                                        value={values.adresse}
                                                                        onChange={handleChange}
                                                                        type="text"
                                                                        placeholder="Adresse"
                                                                        name="adresse"
                                                                    />
                                                                </div>

                                                            </div>

                                                            <div className="row">
                                                                <div className="col-sm-6">
                                                                    <div className="custom-input-group">
                                                                        <p>
                                                                            Numéro de passport:
                                                                        </p>
                                                                    </div>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.passport &&
                                                                            errors.passport
                                                                        )}
                                                                        helperText={
                                                                            touched.passport &&
                                                                            errors.passport
                                                                        }
                                                                        fullWidth
                                                                        className={classes.root}
                                                                        value={values.passport}
                                                                        onChange={handleChange}
                                                                        type="text"
                                                                        placeholder="Numero de passport"
                                                                        name="passport"
                                                                    />
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="custom-input-group">
                                                                        <p>
                                                                            Expiration
                                                                            passeport:
                                                                        </p>
                                                                    </div>
                                                                    <LocalizationProvider
                                                                        fullWidth
                                                                        dateAdapter={
                                                                            AdapterDateFns
                                                                        }
                                                                        adapterLocale={fr}>
                                                                        <DesktopDatePicker
                                                                            className={
                                                                                classes.root
                                                                            }

                                                                            value={
                                                                                values.expiration_passport
                                                                            }
                                                                            name="expiration_passport"
                                                                            onChange={(
                                                                                event,
                                                                                value
                                                                            ) => {
                                                                                setFieldValue(
                                                                                    'expiration_passport',
                                                                                    event
                                                                                );
                                                                            }}
                                                                            ampm={false}
                                                                            inputFormat="dd/MM/yyyy"
                                                                            minDate={new Date()}
                                                                            onBlur={handleBlur}
                                                                            variant="outlined"
                                                                            renderInput={(
                                                                                params
                                                                            ) => (
                                                                                <TextField
                                                                                    placeholder="Expiration passport"
                                                                                    error={Boolean(
                                                                                        touched.expiration_passport &&
                                                                                        errors.expiration_passport
                                                                                    )}
                                                                                    fullWidth
                                                                                    helperText={
                                                                                        touched.expiration_passport &&
                                                                                        errors.expiration_passport
                                                                                    }
                                                                                    {...params}
                                                                                />
                                                                            )}
                                                                        />
                                                                    </LocalizationProvider>
                                                                </div>
                                                                <div className="col-sm-6">
                                                                    <div className="custom-input-group">
                                                                        <p>
                                                                            Email:
                                                                        </p>
                                                                    </div>
                                                                    <TextField
                                                                        error={Boolean(
                                                                            touched.email &&
                                                                            errors.email
                                                                        )}
                                                                        helperText={
                                                                            touched.email &&
                                                                            errors.email
                                                                        }
                                                                        fullWidth
                                                                        className={classes.root}
                                                                        value={values.email}
                                                                        onChange={handleChange}
                                                                        type="text"
                                                                        placeholder="Email"
                                                                        name="email"
                                                                    />
                                                                </div>
                                                            </div>
                                                            <div className="row">
                                                                <div className="col-sm-6">




                                                                    {
                                                                        values.selected && (
                                                                            <div className="row">
                                                                                <div className="col-sm-6">

                                                                                    <div className="custom-input-group ">
                                                                                        <Typography>
                                                                                            Durée de traitement du dossier : <strong>{values.type.duree} (jours)</strong>
                                                                                        </Typography>
                                                                                        <Stack
                                                                                            mt={2}
                                                                                            direction="row"
                                                                                            alignItems="center"
                                                                                            spacing={2}
                                                                                        >


                                                                                            <label htmlFor="button-file">
                                                                                                <Button variant="contained" component="span" size="large" startIcon={<UploadFile />}>
                                                                                                    <Input
                                                                                                        id="button-file"
                                                                                                        style={{ display: "none" }}
                                                                                                        onChange={value => {
                                                                                                            if (values.files.length < values.selected.files.length) {
                                                                                                                let tmp = [...values.files]
                                                                                                                tmp.push(value.currentTarget.files[0])
                                                                                                                setSelected([])
                                                                                                                setFieldValue("files", tmp);
                                                                                                            }
                                                                                                        }}
                                                                                                        name="files"
                                                                                                        type="file"
                                                                                                        inputProps={{
                                                                                                            accept: '.pdf',
                                                                                                        }}
                                                                                                    />
                                                                                                    Importer
                                                                                                </Button>
                                                                                            </label>
                                                                                            <Typography>
                                                                                                {
                                                                                                    values.files.length == 0 ? "" : <div>
                                                                                                        <Button variant="contained" color="error" endIcon={<Delete />}
                                                                                                            onClick={() => setFieldValue("files", [])}
                                                                                                        >
                                                                                                            Supprimer
                                                                                                        </Button>

                                                                                                    </div>
                                                                                                }

                                                                                            </Typography>
                                                                                            {touched.files &&
                                                                                                errors.files && (
                                                                                                    <Typography
                                                                                                        color="error"
                                                                                                        variant="caption">
                                                                                                        {
                                                                                                            errors.files
                                                                                                        }
                                                                                                    </Typography>
                                                                                                )}

                                                                                        </Stack>

                                                                                    </div>




                                                                                </div>


                                                                            </div>
                                                                        )
                                                                    }
                                                                    <div className="custom-input-group">

                                                                        <div className='col'>
                                                                            {
                                                                                values.selected && values.selected.files.map((file, index) =>
                                                                                    <div>
                                                                                        <strong>
                                                                                            {file} ... {values.files.length >= index + 1 ? <Check color='success'></Check> : <HourglassBottom color='warning'></HourglassBottom>} {values.files.length >= index + 1 && values.files[index].name}
                                                                                        </strong>
                                                                                        {values.files.length ? "" : ""}
                                                                                    </div>


                                                                                )
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>

                                                            <div className="custom-input-group">
                                                                {
                                                                    user ?
                                                                        <div className="submite-btn">
                                                                            <LoadingButton type="submit" variant="outlined">
                                                                                Demander
                                                                            </LoadingButton>

                                                                        </div> :
                                                                        <Alert
                                                                            severity="error"
                                                                            sx={{ width: '100%' }}>
                                                                            Se connecter pour
                                                                            terminer cette action !
                                                                            <Link to={'/signin'}>
                                                                                {' '}
                                                                                Se conntecter
                                                                            </Link>
                                                                        </Alert>
                                                                }

                                                            </div>
                                                        </div>


                                                    </Box>

                                                )
                                        }
                                    </Form>
                                )}
                            </Formik>
                        </Box>


                    </div>
                </aside>
            </div >
        </div >
    )
}
