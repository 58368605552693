import { Delete, FilterList, Refresh, Visibility, VisibilityOff } from '@mui/icons-material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import DoDisturbOnIcon from '@mui/icons-material/DoDisturbOn';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import { LoadingButton } from '@mui/lab';
import { Alert, Button, Card, CardContent, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, OutlinedInput, Stack, TextField, Typography } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { getAuth } from "firebase/auth";
import { collection, deleteDoc, doc, getDocs, query, updateDoc, where } from 'firebase/firestore';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { deleteObject, listAll, ref } from 'firebase/storage';
import { Form, Formik } from 'formik';
import * as React from 'react';
import { useState } from 'react';
import * as Yup from 'yup';
import { cleanData, db, storage } from '../../../Firebase';
import { UserAuth } from '../../context/UserContext';
import DialogInscription from '../../common/DialogInscription';
// import * as firebase from 'firebase/app';
// import 'firebase/functions';
export default function Inscriptions() {


    const [color, setAge] = React.useState('#ff4838');

    const handleChange = (event) => {
        setAge(event.target.value);
    };

    const columns = [
        { field: 'nom_agence', headerName: 'Nom agence', width: 180 },
        { field: 'telephone', headerName: 'Téléphone', width: 130 },

        { field: 'mobile', headerName: 'Mobile', width: 130 },
        { field: 'email', headerName: 'Email', width: 200 },
        {
            field: 'montant', headerName: 'Montant (DA)', width: 180,
            renderCell: (cell) => {
                return (
                    cell.row.montant + " DA"
                );
            }
        },
        {
            field: 'versement', headerName: 'Crédit (DA)', width: 180,
            renderCell: (cell) => {
                return (
                    <Alert icon={false} severity={cell.row.versement >= 0 ? 'success' : 'error'}
                        sx={{ width: "100%" }}
                    >
                        {cell.row.versement + " DA"}
                    </Alert>
                );
            }
        },
        {
            field: 'etat',
            headerName: 'Etat',
            width: 180,
            renderCell: (cell) => {
                return (
                    cell.row.etat == "done" ?
                        <div value={"done"}><CheckCircleIcon color={"success"}></CheckCircleIcon><strong> Vérifié</strong> </div>
                        : cell.row.etat == "waiting" ?
                            <div value={"waiting"}><HourglassTopIcon color={"warning"}></HourglassTopIcon><strong> En traitement</strong> </div>
                            :
                            <div value={"cancelled"}><DoDisturbOnIcon color="error"></DoDisturbOnIcon><strong> Bloqué</strong></div>
                );
            }
        },

    ];


    const [comptes, setComptes] = React.useState([])


    // dialog
    const [item, setItem] = React.useState({});
    const [change, setChange] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const handleOpen = () => {
        setChange(false)
        setOpen(true);
    }
    const handleClose = () => setOpen(false);

    /////////////

    const getData = async () => {
        setComptes([])
        await getDocs(collection(db, "inscription"))
            .then((querySnapshot) => {
                const newData = cleanData(querySnapshot)
                setComptes(newData)
            }).catch((err) => {
                console.log(err);
            })
    }

    const [filter, setfilter] = React.useState(false);

    const handleFilter = async (values) => {
        setComptes([])

        var detailRef = null;
        var q = null
        var querySnapshot = null;
        let tmp = []


        detailRef = collection(db, "inscription");
        q = await query(detailRef, where("nom_agence", "==", values.agence.toLowerCase()))
        querySnapshot = await getDocs(q);




        await querySnapshot.forEach(async (doc) => {
            tmp.push({ ...doc.data(), id: doc.id })
            // doc.data() is never undefined for query doc snapshots
        });

        setComptes(tmp)


    }

    const editData = async () => {
        setdone(true)
        let itm = item
        let values = confirmationValues

        const functions = getFunctions();

        itm.montant = values.montant
        itm.versement = values.versement


        try {
            let promises = []
            // creation
            if (itm.created == false && values.etat == "done") {
                const createUser = httpsCallable(functions, 'createUser');

                const auth = getAuth();
                promises.push(
                    createUser({
                        email: itm.email,
                        password: values.password,
                        name: "client",
                    }).then((userCredential) => {
                        // Signed in 
                        itm.created = true
                        itm.password = values.password
                        itm.etat = values.etat
                        itm.uid = userCredential.data
                        return userCredential;
                        // ...
                    }))
            }

            //  modifier password
            if (itm.password != values.password) {
                itm.etat = values.etat
                itm.password = values.password
                const updatePassword = httpsCallable(functions, 'updatePassword');

                // Call the Callable Cloud Function and handle the response
                promises.push(
                    updatePassword({
                        uid: itm.uid,
                        password: values.password
                    })
                        .then((result) => {
                            // logs "Hello World!"
                        })
                        .catch((error) => {
                            console.error(error);
                        }))
            }

            //  bloquer 

            if (itm.created == true && values.etat == "cancelled") {
                console.log("bloqué");
                itm.etat = values.etat

                const updateUser = httpsCallable(functions, 'updateUser');

                // Call the Callable Cloud Function and handle the response
                promises.push(
                    updateUser({
                        uid: itm.uid,
                        disabled: true,
                    })
                        .then((result) => {
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                )
            }


            // débloquer
            if (itm.created == true && values.etat == "done") {
                itm.etat = values.etat

                const updateUser = httpsCallable(functions, 'updateUser');

                // Call the Callable Cloud Function and handle the response
                promises.push(
                    updateUser({
                        uid: itm.uid,
                        disabled: false,
                    })
                        .then((result) => {
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                )
            }




            const user = await Promise.all(promises);

            // modifier inscription
            const docRef = await updateDoc(doc(db, "inscription", itm.id), itm).then(() => {
                // let tmp = [...comptes]
                // let i = tmp.map(e => e.id).indexOf(itm.id)
                // tmp[i] = itm
                // setComptes(tmp)
                setChange(false)
                getData()
                setdone(false)
                handleCloseConfirmation()
                handleClose()

            });
        } catch (e) {
            alert("erreur!")
            setdone(false)
            handleCloseConfirmation()
        }


    }



    const { user } = UserAuth();

    React.useEffect(() => {
        getData()

    }, [])

    const [loadingDelete, setloadingDelete] = useState(false);
    const [supmsg, setsupmsg] = React.useState(false);
    const handleOpensupmsg = () => setsupmsg(true);
    const handleClosesupmsg = () => setsupmsg(false);

    const supprimer = async () => {
        setloadingDelete(true)
        try {
            let promises = []
            if (item.created) {
                const functions = getFunctions();

                // Create a reference to the Callable Cloud Function
                const deleteUser = httpsCallable(functions, 'deleteUser');

                // Call the Callable Cloud Function and handle the response
                promises.push(
                    deleteUser({ uid: item.uid })
                        .then((result) => {
                        })
                        .catch((error) => {
                            console.error(error);
                        }))
            }

            const folderRef = ref(storage, 'images/inscription/' + item.id);
            await listAll(folderRef)
                .then(dir => {
                    dir.items.forEach(fileRef => {
                        const desertRef = ref(storage, fileRef.fullPath);
                        deleteObject(desertRef).catch(error => alert("error"))
                    }
                    );
                })
                .catch(error => alert("error"));

            promises.push(deleteDoc(doc(db, "inscription", item.id)))
            const user = await Promise.all(promises);
            let tmp = [...comptes]
            const newtmp = tmp.filter(e => e.id != item.id)


            setComptes(newtmp)
            setloadingDelete(false)
            handleClosesupmsg()
            handleClose()
        } catch (e) {
            setloadingDelete(false)
            console.log("echec !!")
        }
    }

    // confirmation
    const [done, setdone] = useState(false);
    const [confirmationValues, setconfirmationValues] = useState();
    const [confirmation, setconfirmation] = React.useState(false);
    const handleOpenConfirmation = (item, values) => {
        setconfirmationValues(values);
        setconfirmation(true);
    };
    const handleCloseConfirmation = () => setconfirmation(false);

    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event) => {
        event.preventDefault();
    };

    return (
        <Container style={{ height: 630, width: '100%' }}>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={confirmation}
                onClose={handleCloseConfirmation}>
                <DialogTitle>
                    <strong>Confirmation</strong>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        êtes-vous sûr de vouloir confirmer cette modification ?{' '}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        autoFocus
                        color="inherit"
                        onClick={handleCloseConfirmation}>
                        Annuler
                    </Button>
                    <LoadingButton loading={done} variant="contained" onClick={editData}>
                        Confirmer
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={supmsg}
                onClose={handleClosesupmsg}
            >
                <DialogTitle>
                    <strong>
                        Supprimer
                    </strong>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        êtes-vous sûr de vouloir supprimer cette inscription ? <br />
                        id :
                        <strong>
                            {item.id}
                        </strong>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" autoFocus color="inherit" onClick={handleClosesupmsg}>
                        Annuler
                    </Button>
                    <LoadingButton loading={loadingDelete} variant="contained" style={{ backgroundColor: "#d50000" }} onClick={supprimer} startIcon={<Delete />}>
                        Supprimer
                    </LoadingButton>
                </DialogActions>

            </Dialog>
            <Dialog
                scroll='body'
                fullWidth
                maxWidth="md"
                open={open}
                onClose={handleClose}
            >
                <Card >

                    <Container sx={{ mt: 3 }}>
                        <Stack justifyContent={"space-between"} direction="row" alignItems={"center"}>
                            <Typography variant='h4'>
                                <strong>
                                    Informations du compte
                                </strong>
                            </Typography>
                            <Button variant="contained" style={{ backgroundColor: "#d50000" }} onClick={handleOpensupmsg} startIcon={<Delete />}>
                                Supprimer
                            </Button>

                        </Stack>

                        <CardContent>
                            {
                                item.nom == "AdMin" ?
                                    <div>
                                        <div className='col'>
                                            <Typography>
                                                <strong><i style={{ color: color }} className="bi bi-file-person-fill"></i> Role :</strong> Admin
                                            </Typography>

                                        </div>
                                    </div>
                                    :
                                    <DialogInscription item={item}></DialogInscription>

                            }


                            <Divider
                                sx={{ mt: 1, mb: 1, color: color, borderBottomWidth: 3 }}
                                variant="middle"
                            ></Divider>
                            <Formik
                                initialValues={{
                                    password: item.password,
                                    etat: item.etat,
                                    montant: item.montant,
                                    versement: item.versement,
                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        password: Yup.string().min(6, "Minimum 6 caractères").required('champ obligatoire'),
                                        // places: Yup.number().integer().required('champ obligatoire'),
                                        // price: Yup.number().integer().required('champ obligatoire'),

                                        // include: Yup.string().max(255).required('champ obligatoire'),
                                    }

                                    )
                                }
                                onSubmit={(values) => {
                                    handleOpenConfirmation(item, values)
                                }}
                            >
                                {({

                                    setFieldValue,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting, isValid
                                }) => (
                                    <Form>
                                        {
                                            item.nom == "AdMin" ?
                                                <div>

                                                </div> :
                                                <div className='row'>

                                                    <TextField
                                                        size="small"
                                                        margin='dense'
                                                        select

                                                        label="Etat"
                                                        name="etat"
                                                        onChange={(event, value) => {
                                                            setFieldValue("etat", event.target.value)
                                                            setChange(true)
                                                        }}
                                                        value={values.etat}
                                                        variant="outlined"
                                                    >
                                                        <MenuItem value={"done"}><CheckCircleIcon color={"success"}></CheckCircleIcon> <strong> Vérifié</strong> </MenuItem>
                                                        {
                                                            item.created ?
                                                                <MenuItem value={"cancelled"}><DoDisturbOnIcon color="error"></DoDisturbOnIcon><strong> Bloqué</strong></MenuItem>

                                                                :
                                                                <MenuItem value={"waiting"}><HourglassTopIcon color={"warning"}></HourglassTopIcon><strong>  En traitement</strong></MenuItem>
                                                        }

                                                    </TextField>
                                                </div>}
                                        {
                                            values.etat == "done" &&
                                            <div>
                                                <div className='row'>
                                                    <FormControl sx={{ mt: 1, mb: 1 }} variant="outlined" >
                                                        <InputLabel htmlFor="outlined-adornment-password">Mot de passe</InputLabel>
                                                        <OutlinedInput
                                                            size="small"
                                                            margin='dense'
                                                            value={values.password}
                                                            id="outlined-adornment-password"
                                                            type={showPassword ? 'text' : 'password'}
                                                            name="password"
                                                            onChange={(event, value) => {
                                                                setFieldValue("password", event.target.value)
                                                                setChange(true)

                                                            }}
                                                            endAdornment={
                                                                <InputAdornment position="end">
                                                                    <IconButton
                                                                        aria-label="toggle password visibility"
                                                                        onClick={handleClickShowPassword}
                                                                        // onMouseDown={handleMouseDownPassword}
                                                                        edge="end"
                                                                    >
                                                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                                                    </IconButton>
                                                                </InputAdornment>
                                                            }
                                                            label="Password"
                                                        />
                                                    </FormControl>

                                                </div>

                                                <div className='row'>

                                                    <TextField
                                                        size="small"
                                                        margin='dense'
                                                        label="Montant (DA)"
                                                        name="montant"
                                                        onChange={(event, value) => {
                                                            setFieldValue("montant", event.target.value)

                                                            setChange(true)
                                                        }}
                                                        value={values.montant}
                                                        variant="outlined"
                                                        type='number'
                                                    >


                                                    </TextField>
                                                </div>

                                                <div className='row'>

                                                    <TextField
                                                        size="small"
                                                        margin='dense'
                                                        label="Crédit (DA)"
                                                        name="versement"
                                                        onChange={(event, value) => {
                                                            setFieldValue("versement", event.target.value)
                                                            setChange(true)
                                                        }}
                                                        value={values.versement}
                                                        variant="outlined"
                                                        type='number'
                                                    >


                                                    </TextField>
                                                </div>



                                            </div>
                                        }

                                        {
                                            change && (
                                                <DialogActions>
                                                    <Button
                                                        disabled={!isValid}
                                                        variant='contained'
                                                        type='submit'
                                                    >Modifier</Button>
                                                </DialogActions>
                                            )
                                        }
                                    </Form>)}
                            </Formik>

                        </CardContent>

                    </Container>
                </Card>



            </Dialog>
            <Stack
                direction={"row"}
                alignItems="center"
                justifyContent="space-between"
            >
                <Typography variant='h3' mb={2}>
                    <strong>
                        <i style={{ color: color }} className="bi bi-people-fill"></i> Inscriptions
                    </strong>
                </Typography>
                <Button onClick={() =>
                    setfilter(!filter)
                }

                    style={{ backgroundColor: color }} variant='contained' endIcon={<FilterList ></FilterList>}>filter</Button>

            </Stack>

            <Collapse in={filter}>
                <Card>

                    <CardContent>
                        <Typography variant='h6' mb={2}>
                            <strong>
                                <i style={{ color: color }} className="bi bi-search"></i> Filter
                            </strong>
                        </Typography>

                        <Formik
                            initialValues={{
                                agence: "",



                            }}
                            validationSchema={
                                Yup.object().shape({
                                    // agence: Yup.string().max(255).required('champ obligatoire'),
                                    // name: Yup.string().max(255).required('champ obligatoire'),
                                }

                                )
                            }
                            onSubmit={(values) => {
                                handleFilter(values)
                            }}
                        >
                            {({

                                setFieldValue,
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting, isValid
                            }) => (
                                <Form>
                                    <div className="row">
                                        <div className="col">
                                            <TextField

                                                error={Boolean(touched.agence && errors.agence)}
                                                fullWidth
                                                helperText={touched.agence && errors.agence}
                                                label="agence"
                                                margin="normal"
                                                name="agence"
                                                onBlur={handleBlur}
                                                onChange={handleChange}
                                                value={values.agence}
                                                variant="outlined"
                                            />


                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className='col'>

                                            <Button type='submit' style={{ backgroundColor: color }} variant='contained'>Appliquer</Button>
                                            <IconButton onClick={() => {
                                                setFieldValue("agence", "")
                                                setFieldValue("name", "")
                                                getData()
                                            }}>
                                                <Refresh></Refresh>
                                            </IconButton>
                                        </div>
                                    </div>
                                </Form>)}
                        </Formik>
                    </CardContent>

                </Card>
            </Collapse>

            <DataGrid
                sx={{ mt: 2 }}
                disableColumnMenu
                disableColumnFilter
                rows={comptes}
                columns={columns}
                pageSize={10}
                rowsPerPageOptions={[5]}
                onCellClick={(cell) => {
                    setItem(cell.row)
                    setShowPassword(false)
                    handleOpen()
                }}
                components={{
                    NoRowsOverlay: () => (
                        <Stack height="100%" alignItems="center" justifyContent="center">
                            <Alert severity="error">
                                Aucune inscription trouvé !
                            </Alert>
                        </Stack>
                    ),
                    NoResultsOverlay: () => (
                        <Alert severity="error">
                            Aucune inscription trouvé !
                        </Alert>
                    )
                }}
            />
        </Container >
    );
}