import React from 'react';
import { Redirect } from 'react-router-dom';
import { UserAuth } from './UserContext';

const ProtectedRouteClient = ({ children }) => {
    const { user, logout } = UserAuth();
    let d = JSON.parse(sessionStorage.getItem("verison"))

    if (d == null || d.displayName != "client") {
        logout()
        return <Redirect to={`${process.env.PUBLIC_URL}/signin`} />;
    }
    return children;
};

export default ProtectedRouteClient;