import { Add, ArrowForward, Delete, UploadFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Card, CardActionArea, CardContent, Collapse, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Input, Stack, TextField, Typography } from '@mui/material';
import { collection, deleteDoc, doc, getDocs, setDoc } from 'firebase/firestore';
import { deleteObject, getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { cleanData, db, storage } from '../../../../Firebase';


export default function ListPays() {
    ///   ajouter un pays
    const [addPays, setAddPays] = React.useState(false);
    const handleAddPays = async () => {

        setAddPays(true);
    }
    const handleCloseAddPays = () => setAddPays(false);

    const [collapse, setCollapse] = useState(false)


    const [docRef, setDocRef] = React.useState(null)
    const [pays, setpays] = React.useState({})


    const [progress, setProgress] = React.useState(0);

    const [listvisa, setListvisa] = React.useState([]);
    const [inclueHover, setInclueHover] = React.useState([false]);
    const [filesHover, setFilesHover] = React.useState([false]);

    // type
    const [typeCollapse, settypeCollapse] = useState(false);
    const [listType, setlistType] = useState([]);



    ///////////////////////
    const getListPays = async () => {
        setListvisa([])
        await getDocs(collection(db, "listvisa"))
            .then((querySnapshot) => {
                const newData = cleanData(querySnapshot)
                setListvisa(newData)
            }).catch((err) => {
                console.log(err);
            })
    }

    const ajouterPays = async (values, actions) => {
        setdone(true)
        const promises = [];


        //    reference de l image
        const imageRef = ref(storage, "images/visa/" + docRef)
        promises.push(uploadBytesResumable(imageRef, values.flag).then((uploadResult) => { return getDownloadURL(uploadResult.ref) }));


        // waiting 
        const photos = await Promise.all(promises);

        const visa = {
            pays: values.pays,
            description: values.description,
            types: values.types,
            flag: photos,
            files: values.files
        }

        // add document
        await setDoc(doc(db, "listvisa", docRef), visa).then(() => {
            getListPays()
            setCollapse(!collapse)
            actions.resetForm()
            setdone(false)

        })


    }

    const [loadingDelete, setloadingDelete] = useState(false);
    const [supPays, setsupPays] = React.useState(false);
    const handleOpenSupPays = () => setsupPays(true);
    const handleCloseSupPays = () => setsupPays(false);
    const supprimerPays = async () => {
        setloadingDelete(true)
        await deleteDoc(doc(db, "listvisa", pays.id));

        const imgRef = ref(storage, 'images/visa/' + pays.id);

        // Delete the file
        await deleteObject(imgRef).then(() => {
            getListPays()
            handleCloseSupPays()
            handleCloseAddPays()
            setloadingDelete(false)

        }).catch((error) => {
            getListPays()
            handleCloseSupPays()
            handleCloseAddPays()
            setloadingDelete(false)

            console.log("erreur!");
        });

    }

    // confirmation
    const [done, setdone] = useState(false);
    const [confirmationValues, setconfirmationValues] = useState();
    const [confirmation, setconfirmation] = React.useState(false);
    const handleOpenConfirmation = (item, values) => {
        setconfirmationValues(values);
        setconfirmation(true);
    };
    const handleCloseConfirmation = () => setconfirmation(false);
    const [change, setChange] = React.useState(false);
    const [newValue, setnewValue] = useState();


    useEffect(() => {
        getListPays()
    }, []);

    return (
        <div>

            <Dialog
                fullWidth
                maxWidth="sm"
                open={supPays}
                onClose={handleCloseSupPays}
            >
                <DialogTitle>
                    <strong>
                        Supprimer
                    </strong>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        êtes-vous sûr de vouloir supprimer cette visa ?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" autoFocus color="inherit" onClick={handleCloseSupPays}>
                        Annuler
                    </Button>
                    <LoadingButton loading={loadingDelete} variant="contained" style={{ backgroundColor: "#d50000" }} onClick={supprimerPays} startIcon={<Delete />}>
                        Supprimer
                    </LoadingButton>
                </DialogActions>

            </Dialog>
            <Dialog
                fullWidth
                maxWidth="sm"
                open={addPays}
                onClose={handleCloseAddPays}>
                <DialogTitle>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"

                    >
                        <strong>Informations</strong>
                        <Button
                            variant='contained'
                            color='error'
                            endIcon={<Delete></Delete>}
                            onClick={handleOpenSupPays}
                        >
                            Supprimer
                        </Button>
                    </Stack>
                </DialogTitle>
                <DialogContent>
                    <Typography>
                        Pays : <strong>{pays.pays}</strong>
                    </Typography>

                    <Typography>
                        Conditions : <br />

                        <strong>{pays.description}</strong>
                    </Typography>
                    <Typography>
                        Types visa :
                        <ul>
                            <Container>
                                {pays.types && pays.types.map((type, index) =>
                                    <li key={index}>
                                        <ArrowForward sx={{ color: "#ff4838" }}></ArrowForward> {type.type + " | Prix : " + type.prix + " DZD" + " | Durée : " + type.duree + " Jours"}
                                    </li>
                                )}
                            </Container>
                        </ul>
                    </Typography>
                    <Typography>
                        Fichiers :
                        <ul>
                            <Container>
                                {pays.files && pays.files.map((file, index) =>
                                    <li key={index}>
                                        <UploadFile sx={{ color: "#ff4838" }}></UploadFile> {file}
                                    </li>
                                )}
                            </Container>
                        </ul>
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        autoFocus
                        color="inherit"
                        onClick={handleCloseAddPays}>
                        Fermer
                    </Button>

                </DialogActions>
            </Dialog>
            <div

            >

                <Container>
                    <Button
                        onClick={async () => {
                            const reference = await doc(collection(db, "listvisa"))
                            setDocRef(reference.id);


                            getListPays()

                            setCollapse(!collapse)
                        }}
                        variant="contained"
                        sx={{
                            backgroundColor: "#ff4838", ':hover': {
                                bgcolor: '#ff4860', // theme.palette.primary.main
                                color: 'white',
                            }
                        }}
                    >
                        Ajouter un pays +
                    </Button>
                    <Collapse in={collapse}>
                        <Formik
                            initialValues={{
                                pays: "",
                                temp: "",
                                description: "",
                                duree: 1,
                                types: [],
                                tempType: "",
                                prixType: 0,
                                files: [],
                                flag: null,
                            }}
                            validationSchema={
                                Yup.object().shape({
                                    pays: Yup.string().max(255).required('champ obligatoire'),
                                    description: Yup.string().max(4000, "maximum 4000 caractères"),
                                    duree: Yup.number().min(1, "min 1 jour").required("champ obligatoire"),
                                    types: Yup.array().min(1, "Ajouter au mois un type"),
                                    tempType: Yup.string().max(255),

                                    files: Yup.array().min(1, "Ajouter au mois un nom de fichier"),
                                    flag: Yup.mixed()
                                        .required('Fichier obligatoire')
                                        .test(
                                            'flag',
                                            'Taille de fichier (taille de fichier < 2mb)',
                                            (value) => {

                                                return (
                                                    value.size < 2000000

                                                );
                                            }
                                        )
                                        .test(
                                            'flag',
                                            'Format accepté: .jpeg, .jpg, .png',
                                            (value) => {
                                                return (
                                                    value &&
                                                    (value.type ===
                                                        'image/jpeg' ||
                                                        value.type ===
                                                        'image/jpg' ||
                                                        value.type ===
                                                        'image/png')
                                                );
                                            }
                                        ),
                                }

                                )
                            }
                            onSubmit={(values, actions) => {
                                ajouterPays(values, actions)

                            }}
                        >
                            {({

                                setFieldValue,
                                values,
                                errors,
                                touched,
                                handleChange,
                                handleBlur,
                                handleSubmit,
                                isSubmitting, isValid
                            }) => (
                                <Form>
                                    <div class="row">
                                        <div class="col">
                                            <div className='row'>
                                                <div className='col'>

                                                    <TextField
                                                        required
                                                        error={Boolean(touched.pays && errors.pays)}
                                                        fullWidth
                                                        helperText={touched.pays && errors.pays}
                                                        label="Pays"
                                                        margin="normal"
                                                        name="pays"
                                                        onBlur={handleBlur}
                                                        onChange={handleChange}
                                                        value={values.pays}
                                                        variant="outlined"
                                                    />
                                                </div>

                                            </div>

                                            <div className='row'>
                                                <div className='col'>

                                                    <TextField
                                                        error={Boolean(touched.description && errors.description)}
                                                        fullWidth
                                                        helperText={touched.description && errors.description}
                                                        label="Conditions"
                                                        margin="normal"
                                                        name="description"
                                                        onBlur={handleBlur}
                                                        multiline
                                                        rows={4}
                                                        onChange={handleChange}
                                                        value={values.description}
                                                        variant="outlined"
                                                    />
                                                </div>

                                            </div>

                                            <div class="row">
                                                <Stack
                                                    direction={"row"}
                                                    alignItems="center"
                                                >
                                                    <TextField
                                                        error={Boolean(touched.types && errors.types)}
                                                        fullWidth
                                                        helperText={touched.types && errors.types}
                                                        label="Type"
                                                        margin="normal"
                                                        name="tempType"
                                                        onBlur={handleBlur}

                                                        onChange={(event) => {
                                                            setFieldValue("tempType", event.target.value)
                                                        }}
                                                        value={values.tempType}
                                                        variant="outlined"
                                                    />
                                                    <TextField
                                                        error={Boolean(touched.prixType && errors.prixType)}
                                                        fullWidth
                                                        helperText={touched.prixType && errors.prixType}
                                                        label="Prix Type"
                                                        margin="normal"
                                                        name="prixType"
                                                        onBlur={handleBlur}

                                                        onChange={(event) => {
                                                            setFieldValue("prixType", event.target.value)
                                                        }}
                                                        value={values.prixType}
                                                        variant="outlined"
                                                        type="number"
                                                        inputProps={{ min: 0 }}
                                                    />


                                                </Stack>
                                                <div className='row'>
                                                    <Stack
                                                        direction={"row"}
                                                        alignItems="center"
                                                    >

                                                        <TextField
                                                            error={Boolean(touched.duree && errors.duree)}
                                                            fullWidth
                                                            helperText={touched.duree && errors.duree}
                                                            label="Durée de traitement (jours)"
                                                            margin="normal"
                                                            name="duree"
                                                            type="number"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.duree}
                                                            variant="outlined"
                                                            inputProps={{
                                                                min: 1
                                                            }}
                                                        />
                                                        <IconButton aria-label="add" onClick={(event, value) => {
                                                            let tmp = [...values.types]
                                                            if (values.tempType != "") {
                                                                tmp.push({ type: values.tempType, prix: values.prixType, duree: values.duree })
                                                                setFieldValue("types", tmp)
                                                                setFieldValue("tempType", "")
                                                                setFieldValue("prixType", 0)
                                                                setFieldValue("duree", 1)
                                                                let x = [...inclueHover]
                                                                x.push(false)
                                                                setFilesHover(x)
                                                            }
                                                        }}>
                                                            <Add />
                                                        </IconButton>
                                                    </Stack>
                                                </div>

                                                <div className="row">
                                                    <Container >
                                                        {
                                                            values.types.length > 0 ?
                                                                <Typography variant="body1">
                                                                    <strong>
                                                                        Types :
                                                                    </strong>
                                                                </Typography>
                                                                :
                                                                <></>
                                                        }
                                                        <ul>
                                                            {
                                                                values.types.map((inc, index) =>
                                                                    <li className="mt-2 mb-2">
                                                                        <Card >
                                                                            <CardActionArea>
                                                                                <CardContent>
                                                                                    <Stack
                                                                                        direction="row"
                                                                                        alignItems="center"
                                                                                        justifyContent="space-between"
                                                                                    >
                                                                                        <div>
                                                                                            <i className="bi bi-check2" />

                                                                                            {"  " + inc.type}
                                                                                            {" | " + inc.prix + " DZD"}
                                                                                            {" | " + inc.duree + " Jours"}


                                                                                        </div>
                                                                                        <IconButton
                                                                                            sx={{
                                                                                                '&:hover': {
                                                                                                    backgroundColor: 'red',
                                                                                                    opacity: 0.4,
                                                                                                },
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                let tmp = [...values.types]
                                                                                                tmp = tmp.filter(item => item != inc)
                                                                                                setFieldValue("types", tmp)

                                                                                            }}
                                                                                        >
                                                                                            <Delete></Delete>
                                                                                        </IconButton>
                                                                                    </Stack>
                                                                                </CardContent>
                                                                            </CardActionArea>
                                                                        </Card>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>

                                                    </Container>

                                                </div>

                                            </div>
                                            <div class="row">
                                                <Stack direction={"row"}>
                                                    <TextField
                                                        error={Boolean(touched.files && errors.files)}
                                                        fullWidth
                                                        helperText={touched.files && errors.files}
                                                        label="Fichies"
                                                        margin="normal"
                                                        name="temp"
                                                        onBlur={handleBlur}

                                                        onChange={(event) => {
                                                            setFieldValue("temp", event.target.value)
                                                        }}
                                                        value={values.temp}
                                                        variant="outlined"
                                                    />
                                                    <IconButton aria-label="add" onClick={(event, value) => {
                                                        let tmp = [...values.files]
                                                        if (values.temp != "") {
                                                            tmp.push(values.temp)
                                                            setFieldValue("files", tmp)
                                                            setFieldValue("temp", "")
                                                            let x = [...inclueHover]
                                                            x.push(false)
                                                            setFilesHover(x)
                                                        }
                                                    }}>
                                                        <Add />
                                                    </IconButton>

                                                </Stack>

                                                <div className="row">
                                                    <Container >
                                                        {
                                                            values.files.length > 0 ?
                                                                <Typography variant="body1">
                                                                    <strong>
                                                                        Fichiers :
                                                                    </strong>
                                                                </Typography>
                                                                :
                                                                <></>

                                                        }
                                                        <ul>
                                                            {
                                                                values.files.map((inc, index) =>
                                                                    <li className="mt-2 mb-2">
                                                                        <Card >
                                                                            <CardActionArea>
                                                                                <CardContent>
                                                                                    <Stack
                                                                                        direction="row"
                                                                                        alignItems="center"
                                                                                        justifyContent="space-between"
                                                                                    >
                                                                                        <div>
                                                                                            <i className="bi bi-check2" />

                                                                                            {"  " + inc}

                                                                                        </div>
                                                                                        <IconButton
                                                                                            sx={{
                                                                                                '&:hover': {
                                                                                                    backgroundColor: 'red',
                                                                                                    opacity: 0.4,
                                                                                                },
                                                                                            }}
                                                                                            onClick={() => {
                                                                                                let tmp = [...values.files]
                                                                                                tmp = tmp.filter(item => item != inc)
                                                                                                setFieldValue("files", tmp)

                                                                                            }}
                                                                                        >
                                                                                            <Delete></Delete>
                                                                                        </IconButton>
                                                                                    </Stack>
                                                                                </CardContent>
                                                                            </CardActionArea>
                                                                        </Card>
                                                                    </li>
                                                                )
                                                            }
                                                        </ul>

                                                    </Container>

                                                </div>

                                            </div>


                                        </div>
                                        <div class="col">
                                            <Input
                                                id="contained-button-file"
                                                style={{ display: "none" }}
                                                onChange={value => {
                                                    setFieldValue("flag", value.currentTarget.files[0]);
                                                }}
                                                name="flag"
                                                inputProps={{
                                                    accept: 'image/jpg , image/jpeg , image/png ',
                                                }}
                                                type="file"
                                            />

                                            <Card sx={{ maxWidth: 250, maxHeight: 250, mt: 2 }}>
                                                <CardActionArea>

                                                    {
                                                        values.flag == null ?
                                                            <label htmlFor="contained-button-file" style={{ width: 150, height: 150 }}>

                                                                <Stack
                                                                    direction="row"
                                                                    justifyContent="center"
                                                                    alignItems="center"
                                                                    style={{ width: 250, height: 150 }}
                                                                >
                                                                    <strong>Drapeau</strong>   <Add></Add>

                                                                </Stack>
                                                            </label>

                                                            :


                                                            <label htmlFor="contained-button-file" style={{ width: 250, height: 150 }}>

                                                                <img
                                                                    style={{ width: 250, height: 150 }}
                                                                    src={URL.createObjectURL(values.flag)}
                                                                />
                                                            </label>
                                                    }
                                                </CardActionArea>
                                            </Card>
                                            {touched.flag &&
                                                errors.flag && (
                                                    <Typography
                                                        color="error"
                                                        variant="caption">
                                                        {
                                                            errors.flag
                                                        }
                                                    </Typography>
                                                )}
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className='col'>
                                            <LoadingButton loading={done} variant="contained" type='submit' startIcon={<Add />}>
                                                Ajouter
                                            </LoadingButton>
                                        </div>
                                    </div>




                                </Form>
                            )}

                        </Formik>

                    </Collapse>
                    {
                        !collapse &&
                        <div className="row mt-3">


                            <ul>
                                {
                                    listvisa.map((inc, index) =>
                                        <li className="mt-2 mb-2">
                                            <Card
                                                // onMouseEnter={() => {
                                                //     let x = inclueHover.map(i => false)
                                                //     x[index] = true
                                                //     setInclueHover(x)
                                                // }}
                                                // onMouseLeave={() => {
                                                //     let x = inclueHover.map(i => false)
                                                //     setInclueHover(x)
                                                // }}

                                                sx={{
                                                    '&:hover': {
                                                        backgroundColor: 'white',
                                                        opacity: 0.4,
                                                    },
                                                }}
                                            >
                                                <CardActionArea
                                                    onClick={() => {
                                                        setpays(inc)
                                                        handleAddPays()
                                                    }}
                                                >
                                                    <CardContent>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="space-between"
                                                            spacing={4}
                                                        >
                                                            <Stack
                                                                direction="row"
                                                                alignItems="center"
                                                                justifyContent="space-between"
                                                                spacing={4}>
                                                                <img src={process.env.PUBLIC_URL + inc.flag} alt="images" width={50} />
                                                                <Typography variant="h6">
                                                                    <strong>
                                                                        {inc.pays}
                                                                    </strong>
                                                                </Typography>
                                                            </Stack>
                                                            <Typography variant="h6">
                                                                nbtypes : {inc.types && inc.types.length}
                                                            </Typography>
                                                        </Stack>




                                                    </CardContent>
                                                </CardActionArea>
                                            </Card>
                                        </li>
                                    )
                                }
                            </ul>


                        </div>
                    }

                </Container>




            </div>
        </div>
    )
}
