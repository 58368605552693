/** @format */

import { Alert, Button, Typography } from '@mui/material';
import {
	collection,
	documentId,
	getDocs,
	query,
	where,
} from 'firebase/firestore';
import React, { useEffect, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { SRLWrapper } from 'simple-react-lightbox';
import { db, storage } from '../../../Firebase';
import DetailTable from '../../common/DetailTable';
import { UserAuth } from '../../context/UserContext';
import BookingTable from './BookingTable';
import { saveAs } from 'file-saver';
import { getBlob, getDownloadURL, ref } from 'firebase/storage';

function PackageDetailsWrap() {
	const location = useLocation();
	useEffect(() => {
		if (location.state == undefined) {
			return navigate.goBack();
		}
		setprogramme(location.state.programme);
		getData();
	}, []);

	const [isClient, setIsClient] = useState(true);

	const [programme, setprogramme] = useState({});

	const getData = async () => {
		const detailRef = collection(db, 'destination_detail');
		const q = await query(
			detailRef,
			where(documentId(), '==', location.state.programme.id)
		);
		const querySnapshot = await getDocs(q);
		querySnapshot.forEach((doc) => {
			setprogramme({ ...doc.data(), id: doc.id });
		});
		// const newData = cleanData(querySnapshot)
		// setprogramme(newData)
	};

	const [usr, setusr] = useState({});

	const { user, getUsr } = UserAuth();

	let navigate = useHistory();

	const [snake, setSnake] = React.useState(false);
	const [isError, setIsError] = React.useState(false);
	const handleSnake = async () => {
		setSnake(true);
	};
	const handleCloseSnake = () => setSnake(false);

	const onDownload = (url) => {
		let file = 'images/destination/' + programme.id + '/pdf';

		getBlob(ref(storage, file))
			.then((blob) => {
				const href = URL.createObjectURL(blob);
				const a = Object.assign(document.createElement('a'), {
					href,
					style: 'display:none',
					download: 'details.pdf', // This is where you set the name of the file you're about to download
				});
				a.click();

				URL.revokeObjectURL(href);
				a.remove();
			})
			.catch((error) => {
				console.error(error);
			});
	};

	return (
		<>
			<div className="package-details-wrapper pt-110">
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<div className="tour-package-details">
								<div className="pd-header">
									<div className=" pd-top row row-cols-lg-4 row-cols-md-2 row-cols-2 gy-4">
										<div className="col">
											<div className="pd-single-info">
												<div className="info-icon">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														style={{
															color: '#ff4838',
														}}
														width="35"
														height="35"
														fill="currentColor"
														className="bi bi-calendar-week"
														viewBox="0 0 16 16">
														<path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z" />
														<path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z" />
													</svg>
												</div>
												<div className="info">
													<h6>Durée</h6>
													<span>
														{programme?.duration}
													</span>
												</div>
											</div>
										</div>
										<div className="col">
											<div className="pd-single-info">
												<div className="info-icon">
													<svg
														xmlns="http://www.w3.org/2000/svg"
														style={{
															color: '#ff4838',
														}}
														width="35"
														height="35"
														fill="currentColor"
														className="bi bi-globe-europe-africa"
														viewBox="0 0 16 16">
														<path d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0ZM3.668 2.501l-.288.646a.847.847 0 0 0 1.479.815l.245-.368a.809.809 0 0 1 1.034-.275.809.809 0 0 0 .724 0l.261-.13a1 1 0 0 1 .775-.05l.984.34c.078.028.16.044.243.054.784.093.855.377.694.801-.155.41-.616.617-1.035.487l-.01-.003C8.274 4.663 7.748 4.5 6 4.5 4.8 4.5 3.5 5.62 3.5 7c0 1.96.826 2.166 1.696 2.382.46.115.935.233 1.304.618.449.467.393 1.181.339 1.877C6.755 12.96 6.674 14 8.5 14c1.75 0 3-3.5 3-4.5 0-.262.208-.468.444-.7.396-.392.87-.86.556-1.8-.097-.291-.396-.568-.641-.756-.174-.133-.207-.396-.052-.551a.333.333 0 0 1 .42-.042l1.085.724c.11.072.255.058.348-.035.15-.15.415-.083.489.117.16.43.445 1.05.849 1.357L15 8A7 7 0 1 1 3.668 2.501Z" />
													</svg>
												</div>
												<div className="info">
													<h6>Type</h6>
													<span>
														{programme?.type}
													</span>
												</div>
											</div>
										</div>

										<div className="col">
											<div className="pd-single-info">
												<div
													className="info-icon"
													style={{
														color: '#ff4838',
													}}>
													<svg
														xmlns="http://www.w3.org/2000/svg"
														width="35"
														height="35"
														fill="currentColor"
														className="bi bi-cash-coin"
														viewBox="0 0 16 16">
														<path
															fillRule="evenodd"
															d="M11 15a4 4 0 1 0 0-8 4 4 0 0 0 0 8zm5-4a5 5 0 1 1-10 0 5 5 0 0 1 10 0z"
														/>
														<path d="M9.438 11.944c.047.596.518 1.06 1.363 1.116v.44h.375v-.443c.875-.061 1.386-.529 1.386-1.207 0-.618-.39-.936-1.09-1.1l-.296-.07v-1.2c.376.043.614.248.671.532h.658c-.047-.575-.54-1.024-1.329-1.073V8.5h-.375v.45c-.747.073-1.255.522-1.255 1.158 0 .562.378.92 1.007 1.066l.248.061v1.272c-.384-.058-.639-.27-.696-.563h-.668zm1.36-1.354c-.369-.085-.569-.26-.569-.522 0-.294.216-.514.572-.578v1.1h-.003zm.432.746c.449.104.655.272.655.569 0 .339-.257.571-.709.614v-1.195l.054.012z" />
														<path d="M1 0a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h4.083c.058-.344.145-.678.258-1H3a2 2 0 0 0-2-2V3a2 2 0 0 0 2-2h10a2 2 0 0 0 2 2v3.528c.38.34.717.728 1 1.154V1a1 1 0 0 0-1-1H1z" />
														<path d="M9.998 5.083 10 5a2 2 0 1 0-3.132 1.65 5.982 5.982 0 0 1 3.13-1.567z" />
													</svg>
												</div>
												<div className="info">
													<h6>Prix</h6>
													<span>
														{programme.prixGenerale &&
															programme.prixGenerale}{' '}
														DA
													</span>
												</div>
											</div>
										</div>
									</div>
									<div className="pd-thumb">
										<img
											src={programme.image}
											alt="PackageIMG"
											height={600}
										/>
									</div>
									<div className="header-bottom">
										<div className="pd-lavel d-flex justify-content-between align-items-center flex-wrap gap-2">
											<h5 className="location">
												<i className="bi bi-geo-alt" />
												{programme.destination}
											</h5>
										</div>
										<h2 className="pd-title">
											{programme.title}
										</h2>
									</div>
								</div>
								<div className="package-details-tabs">
									<ul
										className="nav nav-pills tab-switchers gap-xxl-4 gap-3"
										id="pills-tab"
										role="tablist">
										<li
											className="nav-item"
											role="presentation">
											<button
												className="nav-link active"
												id="pills-package1"
												data-bs-toggle="pill"
												data-bs-target="#pill-body1"
												type="button"
												role="tab"
												aria-controls="pill-body1"
												aria-selected="true">
												<i className="bi bi-info-lg" />{' '}
												Information
											</button>
										</li>
										{programme.pdf && (
											<li
												className="nav-item"
												role="presentation">
												<button
													onClick={() =>
														onDownload(
															programme.pdf
														)
													}
													// href={programme.pdf}
													// target="_blank"
													// download
													className="nav-link">
													<i className="bi bi-file-earmark-spreadsheet" />
													Fichier
												</button>
											</li>
										)}

										<li
											className="nav-item"
											role="presentation">
											<button
												className="nav-link"
												id="pills-package3"
												data-bs-toggle="pill"
												data-bs-target="#pill-body3"
												type="button"
												role="tab"
												aria-controls="pill-body3"
												aria-selected="false">
												<i className="bi bi-images" />
												Gallary
											</button>
										</li>
										{user ? (
											<li
												className="nav-item"
												role="presentation">
												<button
													className="nav-link"
													id="pills-package4"
													data-bs-toggle="pill"
													data-bs-target="#pill-body4"
													type="button"
													role="tab"
													aria-controls="pill-body4"
													aria-selected="false">
													<i className="bi bi-people-fill"></i>{' '}
													Réservation
												</button>
											</li>
										) : (
											<div></div>
										)}
									</ul>
									<div
										className="tab-content"
										id="pills-tabContent">
										{/* package info tab */}
										<div
											className="tab-pane fade show active package-info-tab mt-5"
											id="pill-body1"
											role="tabpanel"
											aria-labelledby="pills-package1">
											<h3 className="d-subtitle">
												Details d'offre
											</h3>
											<Typography
												variant="h6"
												component="div"
												sx={{
													display: '-webkit-box',
													boxOrient: 'vertical',
													wordBreak: 'break-all',
													overflow: 'hidden',
													color: 'black',
												}}>
												<p>{programme.description}</p>
											</Typography>

											<DetailTable
												programme={
													programme
												}></DetailTable>
										</div>

										<div
											className="tab-pane fade package-gallary-tab"
											id="pill-body3"
											role="tabpanel"
											aria-labelledby="pills-package3">
											<SRLWrapper>
												<div className="row g-4">
													{programme.gallary &&
														programme.gallary.map(
															(img, index) => (
																<div
																	className="col-6"
																	key={index}>
																	<div className="package-gallary-item">
																		<a
																			href={
																				img
																			}
																			data-fancybox="gallery"
																			data-caption="Caption Here">
																			<img
																				src={
																					img
																				}
																				alt="PackageIMG"
																			/>
																		</a>
																	</div>
																</div>
															)
														)}
												</div>
											</SRLWrapper>
										</div>
										<div
											className="tab-pane fade package-location-tab"
											id="pill-body4"
											role="tabpanel"
											aria-labelledby="pills-package4">
											<div className="container">
												{user ? (
													programme && (
														<BookingTable
															programme={
																programme
															}
															getData={getData}
														/>
													)
												) : (
													<Alert
														severity="error"
														sx={{ width: '100%' }}>
														Se connecter pour
														terminer cette action !
														<Link to={'/signin'}>
															{' '}
															Se conntecter
														</Link>
													</Alert>
												)}
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default PackageDetailsWrap;
