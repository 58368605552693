/** @format */

import { Link, useLocation } from 'react-router-dom';
import React, { useEffect } from 'react';

function Breadcrumb(props) {
	const location = useLocation();

	return (
		<>
			<div
				className="breadcrumb breadcrumb-style-one"
				style={{ paddingTop: '30vh' }}>
				<div className="container">
					<div className="col-lg-12 text-center">
						<h2 className="breadcrumb-title">{props.name}</h2>
						<ul className="d-flex justify-content-center breadcrumb-items">
							<li className="breadcrumb-item">
								<Link to={`${process.env.PUBLIC_URL}/`}>
									Acceuil
								</Link>
							</li>
							<li className="breadcrumb-item active">
								{props.name}
							</li>
						</ul>
						<div style={{ color: 'white' }}>
							{location.state?.type &&
								props.name == 'Destinations' && (
									<h6>{location.state.type.toUpperCase()}</h6>
								)}
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default Breadcrumb;
