import { Alert, Button, CardActions, CardContent, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, Input, MenuItem, Snackbar, Stack, TextField, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';

import { CheckCircle, UploadFile } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { format } from 'date-fns';
import { collection, doc, setDoc } from 'firebase/firestore';
import { getDownloadURL, ref, uploadBytesResumable } from 'firebase/storage';
import React, { useState } from 'react';
import { db, storage } from '../../../Firebase';
import { useHistory } from 'react-router-dom';

export default function Inscription() {

    const addClient = async () => {
        setdone(true)
        let values = confirmationValues
        const reference = await doc(collection(db, "inscriptions"))
        let docRef = reference.id;
        const promises = [];

        let registre_commerce_file_url = ""
        let licence_file_url = ""
        let nif_file_url = ""

        let imageRef = ref(storage, "images/inscription/" + docRef + "/registre_commerce")
        promises.push(
            uploadBytesResumable(imageRef, values.registre_commerce_file).then((uploadResult) => {
                return getDownloadURL(uploadResult.ref).then((res) =>
                    registre_commerce_file_url = res)
            })
        );
        imageRef = ref(storage, "images/inscription/" + docRef + "/licence")
        promises.push(
            uploadBytesResumable(imageRef, values.licence_file).then((uploadResult) => {
                return getDownloadURL(uploadResult.ref).then((res) =>
                    licence_file_url = res)
            })
        );
        imageRef = ref(storage, "images/inscription/" + docRef + "/nif")
        promises.push(
            uploadBytesResumable(imageRef, values.nif_file).then((uploadResult) => {
                return getDownloadURL(uploadResult.ref).then((res) =>
                    nif_file_url = res)
            })
        );

        const files = await Promise.all(promises);


        let client = {
            nom_agence: values.nom_agence.toLowerCase(),
            licence: values.licence,
            licence_file: licence_file_url,
            nif: values.nif,
            nif_file: nif_file_url,
            adresse: values.adresse,
            pays: values.pays,
            ville: values.ville,
            code_postal: values.code_postal,
            telephone: values.telephone,
            fax: values.fax,
            registre_commerce: values.registre_commerce,
            registre_commerce_file: registre_commerce_file_url,
            civilite: values.civilite,
            prenom: values.prenom,
            nom: values.nom,
            mobile: values.mobile,
            email: values.email,
            etat: "waiting",
            created: false,
            montant: "0",
            versement: 0,
            password: "",
            date: format(new Date(), 'dd-MM-yyyy')


        }
        await await setDoc(doc(db, "inscription", docRef), client).then(i => {
            handleSnake()
            setdone(false)
            handleCloseConfirmation()
        })
        setdone(false)

        handleCloseConfirmation()
        history.push({
            pathname:
                '/',
            state: {
                snake: true
            },
        })

    }

    let history = useHistory();

    const [snake, setSnake] = React.useState(false);
    const [isError, setIsError] = React.useState(false);
    const handleSnake = async () => {
        setSnake(true);
    };
    const handleCloseSnake = () => setSnake(false);

    // confirmation
    const [done, setdone] = useState(false);

    const [confirmationValues, setconfirmationValues] = useState();
    const [confirmation, setconfirmation] = React.useState(false);
    const handleOpenConfirmation = (values) => {
        setconfirmationValues(values);
        setconfirmation(true);
    };
    const handleCloseConfirmation = () => setconfirmation(false);

    return (
        <div className="package-sidebar">
            <Dialog
                fullWidth
                maxWidth="sm"
                open={confirmation}
                onClose={handleCloseConfirmation}>
                <DialogTitle>
                    <strong>Confirmation</strong>
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        êtes-vous sûr de vouloir confirmer cette demande ?{' '}
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        autoFocus
                        color="inherit"
                        onClick={handleCloseConfirmation}>
                        Annuler
                    </Button>
                    <LoadingButton loading={done} variant="contained" onClick={addClient}>
                        Confirmer
                    </LoadingButton>
                </DialogActions>
            </Dialog>
            <aside className="package-widget-style-2 widget-form">
                <div className="widget-title text-center d-flex justify-content-between" style={{ paddingTop: "12vh" }}>

                </div>
                <div className="widget-body">
                    <Container >
                        <Snackbar open={snake} autoHideDuration={5000} onClose={handleCloseSnake}>


                            <Alert onClose={handleCloseSnake} severity="success" sx={{ width: '100%' }}>
                                Votre demande d'inscription a été ajouté !
                            </Alert>

                        </Snackbar>

                        <CardContent>

                            <Formik
                                initialValues={{

                                    nom_agence: "",
                                    licence: "",
                                    licence_file: null,
                                    adresse: "",
                                    pays: "",
                                    ville: "",
                                    code_postal: "",
                                    telephone: "",
                                    fax: "",
                                    registre_commerce: "",
                                    registre_commerce_file: null,
                                    civilite: "",
                                    prenom: "",
                                    nom: "",
                                    mobile: "",
                                    email: "",
                                    nif: "",
                                    nif_file: null


                                }}
                                validationSchema={
                                    Yup.object().shape({
                                        nom_agence: Yup.string().max(255).required('champ obligatoire'),
                                        licence: Yup.string().max(255).required('champ obligatoire'),
                                        licence_file: Yup.mixed()
                                            .required('Fichier obligatoire')
                                            .test(
                                                'licence_file',
                                                'Taille de fichier (taille de fichier < 2mb)',
                                                (value) => {
                                                    return (
                                                        value &&
                                                        value.size <= 2000000
                                                    );
                                                }
                                            )
                                            .test(
                                                'type',
                                                'Format accepté: .jpeg, .jpg, .png, .pdf',
                                                (value) => {
                                                    return (
                                                        value &&
                                                        (value.type ===
                                                            'image/jpeg' ||
                                                            value.type ===
                                                            'image/jpg' ||
                                                            value.type ===
                                                            'image/png' ||
                                                            value.type ===
                                                            'application/pdf')
                                                    );
                                                }
                                            ),
                                        adresse: Yup.string().max(255).required('champ obligatoire'),
                                        pays: Yup.string().max(255).required('champ obligatoire'),
                                        ville: Yup.string().max(255).required('champ obligatoire'),
                                        code_postal: Yup.number().min(0).required('champ obligatoire'),
                                        telephone: Yup.string().test('telephone',
                                            "Le numéro est invalide",
                                            (value) => !Number.isNaN(Number(value)) && value.length == 10 && value[0] == "0"),
                                        fax: Yup.string().max(255).required('champ obligatoire'),
                                        registre_commerce: Yup.string().max(255).required('champ obligatoire'),
                                        registre_commerce_file: Yup.mixed()
                                            .required('Fichier obligatoire')
                                            .test(
                                                'registre_commerce_file',
                                                'Taille de fichier (taille de fichier < 2mb)',
                                                (value) => {
                                                    return (
                                                        value &&
                                                        value.size <= 2000000
                                                    );
                                                }
                                            )
                                            .test(
                                                'type',
                                                'Format accepté: .jpeg, .jpg, .png, .pdf',
                                                (value) => {
                                                    return (
                                                        value &&
                                                        (value.type ===
                                                            'image/jpeg' ||
                                                            value.type ===
                                                            'image/jpg' ||
                                                            value.type ===
                                                            'image/png' ||
                                                            value.type ===
                                                            'application/pdf')
                                                    );
                                                }
                                            ),
                                        nif: Yup.string().max(255).required('champ obligatoire'),
                                        nif_file: Yup.mixed()
                                            .required('Fichier obligatoire')
                                            .test(
                                                'nif_file',
                                                'Taille de fichier (taille de fichier < 2mb)',
                                                (value) => {
                                                    return (
                                                        value &&
                                                        value.size <= 2000000
                                                    );
                                                }
                                            )
                                            .test(
                                                'type',
                                                'Format accepté: .jpeg, .jpg, .png, .pdf',
                                                (value) => {
                                                    return (
                                                        value &&
                                                        (value.type ===
                                                            'image/jpeg' ||
                                                            value.type ===
                                                            'image/jpg' ||
                                                            value.type ===
                                                            'image/png' ||
                                                            value.type ===
                                                            'application/pdf')
                                                    );
                                                }
                                            ),
                                        civilite: Yup.string().max(255).required('champ obligatoire'),
                                        prenom: Yup.string().max(255).required('champ obligatoire'),
                                        nom: Yup.string().max(255).required('champ obligatoire'),
                                        mobile: Yup.string().test('mobile',
                                            "Le numéro est invalide",
                                            (value) => !Number.isNaN(Number(value)) && value.length == 10 && value[0] == "0"),
                                        email: Yup.string().email("L'email est invalide").max(255).required('champ obligatoire'),

                                    }

                                    )
                                }
                                onSubmit={(values) => {
                                    handleOpenConfirmation(values)
                                }}
                            >
                                {({

                                    setFieldValue,
                                    values,
                                    errors,
                                    touched,
                                    handleChange,
                                    handleBlur,
                                    handleSubmit,
                                    isSubmitting, isValid
                                }) => (
                                    <Form>
                                        <CardContent id="alert-dialog-title">
                                            <Typography variant='h3'>

                                                <strong>
                                                    Inscrivez votre agence
                                                </strong>
                                            </Typography>
                                            <Typography variant='h6'>

                                                Inscrivez votre agence en remplissant le formulaire, nous prendrons contact avec vous dans les plus brefs délais.

                                            </Typography>
                                        </CardContent>
                                        <CardContent>


                                            <div style={{ marginTop: 2 }}>
                                                <Typography variant='h6'>

                                                    <strong>
                                                        Coordonnées de l'agence
                                                    </strong>
                                                </Typography>
                                                <div class="row">
                                                    <div class="col">
                                                        <TextField
                                                            error={Boolean(touched.nom_agence && errors.nom_agence)}
                                                            fullWidth
                                                            helperText={touched.nom_agence && errors.nom_agence}
                                                            label="Nom de l'agence"
                                                            margin="normal"
                                                            name="nom_agence"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.nom_agence}
                                                            variant="outlined"
                                                        />


                                                    </div>
                                                    <div class="col">
                                                        <div className='row'>

                                                            <div className='col'>

                                                                <Stack
                                                                    direction="row"
                                                                    alignItems="center"
                                                                    justifyContent="center"

                                                                    spacing={2}
                                                                >
                                                                    <TextField
                                                                        error={Boolean(errors.licence && touched.licence || touched.licence_file && errors.licence_file)}
                                                                        helperText={(touched.licence || touched.licence_file) && <div>{touched.licence !== undefined ? errors.licence : ""}  {touched.licence_file != undefined ? errors.licence_file : ""} </div>}
                                                                        fullWidth
                                                                        label="Licence"
                                                                        margin="normal"
                                                                        name="licence"
                                                                        onBlur={handleBlur}
                                                                        onChange={handleChange}
                                                                        value={values.licence}
                                                                        variant="outlined"
                                                                    />

                                                                    <label htmlFor="button-file">
                                                                        <IconButton variant="contained" component="span" size="large" >
                                                                            <Input
                                                                                id="button-file"
                                                                                style={{ display: "none" }}
                                                                                onChange={value => {
                                                                                    setFieldValue("licence_file", value.currentTarget.files[0]);

                                                                                }}
                                                                                name="licence_file"
                                                                                inputProps={{
                                                                                    accept: 'image/jpg , image/jpeg , image/png ,.pdf',
                                                                                }}
                                                                                type="file"
                                                                            />
                                                                            {
                                                                                values.licence_file == null ?
                                                                                    <UploadFile /> : <CheckCircle color='success'></CheckCircle>

                                                                            }
                                                                        </IconButton>
                                                                    </label>


                                                                </Stack>
                                                            </div>

                                                        </div>


                                                    </div>

                                                </div>
                                                <div class="row">
                                                    <div className='col'>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="center"

                                                            spacing={2}
                                                        >
                                                            <TextField
                                                                error={Boolean(errors.registre_commerce && touched.registre_commerce || touched.registre_commerce_file && errors.registre_commerce_file)}
                                                                helperText={(touched.registre_commerce || touched.registre_commerce_file) && <div>{touched.registre_commerce !== undefined ? errors.registre_commerce : ""}  {touched.registre_commerce_file != undefined ? errors.registre_commerce_file : ""} </div>}

                                                                fullWidth
                                                                label="Registre de commerce"
                                                                margin="normal"
                                                                name="registre_commerce"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.registre_commerce}
                                                                variant="outlined"
                                                            />
                                                            <label htmlFor="button-file-register">
                                                                <IconButton variant="contained" component="span" size="large" >
                                                                    <Input
                                                                        id="button-file-register"
                                                                        style={{ display: "none" }}
                                                                        onChange={value => {
                                                                            setFieldValue("registre_commerce_file", value.currentTarget.files[0]);
                                                                        }}
                                                                        name="registre_commerce_file"
                                                                        inputProps={{
                                                                            accept: 'image/jpg , image/jpeg , image/png ,.pdf',
                                                                        }}
                                                                        type="file"
                                                                    />
                                                                    {
                                                                        values.registre_commerce_file == null ?
                                                                            <UploadFile /> : <CheckCircle color='success'></CheckCircle>

                                                                    }
                                                                </IconButton>
                                                            </label>


                                                        </Stack>


                                                    </div>


                                                </div>
                                                <div class="row">
                                                    <div className='col'>
                                                        <Stack
                                                            direction="row"
                                                            alignItems="center"
                                                            justifyContent="center"
                                                            spacing={2}
                                                        >
                                                            <TextField
                                                                error={Boolean(errors.nif && touched.nif || touched.nif_file && errors.nif_file)}
                                                                helperText={(touched.nif || touched.nif_file) && <div>{touched.nif !== undefined ? errors.nif : ""}  {touched.nif_file != undefined ? errors.nif_file : ""} </div>}
                                                                fullWidth
                                                                label="NIF"
                                                                margin="normal"
                                                                name="nif"
                                                                onBlur={handleBlur}
                                                                onChange={handleChange}
                                                                value={values.nif}
                                                                variant="outlined"
                                                            />
                                                            <label htmlFor="button-file-nif">
                                                                <IconButton variant="contained" component="span" size="large" >
                                                                    <Input
                                                                        id="button-file-nif"
                                                                        style={{ display: "none" }}
                                                                        onChange={value => {
                                                                            setFieldValue("nif_file", value.currentTarget.files[0]);
                                                                        }}
                                                                        name="nif_file"
                                                                        inputProps={{
                                                                            accept: 'image/jpg , image/jpeg , image/png ,.pdf',
                                                                        }}
                                                                        type="file"
                                                                    />
                                                                    {
                                                                        values.nif_file == null ?
                                                                            <UploadFile /> : <CheckCircle color='success'></CheckCircle>

                                                                    }
                                                                </IconButton>
                                                            </label>


                                                        </Stack>


                                                    </div>


                                                </div>
                                                <div class="row">
                                                    <div class="col">
                                                        <TextField
                                                            error={Boolean(touched.adresse && errors.adresse)}
                                                            fullWidth
                                                            helperText={touched.adresse && errors.adresse}
                                                            label="Adresse"
                                                            margin="normal"
                                                            name="adresse"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.adresse}
                                                            variant="outlined"
                                                        />


                                                    </div>
                                                    <div class="col">
                                                        <TextField
                                                            error={Boolean(touched.pays && errors.pays)}
                                                            fullWidth
                                                            helperText={touched.pays && errors.pays}
                                                            label="Pays"
                                                            margin="normal"
                                                            name="pays"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.pays}
                                                            variant="outlined"
                                                        />

                                                    </div>
                                                    <div class="col">
                                                        <TextField
                                                            error={Boolean(touched.ville && errors.ville)}
                                                            fullWidth
                                                            helperText={touched.ville && errors.ville}
                                                            label="Ville"
                                                            margin="normal"
                                                            name="ville"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.ville}
                                                            variant="outlined"
                                                        />


                                                    </div>
                                                </div>


                                                <div class="row">

                                                    <div class="col">
                                                        <TextField
                                                            type='number'
                                                            error={Boolean(touched.code_postal && errors.code_postal)}
                                                            fullWidth
                                                            helperText={touched.code_postal && errors.code_postal}
                                                            label="Code postal"
                                                            margin="normal"
                                                            name="code_postal"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.code_postal}
                                                            variant="outlined"
                                                        />
                                                    </div>




                                                </div>

                                                <div class="row">
                                                    <div class="col">
                                                        <TextField

                                                            error={Boolean(touched.telephone && errors.telephone)}
                                                            fullWidth
                                                            helperText={touched.telephone && errors.telephone}
                                                            label="Téléphone"
                                                            margin="normal"
                                                            name="telephone"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.telephone}
                                                            variant="outlined"
                                                        />


                                                    </div>
                                                    <div class="col">
                                                        <TextField
                                                            error={Boolean(touched.fax && errors.fax)}
                                                            fullWidth
                                                            helperText={touched.fax && errors.fax}
                                                            label="Fax"
                                                            margin="normal"
                                                            name="fax"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.fax}
                                                            variant="outlined"
                                                        />
                                                    </div>




                                                </div>



                                                <Typography variant='h6'>

                                                    <strong>
                                                        Coordonnées du responsable
                                                    </strong>
                                                </Typography>
                                                <div class="row">
                                                    <div class="col">
                                                        <TextField
                                                            error={Boolean(touched.civilite && errors.civilite)}
                                                            fullWidth
                                                            helperText={touched.civilite && errors.civilite}
                                                            margin='normal'
                                                            select
                                                            label="Civilité"
                                                            name="civilite"
                                                            onChange={handleChange}
                                                            value={values.civilite}
                                                            variant="outlined"
                                                        >
                                                            <MenuItem value={"Monsieur"}>Monsieur</MenuItem>
                                                            <MenuItem value={"Madame"}>Madame</MenuItem>

                                                        </TextField>
                                                    </div>
                                                    <div class="col">
                                                        <TextField
                                                            error={Boolean(touched.nom && errors.nom)}
                                                            fullWidth
                                                            helperText={touched.nom && errors.nom}
                                                            label="Nom"
                                                            margin="normal"
                                                            name="nom"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.nom}
                                                            variant="outlined"
                                                        />


                                                    </div>
                                                    <div class="col">
                                                        <TextField
                                                            error={Boolean(touched.prenom && errors.prenom)}
                                                            fullWidth
                                                            helperText={touched.prenom && errors.prenom}
                                                            label="Prénom"
                                                            margin="normal"
                                                            name="prenom"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.prenom}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                </div>

                                                <div class="row">
                                                    <div class="col">
                                                        <TextField
                                                            error={Boolean(touched.mobile && errors.mobile)}
                                                            fullWidth
                                                            helperText={touched.mobile && errors.mobile}
                                                            label="Mobile"
                                                            margin="normal"
                                                            name="mobile"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.mobile}
                                                            variant="outlined"
                                                        />


                                                    </div>
                                                    <div class="col">
                                                        <TextField
                                                            type="email"
                                                            error={Boolean(touched.email && errors.email)}
                                                            fullWidth
                                                            helperText={touched.email && errors.email}
                                                            label="Email"
                                                            margin="normal"
                                                            name="email"
                                                            onBlur={handleBlur}
                                                            onChange={handleChange}
                                                            value={values.email}
                                                            variant="outlined"
                                                        />
                                                    </div>
                                                </div>


                                            </div>





                                        </CardContent>
                                        <CardActions>
                                            <Stack
                                                sx={{ width: "100%" }}
                                                direction="row"
                                                alignItems="center"
                                                justifyContent="space-between"
                                            >
                                                <div></div>
                                                <Button
                                                    // disabled={values.licence_file == null || values.registre_commerce_file == null || !isValid}
                                                    size='large'
                                                    type="submit"
                                                    variant="contained"
                                                >
                                                    <Typography>
                                                        S'inscrire
                                                    </Typography>
                                                </Button>

                                            </Stack>



                                        </CardActions>




                                    </Form>

                                )}
                            </Formik>
                        </CardContent>
                    </Container>
                </div>
            </aside >
        </div >

    )
}
