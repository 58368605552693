import { AccountBox, AirplanemodeActive, AirportShuttle, ArrowBack, ArrowForward, CalendarMonth, CalendarToday, CheckCircle, ContactEmergency, DoDisturbOn, DocumentScanner, Edit, Fingerprint, FlightLand, FlightTakeoff, Hotel, HourglassTop, InsertDriveFile, MonetizationOn, PhoneIphone, Pin, UploadFile } from '@mui/icons-material'
import { Alert, AlertTitle, CardContent, Container, Divider, IconButton, Stack, Typography } from '@mui/material'
import { getBlob, ref } from 'firebase/storage'
import React, { useState } from 'react'
import { storage } from '../../../Firebase'
import { IconLabelItem } from '../../common/IconLabelItem'

export default function DialogMesReservation(props) {
    let item = props.data

    let color = '#ff4838'

    const [itemIndex, setitemIndex] = useState(0);
    const [itemDeatil, setitemDeatil] = useState(props.data.members[0]);

    function checkConfirmation(params) {
        if (params.etat == "done")
            return 3

        let splitDate = params.date.split("-");
        splitDate = splitDate[2] + "-" + splitDate[1] + "-" + splitDate[0]
        let pastTime = new Date(splitDate)
        const now = new Date();

        const thirtyDaysInMs = 3 * 24 * 60 * 60 * 1000;
        const timeDiffInMs = now.getTime() - pastTime.getTime();

        let bool = timeDiffInMs >= thirtyDaysInMs

        if (bool)
            return 1

        return 2

    }


    const calculePersonnes = (item) => {
        let total = item.nbadults;
        for (let index in item.enfants) {
            total = total + parseInt(item.enfants[index]);
        }
        return total;
    };

    const testCurrentPersonne = () => {
        let i = itemIndex;

        if (i < item.nbadults) {
            return 'Adulte n° ' + (i + 1);
        }

        i = i - item.nbadults;
        let x = 0;
        while (x < item.programme.enfants.length) {
            if (i < item.enfants[x]) {
                return (
                    'Enfant ' +
                    item.programme.enfants[x].age +
                    ' | n° ' +
                    (i + 1)
                );
            } else {
                x = x + 1;
                if (i > 0) {
                    i = i - 1;
                }
            }
        }

        return '';
        //  return
    };



    const onDownload = (url) => {
        let file = 'images/reservation/' + item.id + '/reservation_' + item.id;

        getBlob(ref(storage, file))
            .then((blob) => {
                const href = URL.createObjectURL(blob);
                const a = Object.assign(document.createElement('a'), {
                    href,
                    style: 'display:none',
                    download: 'reservation_' + item.id,
                    // This is where you set the name of the file you're about to download
                });
                a.click();

                URL.revokeObjectURL(href);
                a.remove();
            })
            .catch((error) => {
                console.error(error);
            });

    };
    return (
        <div >

            <Container >

                <CardContent>
                    <div className='row'>
                        {

                            checkConfirmation(item) == 3 ?
                                IconLabelItem(<Edit style={{ color: color }}></Edit>, "Date d'ajout", item.date)
                                :
                                checkConfirmation(item) == 1 ?
                                    <Alert severity="error">
                                        <AlertTitle><strong>Réservation Annulé (72h sans confirmation)</strong></AlertTitle>
                                        <div>
                                            {IconLabelItem(<Edit style={{ color: color }}></Edit>, "Date d'ajout", item.date)}
                                        </div>
                                    </Alert>
                                    :
                                    <Alert severity="warning">
                                        <AlertTitle><strong>En traitement</strong></AlertTitle>
                                        <div>
                                            {IconLabelItem(<Edit style={{ color: color }}></Edit>, "Date d'ajout", item.date)}
                                        </div>
                                    </Alert>

                        }
                    </div>

                    <div className='row mt-1'>
                        <div className='col-sm-6'>
                            {IconLabelItem(<AccountBox style={{ color: color }}></AccountBox>, "Nom agence", item.nom_agence)}
                        </div>
                        <div className='col-sm-6'>
                            {IconLabelItem(<AirplanemodeActive style={{ color: color }}></AirplanemodeActive>, "Voyage organisé", item.destination)}
                        </div>
                    </div>
                    <div className='row'>
                        {IconLabelItem(<FlightTakeoff style={{ color: color }}></FlightTakeoff>, "Départ / retour", <><FlightTakeoff></FlightTakeoff>  {item.depart} | <FlightLand></FlightLand>  {item.retour}</>)}
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            {IconLabelItem(<Hotel style={{ color: color }}></Hotel>, "Chamber", item.chambre)}

                        </div>
                        <div className='col-sm-6'>
                            {IconLabelItem(<PhoneIphone style={{ color: color }}></PhoneIphone>, "Mobile", item.phone)}
                        </div>
                    </div>
                    <div className='row'>
                        {IconLabelItem(<Fingerprint style={{ color: color }}></Fingerprint>, "Référence", item.id)}

                    </div>
                    <div className='row'>
                        {IconLabelItem(<MonetizationOn style={{ color: color }}></MonetizationOn>, "Total", item.total + " DA")}
                    </div>

                    <Divider
                        sx={{ mt: 1, mb: 1, color: color, borderBottomWidth: 3 }}
                        variant="middle"
                    ></Divider>
                    <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <IconButton
                            disabled={itemIndex - 1 == -1}
                            color="primary"
                            onClick={() => {
                                setitemDeatil(item.members[itemIndex - 1])
                                setitemIndex((prv) => prv - 1)
                            }}
                        >
                            <ArrowBack></ArrowBack>
                        </IconButton>
                        <Typography sx={{ width: 250 }} align="center">
                            <strong>
                                {
                                    testCurrentPersonne()
                                }
                            </strong>
                        </Typography>
                        <IconButton
                            disabled={itemIndex + 1 == calculePersonnes(item)}
                            color="primary"
                            onClick={() => {
                                setitemDeatil(item.members[itemIndex + 1])
                                setitemIndex((prv) => prv + 1)
                            }}
                        >
                            <ArrowForward></ArrowForward>
                        </IconButton>
                    </Stack>

                    <div className='row mt-2'>

                        <div className='col-sm-6'>
                            {IconLabelItem(<ContactEmergency style={{ color: color }}></ContactEmergency>, "Nom et prénom", itemDeatil?.nom)}
                        </div>
                        <div className='col-sm-6'>
                            {IconLabelItem(<CalendarMonth style={{ color: color }}></CalendarMonth>, "Date de naissance", itemDeatil?.age)}
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-sm-6'>
                            {IconLabelItem(<AirportShuttle style={{ color: color }}></AirportShuttle>, "Transport", itemDeatil?.transport ? "Oui" : "Non")}
                        </div>
                    </div>
                    {
                        item.type != "national" &&
                        <div>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    {IconLabelItem(<Pin style={{ color: color }}></Pin>, "Passport", itemDeatil?.passport)}
                                </div>
                                <div className='col-sm-6'>
                                    {IconLabelItem(<CalendarToday style={{ color: color }}></CalendarToday>, "Expiration passport", itemDeatil?.expiration_passport)}
                                </div>
                            </div>
                            <div className='row'>
                                <div className='col-sm-6'>
                                    {IconLabelItem(<DocumentScanner style={{ color: color }}></DocumentScanner>, "Visa", itemDeatil?.visa)}
                                </div>
                            </div>
                            {
                                item?.programme?.files.length > 0 &&
                                <div>

                                    <Divider
                                        sx={{ mt: 1, mb: 1, color: color, borderBottomWidth: 3 }}
                                        variant="middle"
                                    ></Divider>
                                    <div className='row mt-2'>
                                        <Typography variant="body1" component="div" sx={{
                                            display: "-webkit-box",
                                            boxOrient: "vertical",
                                            wordBreak: "break-all",
                                            overflow: "hidden",
                                            color: "black"
                                        }}>
                                            <InsertDriveFile
                                                style={{
                                                    color: color,
                                                }}></InsertDriveFile>
                                            <strong>- Fichiers :</strong>
                                            <br />

                                        </Typography>
                                        <ul

                                        >
                                            {itemDeatil?.files && itemDeatil?.files.map((itm, ind) =>
                                                <li>

                                                    <a
                                                        style={{ paddingLeft: 30 }}
                                                        href={itm}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                        download
                                                    >
                                                        <UploadFile></UploadFile>

                                                        {item?.programme?.files[ind]}
                                                    </a>

                                                </li>
                                            )}

                                        </ul>

                                    </div>
                                </div>
                            }
                        </div>
                    }


                    <Divider
                        sx={{ mt: 1, mb: 1, color: color, borderBottomWidth: 3 }}
                        variant="middle"
                    ></Divider>
                    <div className='row mt-1'>
                        <div className='col'>
                            <Typography>
                                <strong> Etat de la demande :</strong>
                            </Typography>
                        </div>

                        {
                            item.etat == "done" ?
                                <div className='col'>
                                    <CheckCircle color={"success"}> </CheckCircle>
                                    <strong> Confirmé</strong>
                                </div>
                                : item.etat == "waiting" ?
                                    <div className='col'>
                                        <HourglassTop color={"warning"}></HourglassTop>
                                        <strong>  En traitement</strong>
                                    </div >
                                    :
                                    <div className='col'>
                                        <DoDisturbOn color="error"></DoDisturbOn>
                                        <strong> Annulé</strong>
                                    </div>
                        }


                    </div>
                    {
                        item.etat == "done" &&
                        <div>
                            <Divider
                                sx={{ mt: 1, mb: 1, color: color, borderBottomWidth: 3 }}
                                variant="middle"
                            ></Divider>
                            <Typography variant="h6" >
                                <strong>
                                    Voucher de réservation
                                </strong>
                            </Typography>
                            {
                                item.pdf != "" &&
                                <div
                                    onClick={onDownload}
                                >
                                    <a
                                        style={{ paddingLeft: 30 }}
                                        href={item?.pdf}
                                        rel="noopener noreferrer"
                                        target="_blank"
                                        download
                                    >
                                        <UploadFile></UploadFile>
                                        Téléchager voucher
                                    </a>
                                </div>
                            }
                        </div>
                    }

                </CardContent>


            </Container>
        </div >
    )
}
