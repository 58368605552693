import React from 'react';
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";

import SimpleReactLightbox from "simple-react-lightbox";
import Layout from "./components/App";
import About from "./components/pages/about/About";
import Contact from "./components/pages/contact/Contact";
import Destinations from "./components/pages/destinetion/Destinatination";
import Error from "./components/pages/error/Error";
import PackageDetails from "./components/pages/packageDetails/PackageDetails";
import PackageGrid from "./components/pages/packageGrid/PackageGrid";

// import all css
import ProtectedRoute from './components/context/ProtectedRoute';
import ProtectedRouteClient from './components/context/ProtectedRouteClient';
import { AuthContextProvider } from './components/context/UserContext';
import HomeFourLayout from './components/layout/HomeFourLayout';
import Billetterie from './components/pages/Billetterie/Billetterie';
import Hotelerie from './components/pages/Hotelere/Hotelerie';
import MesVisa from './components/pages/Visa/MesVisa';
import VisaClient from './components/pages/Visa/Visa';
import Demandes from './components/pages/admin/Demandes';
import Inscriptions from './components/pages/admin/Inscriptions';
import AdminDestionation from './components/pages/admin/adminDestinations/AdminDestinations';
import AdminPackage from './components/pages/admin/package/AdminPackage';
import AdminPackageDetail from './components/pages/admin/package/AdminPackageDetail';
import Visa from './components/pages/admin/visa/Visa';
import Notfound from './components/pages/notfound';
import MesReservations from './components/pages/packageGrid/MesReservation';
import MesReservationsOmra from './components/pages/packageGrid/MesReservationOmra';
import Inscription from './components/pages/signin/Inscription';
import Signin from './components/pages/signin/Signin';
import "./index.css";

/*
 * Version :Tourx-pro 0.1
 * Event : Rendering all content to web.
 * Actions: Define all routes and page.
 * @return html
 * */
// default Warning Error hide



function Root() {
  return (
    <>
      <BrowserRouter basename="/">
        <AuthContextProvider>
          <Switch>


            <Route exact path="/" component={HomeFourLayout} />


            {/* Admin pages */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/admin/destinations`}
            >
              <Layout>

                <ProtectedRoute>
                  <AdminDestionation></AdminDestionation>
                </ProtectedRoute>

              </Layout>
            </Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/admin/packages`}

            >
              <Layout>

                <ProtectedRoute>
                  <AdminPackage></AdminPackage>
                </ProtectedRoute>

              </Layout>
            </Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/admin/packages-detail`}
            >
              <Layout>


                <ProtectedRoute>
                  <AdminPackageDetail></AdminPackageDetail>
                </ProtectedRoute>
              </Layout>
            </Route>

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/admin/inscriptions`}

            >
              <Layout>


                <ProtectedRoute>
                  <Inscriptions></Inscriptions>
                </ProtectedRoute>
              </Layout>
            </Route>

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/admin/demandevisa`}

            >
              <Layout>


                <ProtectedRoute>
                  <Visa></Visa>
                </ProtectedRoute>
              </Layout>
            </Route>

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/admin/demandes`}


            >
              <Layout>


                <ProtectedRoute>
                  <Demandes></Demandes>
                </ProtectedRoute>
              </Layout>
            </Route>



            <Route
              exact
              path={`${process.env.PUBLIC_URL}/signin`}

            >
              <Layout>

                <Signin></Signin>
              </Layout>
            </Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/inscription`}
              component={Inscription}
            >
              <Layout>

                <Inscription></Inscription>
              </Layout>
            </Route>

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/about`}

            >
              <Layout>


                <About></About>
              </Layout>
            </Route>

            {/* desination pages */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/destination`}

            >
              <Layout>

                <Destinations></Destinations>
              </Layout>
            </Route>


            {/* all package pages component */}

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/package`}

            >
              <Layout>

                <PackageGrid></PackageGrid>
              </Layout>
            </Route>

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/package-details`}

            >
              <Layout>

                <PackageDetails></PackageDetails>
              </Layout>
            </Route>

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/hotelerie`}

            >
              <Layout>

                <Hotelerie></Hotelerie>
              </Layout>

            </Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/billetterie`}

            >
              <Layout>
                <Billetterie></Billetterie>

              </Layout>

            </Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/visa`}

            >
              <Layout>
                <VisaClient></VisaClient>

              </Layout>
            </Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/mesvisa`}
            >
              <Layout>


                <ProtectedRouteClient>
                  <MesVisa></MesVisa>
                </ProtectedRouteClient>
              </Layout>
            </Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/mesreservations`}
            >
              <Layout>

                <ProtectedRouteClient>
                  <MesReservations></MesReservations>
                </ProtectedRouteClient>

              </Layout>
            </Route>
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/mesreservationsomra`}
            >
              <Layout>

                <ProtectedRouteClient>
                  <MesReservationsOmra></MesReservationsOmra>
                </ProtectedRouteClient>

              </Layout>
            </Route>
            {/* all blog pages */}
            {/* <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog`}
              component={BlogGridPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-details`}
              component={BlogDetailsPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-standard`}
              component={BlogStandard}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/blog-sidebar`}
              component={BlogSidebar}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/guide`}
              component={GuidePage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/faq`}
              component={FaqPage}
            />
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/gallary`}
              component={GallaryPage}
            /> */}
            <Route
              exact
              path={`${process.env.PUBLIC_URL}/contact`}
            >
              <Layout>
                <Contact></Contact>
              </Layout>
            </Route>

            <Route
              exact
              path={`${process.env.PUBLIC_URL}/404`}
            >
              <Layout>
                <Error></Error>
              </Layout>
            </Route>

            <Route>
              <Notfound></Notfound>
            </Route>




          </Switch>
        </AuthContextProvider>

      </BrowserRouter>
    </>
  )
}

export default Root
ReactDOM.render(
  <React.Fragment>
    <SimpleReactLightbox>
      <Root />
    </SimpleReactLightbox>
  </React.Fragment>,
  document.getElementById("root")
);
