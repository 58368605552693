/** @format */

import React from 'react';
import { Link } from 'react-router-dom';

function DestinationCart(props) {
	const scrollTop = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};
	return (
		<>
			<div
				className="destination-card-style-two mb-0"
				style={{
					borderRadius: 3,
					'&:hover': {
						boxShadow: '0 10px 10px rgba(0,0,0,.7)',
					},
				}}>
				<div className="d-card-thumb">
					<img
						src={props.image}
						alt="CartIMG"
						style={{ height: '500px' }}
					/>
				</div>
				<div className="d-card-content">
					<h4 className="destination-title">
						{' '}
						<Link onClick={scrollTop}>{props.palce}</Link>
					</h4>
					<div className="place-count">
						<span>{props.palceCount}</span>
					</div>
				</div>
			</div>
		</>
	);
}

export default DestinationCart;
